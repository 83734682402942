import {IPayment} from "../../models/payment/payment.interface";
import {SequenceGenerator} from "./sequence.generator";
import {PaymentMethod} from "../../models/payment/payment-method";
import {inject, Injectable} from "@angular/core";
import {AuthenticationService} from "../../services/authentication/authentication.service";
import {IContact} from "../../models/contact/contact.interface";
import {DateConverter} from "../converters/date-converter";
import {PaymentType} from "../../models/payment/payment-type";

@Injectable()
export class EntityGenerator {

  private dateConverter = inject(DateConverter);

  private authenticationService = inject(AuthenticationService);

  public getEmptyPayment(paymentType: PaymentType, contact: IContact, amount: number, occurredAt: Date): IPayment {
    return {
      id: SequenceGenerator.generate(),
      serialNumber: SequenceGenerator.generate(),
      name: SequenceGenerator.generate(),
      userId: this.authenticationService.getUserDetails()!.attributes.id,

      newContactBalance: 0,
      oldContactBalance: 0,

      contact: contact,
      contactId: contact.id,
      amount: amount,
      createdAt: this.dateConverter.toString(new Date()),
      occurredAt: this.dateConverter.toString(occurredAt),
      paymentType: paymentType,
      paymentMethod: PaymentMethod.CASH,

      description: '',
      order: undefined,
    }
  }
}
