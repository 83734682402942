import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Routes } from '@angular/router';

interface Route {
  root: string;
  icon: string;
  childs: Child[];
}

interface Child {
  label: string;
  icon: string;
  link: string;
}

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss']
})
export class QuickAccessComponent implements OnInit {
  protected routes: Route[] = [];
  protected parentRoute: string | null = null;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.parentRoute = params['parentRoute'] || null;
      if (this.parentRoute) {
        this.populateChildRoutes(this.parentRoute);
      } else {
        this.populateAllRoutes();
      }
    });
  }

  private populateAllRoutes(): void {
    const routes: Routes = this.router.config;
    this.routes = routes.map(route => ({
      root: route.data?.['breadcrumb'] || '',
      icon: route.data?.['icon'] || '',
      childs: this.getChildRoutes(route)
    })).filter(route => route.childs.length > 0);
  }

  private populateChildRoutes(parentRoute: string): void {
    const routes: Routes = this.router.config;
    parentRoute = parentRoute.startsWith('/') ? parentRoute : `/${parentRoute}`;
    const parent = routes.find(route => `/${route.path}` === parentRoute);
    if (parent && parent.children) {
      this.routes = [{
        root: parent.data?.['breadcrumb'] || '',
        icon: parent.data?.['icon'] || '',
        childs: this.getChildRoutes(parent)
      }];
    }
  }

  private getChildRoutes(parentRoute: any): Child[] {
    if (parentRoute.children) {
      return parentRoute.children.map((childRoute: any) => ({
        label: childRoute.data?.['breadcrumb'] || '',
        icon: childRoute.data?.['icon'] || '',
        link: `/${parentRoute.path}/${childRoute.path}`
      }));
    }
    return [];
  }
}
