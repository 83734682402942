import {Component} from "@angular/core";
import {
  ReportingOrdersDashboardComponent
} from "../shared/reporting-orders-dashboard/reporting-orders-dashboard.component";
import {ReportingOrderType} from "../../../../core/models/transaction/reporting-order/reporting-order-type";

@Component({
  selector: 'app-proforma-invoice-orders-dashboard',
  templateUrl: './../shared/reporting-orders-dashboard/reporting-orders-dashboard.component.html',
  styleUrls: ['./../shared/reporting-orders-dashboard/reporting-orders-dashboard.component.scss']
})
export class ProformaInvoiceOrdersDashboardComponent extends ReportingOrdersDashboardComponent {
  protected getAddLabel(): string {
    return "Nouvelle facture proforma";
  }

  protected getEditLabel(): string {
    return "Facture proforma";
  }

  protected getReportingOrderType(): ReportingOrderType {
    return ReportingOrderType.PROFORMA_INVOICE;
  }
}
