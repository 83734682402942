import {Injectable} from '@angular/core';
import {ProductCategoryRepository} from "../../../repositories/inventory/product-category.repository";
import {ProductCategorySearchRequest} from "../../../models/inventory/product-category/product-category-search.request";
import {GenericServiceService} from "../../shared/generic-service.service";
import {IProductCategory} from "../../../models/inventory/product-category/product-category.interface";
import {ProductCategoryCreateRequest} from "../../../models/inventory/product-category/product-category-create.request";
import {ProductCategoryUpdateRequest} from "../../../models/inventory/product-category/product-category-update.request";

@Injectable()
export class ProductCategoryService extends GenericServiceService<IProductCategory, ProductCategorySearchRequest, ProductCategoryCreateRequest, ProductCategoryUpdateRequest> {

  constructor(productCategoryRepository: ProductCategoryRepository) {
    super(productCategoryRepository);
  }
}
