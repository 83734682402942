import {GenericSearchRequest} from "../shared/generic-search.request";

import {PaymentType} from "./payment-type";
import {RangeSearchRequestInterface} from "../shared/range-search-request.interface";
import {ContactSearchRequestInterface} from "../shared/contact-search-request.interface";

export class PaymentSearchRequest extends GenericSearchRequest implements RangeSearchRequestInterface, ContactSearchRequestInterface {

  occurredAfter: string | undefined;
  occurredBefore: string | undefined;
  serialNumber: string | undefined;
  contactId: string | undefined;
  paymentType: PaymentType;

  constructor(occurredAfter: string | undefined, occurredBefore: string | undefined, serialNumber: string | undefined, contactId: string | undefined, paymentType: PaymentType,
              pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined) {
    super(pageIndex, pageSize, offsetIndicators);

    this.occurredAfter = occurredAfter;
    this.occurredBefore = occurredBefore;
    this.serialNumber = serialNumber;
    this.contactId = contactId;
    this.paymentType = paymentType;
  }
}
