<div class="flex flex-column align-items-center gap-2 p-5">
  <div class="flex">
    <label>Montant</label>
  </div>
  <div class="w-full card flex justify-content-center gap-2">
    <div class="p-inputgroup">
      <p-inputNumber inputId="integeronly" [(ngModel)]="total"></p-inputNumber>
    </div>
  </div>
  <div class="flex">
    <label>Versement</label>
  </div>
  <div class="w-full card flex justify-content-center gap-2">
    <div class="p-inputgroup">
      <p-inputNumber inputId="integeronly" [(ngModel)]="paymentAmount"></p-inputNumber>
    </div>
  </div>
  <div class="flex">
    <label>Reste</label>
  </div>
  <div class="w-full card flex justify-content-center gap-2 mb-2">
    <div class="p-inputgroup">
      <p-inputNumber inputId="integeronly" [(ngModel)]="remaining"></p-inputNumber>
    </div>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-button label="1" (click)="append(1)" size="large" styleClass="keypad"></p-button>
    <p-button label="2" (click)="append(2)" size="large" styleClass="keypad"></p-button>
    <p-button label="3" (click)="append(3)" size="large" styleClass="keypad"></p-button>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-button label="4" (click)="append(4)" size="large" styleClass="keypad"></p-button>
    <p-button label="5" (click)="append(5)" size="large" styleClass="keypad"></p-button>
    <p-button label="6" (click)="append(6)" size="large" styleClass="keypad"></p-button>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-button label="7" (click)="append(7)" size="large" styleClass="keypad"></p-button>
    <p-button label="8" (click)="append(8)" size="large" styleClass="keypad"></p-button>
    <p-button label="9" (click)="append(9)" size="large" styleClass="keypad"></p-button>
  </div>
  <div class="card flex justify-content-center gap-2">
    <p-button label="C" (click)="clearInput()" severity="primary" size="large" styleClass="keypad"></p-button>
    <p-button label="0" (click)="append(0)" size="large" styleClass="keypad"></p-button>
    <p-button icon="pi pi-delete-left" (click)="delete()" severity="secondary" size="large" styleClass="keypad"></p-button>
  </div>
  <div class="w-full card flex justify-content-center gap-2">
    <p-button icon="pi pi-times" (click)="cancel()" severity="danger" styleClass="keypad"></p-button>
    <p-button label="Confirmer" (click)="confirm()" severity="success" styleClass="confirm" class="flex-grow-1 w-full"></p-button>
  </div>
</div>
