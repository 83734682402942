import {Component} from '@angular/core';
import {PaymentsDashboardComponent} from "../shared/payments-dashboard/payments-dashboard.component";
import {PaymentType} from "../../../core/models/payment/payment-type";


@Component({
  selector: 'app-customers-payments-dashboard',
  templateUrl: './customer-payments-dashboard.component.html',
  styleUrls: ['./customer-payments-dashboard.component.scss']
})
export class CustomerPaymentsDashboardComponent extends PaymentsDashboardComponent{
  protected readonly PaymentType = PaymentType;
}
