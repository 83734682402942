import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http/http.service';
import {IContact} from '../../models/contact/contact.interface';
import {ContactCreationRequest} from "../../models/contact/contact-creation.request";
import {ContactUpdateRequest} from "../../models/contact/contact-update.request";
import {GenericRepository} from "../shared/generic.repository";
import {ContactSearchRequest} from "../../models/contact/contact-search.request";

@Injectable()
export class ContactRepository extends GenericRepository<IContact, ContactSearchRequest, ContactCreationRequest, ContactUpdateRequest> {

  constructor(httpService: HttpService) {
    super(httpService, "/contacts")
  }
}
