import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportService} from './services/report/report.service';
import {ProductCategoryService} from './services/inventory/product-category/product-category.service';
import {PaymentService} from './services/payment/payment.service';
import {ContactService} from './services/contact/contact.service';
import {HttpService} from './services/http/http.service';
import {AuthenticationService} from './services/authentication/authentication.service';
import {ProductService} from './services/inventory/product/product.service';
import {ReportRepository} from './repositories/reporting/report.repository';
import {ProductCategoryRepository} from './repositories/inventory/product-category.repository';
import {ProductRepository} from './repositories/inventory/product.repository';
import {PaymentRepository} from './repositories/payment/payment.repository';
import {ContactRepository} from './repositories/contact/contact.repository';
import {UserRepository} from "./repositories/user/user.repository";
import {SessionManager} from "./util/managers/session.manager";
import {StorageManager} from "./util/managers/storage.manager";
import {BusinessErrorHandler} from "./util/handlers/business-error.handler";
import {NumberDirective} from "./directives/number.directive";
import {PurchaseOrderService} from "./services/transaction/purchase-order.service";
import {PurchaseOrderRepository} from "./repositories/order/purchase-order.repository";
import {SaleOrderRepository} from "./repositories/order/sale-order.repository";
import {SaleOrderService} from "./services/transaction/sale-order.service";
import {ReportingOrderRepository} from "./repositories/order/reporting-order.repository";
import {ReportingOrderService} from "./services/transaction/reporting-order.service";
import {PrintingHandler} from "./util/handlers/printing.handler";
import {NotificationHandler} from "./util/handlers/notification.handler";
import {DateConverter} from "./util/converters/date-converter";
import {EntityGenerator} from "./util/generators/entity.generator";

@NgModule({
  declarations: [
    NumberDirective
  ],
  providers: [
    DateConverter,
    EntityGenerator,

    SessionManager,
    StorageManager,

    PrintingHandler,
    NotificationHandler,
    BusinessErrorHandler,

    HttpService,
    AuthenticationService,
    ReportService,
    ProductCategoryService,
    ProductService,
    PaymentService,
    PurchaseOrderService,
    SaleOrderService,
    ReportingOrderService,
    ContactService,

    UserRepository,
    ContactRepository,
    ReportRepository,
    ProductCategoryRepository,
    ProductRepository,
    PurchaseOrderRepository,
    SaleOrderRepository,
    ReportingOrderRepository,
    PaymentRepository
  ],
  imports: [
    CommonModule
  ],
  exports: [
    NumberDirective
  ]
})
export class CoreModule {
}
