import {GenericSearchRequest} from "../../shared/generic-search.request";

export class ProductSearchRequest extends GenericSearchRequest {

  name: string | undefined;
  productCategoryId: string | undefined;
  sellingPrice: number | undefined;
  ids: string[] | undefined;

  constructor(name: string | undefined, productCategoryId: string | undefined, sellingPrice: number | undefined, ids: string[] | undefined,
              pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined) {
    super(pageIndex, pageSize, offsetIndicators);

    this.name = name;
    this.productCategoryId = productCategoryId;
    this.sellingPrice = sellingPrice;
    this.ids = ids;
  }
}
