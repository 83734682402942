import {ItemSplitButtonComponent} from "../item-split-button.component";
import {Component, Input} from "@angular/core";
import {IOrder} from "../../../models/transaction/shared/order/order.interface";
import {ReportType} from "../../../models/reporting/report-type";
import {SourceType} from "../../../models/reporting/source-type";
import {MenuItem} from "primeng/api";

@Component({
  selector: 'app-order-split-button',
  templateUrl: '../item-split-button.component.html',
  styleUrl: '../item-split-button.component.scss'
})
export class OrderSplitButtonComponent<T extends IOrder> extends ItemSplitButtonComponent<T> {

  @Input()
  printableReports: PrintableReport[] | undefined;

  override ngOnInit() {
    if (this.printableReports && this.printableReports.length > 0) {
      if (this.printableReports.length == 1) {
        const printableReport = this.printableReports.at(0)!;

        this.items.push(this.buildPrintItem('pi pi-file', printableReport));
      } else {
        const printingItems = this.printableReports.map(printableReport => {
          return this.buildPrintItem('pi pi-arrow-right', printableReport);
        });

        this.items.push({
          icon: 'pi pi-file',
          label: 'Imprimer',
          styleClass: this.itemsStyleClass ? this.itemsStyleClass : '',
          items: printingItems
        })
      }
    }

    super.ngOnInit();
  }

  buildPrintItem(icon: string, printableReport: PrintableReport): MenuItem {
    return {
      icon: icon,
      label: printableReport.label,
      styleClass: this.itemsStyleClass ? this.itemsStyleClass : '',
      command: e => {
        const input: {
          reportType: ReportType,
          sourceType: SourceType
        } = {
          reportType: printableReport.reportType,
          sourceType: printableReport.sourceType
        };

        this.printWithDefaultDialog(printableReport.header, printableReport.message, input, (id, input) =>
          this.reportService.orderReport(id, input.sourceType, input.reportType)
        );
      }
    }
  }
}

export interface PrintableReport {
  reportType: ReportType,
  sourceType: SourceType,
  label: string;
  header: string,
  message: string,
}
