import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {IUser} from "../../models/login/user.interface";
import {Observable} from "rxjs";
import {LoginRequest} from "../../models/login/login.request";
import {UserRepository} from "../../repositories/user/user.repository";
import {SessionManager} from "../../util/managers/session.manager";
import {StorageManager} from "../../util/managers/storage.manager";

@Injectable()
export class AuthenticationService {

  public static readonly AUTHORIZATION_KEY = "Authorization";
  private static readonly USER_KEY = "User";
  private static readonly USERNAME_KEY = "Username";
  private static readonly PASSWORD_KEY = "Password";

  private static readonly LOGIN_ROUTE = "/login";
  private static readonly HOME_ROUTE = "/home";

  private sessionManager = inject(SessionManager);
  private storageManager = inject(StorageManager);
  private userRepository = inject(UserRepository);
  private router = inject(Router);

  public getUserDetails(): IUser | null {
    return this.sessionManager.getItem(AuthenticationService.USER_KEY);
  }

  public getUserToken(): string | null {
    return this.sessionManager.getValue(AuthenticationService.AUTHORIZATION_KEY);
  }

  public isUserLoggedIn() {
    return this.getUserDetails() != null;
  }

  public login(loginRequest: LoginRequest, rememberMe: boolean): Observable<IUser> {
    if (rememberMe) {
      this.storageManager.setValue(AuthenticationService.USERNAME_KEY, loginRequest.username);
      this.storageManager.setValue(AuthenticationService.PASSWORD_KEY, loginRequest.password);
    } else {
      this.storageManager.removeItem(AuthenticationService.USERNAME_KEY);
      this.storageManager.removeItem(AuthenticationService.PASSWORD_KEY);
    }

    return new Observable<IUser>(userSubscriber => {
      this.userRepository.login(loginRequest).subscribe({
        next: loginResponse => {
          this.sessionManager.setValue(AuthenticationService.AUTHORIZATION_KEY, loginResponse.accessToken);

          this.userRepository.getUserDetails().subscribe({
            next: user => {
              this.sessionManager.setItem(AuthenticationService.USER_KEY, user);
              window.location.href = AuthenticationService.HOME_ROUTE;
              userSubscriber.next(user);
            },

            error: () => userSubscriber.error()
          });
        },

        error: () => userSubscriber.error()
      });
    });
  }

  public logout() {
    this.sessionManager.removeItem(AuthenticationService.AUTHORIZATION_KEY);
    this.sessionManager.removeItem(AuthenticationService.USER_KEY);

    this.router.navigate([AuthenticationService.LOGIN_ROUTE]).then(() => {
    });
  }
}
