export enum OrderType {
  SALE_ORDER = "SALE_ORDER",
  PURCHASE_ORDER = "PURCHASE_ORDER"
}

export namespace OrderType {
  export function toString(orderType: OrderType): string {
    switch (orderType) {
      case OrderType.PURCHASE_ORDER:
        return "Achat";
      case OrderType.SALE_ORDER:
        return "Vente";
    }
  }
}
