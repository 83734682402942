<p-toast [key]="this.printingToastId" position="bottom-right"/>

<div class="flex flex-column">
  <p-tabView *ngIf="tabsCount > 0">

    <p-tabPanel *ngFor="let orderBuilderTab of orderBuilderTabs;">
      <ng-template pTemplate="header">
        <div class="flex flex-row gap-1 align-items-center">
          <i [class]="orderBuilderTab.icon"></i>
          <span [textContent]="orderBuilderTab.title"></span>
          <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger "
                    (click)="onCloseTab(orderBuilderTab.id)"/>
        </div>

      </ng-template>

      <app-table-sale-order-builder
        [printingToastId]="this.printingToastId"
        [paymentRequestStrategy]="this.paymentRequestStrategy"
        [requestType]="RequestType.CREATE"
        [allowedReportTypes]="this.allowedReportTypes"/>
    </p-tabPanel>
  </p-tabView>
  <ng-container *ngIf="tabsCount < 1">
    <app-table-sale-order-builder
      [printingToastId]="this.printingToastId"
      [paymentRequestStrategy]="this.paymentRequestStrategy"
      [requestType]="RequestType.CREATE"
      [allowedReportTypes]="this.allowedReportTypes"/>
  </ng-container>

</div>
<p-button icon="pi pi-plus" label="Nouvel onglet" [rounded]="true" (click)="onAddTab()" styleClass="new-order"/>
