import { NgModule } from "@angular/core";
import { NavigationBreadcrumbComponent } from "./navigation-breadcrumb.component";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CommonModule } from "@angular/common";

@NgModule(
{
  declarations: [
    NavigationBreadcrumbComponent,
  ],
  providers: [],
  imports: [
    BreadcrumbModule,
    CommonModule,
  ],
  exports: [
    NavigationBreadcrumbComponent,
  ]
})
export class NavigationBreadCrumbModule{

}
