import {IOrderItem} from "../../models/transaction/shared/order-item/order-item.interface";

export class CloningHelper {

  public static orderItem(source: IOrderItem): IOrderItem {
    return {
      id: source.id,
      quantity: source.quantity,
      unitPrice: source.unitPrice,
      totalDiscount: source.totalDiscount,
      totalPrice: source.totalPrice,
      description: source.description,
      productId: source.productId,
      product: source.product
    };
  }
}
