import {Component, inject} from '@angular/core';
import {AuthenticationService} from "../../core/services/authentication/authentication.service";
import {MathHelper} from "../../core/helpers/math-helper";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  protected authenticationService = inject(AuthenticationService);
  protected readonly MathHelper = MathHelper;
}
