export enum ReportType {

  ORDER = "ORDER",
  DELIVERY_ORDER = "DELIVERY_ORDER",
  INVOICE = "INVOICE",
  ESTIMATE = "ESTIMATE",
  TAX_FREE_INVOICE = "TAX_FREE_INVOICE",
  REPORTING_ORDER = "REPORTING_ORDER",
  CONTACT_TRANSACTIONS_HISTORY = "CONTACT_TRANSACTIONS_HISTORY",
  DETAILED_CONTACT_TRANSACTIONS_HISTORY = "DETAILED_CONTACT_TRANSACTIONS_HISTORY",
  PAYMENT_RECEIPT = "PAYMENT_RECEIPT",
  WARRANTY = "WARRANTY"
}
