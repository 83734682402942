<p-toast [key]="this.toastId"></p-toast>
<p-toast [key]="this.printingToastId" position="bottom-right"/>

<p-confirmDialog></p-confirmDialog>
<p-confirmDialog key="printingConfirmDialog">
  <ng-template pTemplate="message" let-message>
    <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
      <i class="pi pi-question-circle text-6xl text-primary-500"></i>
      <p>{{ message.message }}</p>
    </div>
  </ng-template>
</p-confirmDialog>

<p-dialog
  [modal]="true"
  [maximizable]="true"
  [style]="{width: '100%', height: '100%'}"
  [(visible)]="this.orderCreationBuilderVisibility">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-plus text-green-700 text-xl"></i>
      <span class="font-bold white-space-nowrap text-xl" [textContent]="this.addButtonLabel"></span>
    </div>
  </ng-template>
  <div #orderCreationBuilderContainer></div>
</p-dialog>

<p-dialog
  [modal]="true"
  [maximizable]="true"
  [style]="{width: '100%', height: '100%'}"
  [(visible)]="this.orderEditionBuilderVisibility">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-pen text-yellow-500 text-xl"></i>
      <span class="font-bold white-space-nowrap text-xl"
            [textContent]="this.editHeaderPrefix + ': ' + this.targetedOrderForUpdate?.name"></span>
    </div>
  </ng-template>
  <div #orderEditionBuilderContainer></div>
</p-dialog>

<p-table
  dataKey="id"
  [value]="this.tableSearchManager.items"

  [paginator]="true"
  [rows]="this.pageSize"
  [totalRecords]="this.tableSearchManager.totalItemsCount"

  [lazy]="true"
  [loading]="this.tableSearchManager.searching || this.processing"
  [(first)]="this.tableSearchManager.first"
  (onLazyLoad)="tableSearchManager.search($event)"

  [rowHover]="true"
  [resizableColumns]="true"
  responsiveLayout="stack"
  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

  [scrollable]="true"

  [showFirstLastIcon]="false"
  [showPageLinks]="false"

  currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
      <button pButton pRipple [label]="this.addButtonLabel" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()">
      </button>

      <div class="flex-grow-1 xl:flex-grow-0">
        <p-calendar selectionMode="range"
                    dateFormat="dd/mm/yy"
                    placeholder="Réalisé le..."
                    [showIcon]="true"
                    [showButtonBar]="true"
                    [(ngModel)]="this.occurrenceRange"
                    (ngModelChange)="this.onOccurrenceRangeChanged()">
        </p-calendar>
      </div>

      <div class="flex-grow-1">
        <app-contact-auto-complete id="contact"
                                   [floatLabel]="false"
                                   [(entity)]="this.contact"
                                   [includeCustomers]="this.customerTransactions"
                                   [includeProviders]="this.providerTransactions"

                                   [parentToastId]="this.toastId"
                                   (entityChange)="this.onContactChanged()">
        </app-contact-auto-complete>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center" style="width: 5rem"></th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-hashtag mr-2"></i>Référence</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-user mr-2"></i>Contact</th>
      <th pResizableColumn class="text-center"><i class="fa-regular fa-calendar-plus mr-2"></i>Crée le</th>
      <th pResizableColumn class="text-center"><i class="fa-regular fa-calendar-check mr-2"></i>Réalisé le</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-file-invoice-dollar mr-2"></i>Total HT</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-percent mr-2"></i>TVA</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-file-invoice-dollar mr-2"></i>TTC</th>
      <th pResizableColumn class="text-center" *ngIf="this.isPayableOrder()"><i class="fa-solid fa-sack-dollar mr-2"></i>Versement</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-file-invoice-dollar mr-2"></i>Reste</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-comment mr-2"></i>Déscription</th>

      <th style="width:30px" pFrozenColumn alignFrozen="right"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-order let-expanded="expanded">
    <tr>
      <td class="text-center">
        <p-button styleClass="p-button-text p-button-info p-button-raised"
                  [pRowToggler]="order"
                  (onClick)="this.onViewOrderDetails(order)"
                  [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></p-button>
      </td>
      <td class="text-center font-semibold"><span class="p-column-title">Référence:</span>{{ order.serialNumber }}</td>
      <td class="text-center"><span class="p-column-title">Contact:</span>{{ order.contact?.name }}</td>
      <td class="text-center"><span class="p-column-title">Crée le:</span>
        <div>
          <span class="font-bold">
          {{ order.createdAt | date: 'dd MMM yyyy' }}
        </span>
          <span> - </span>
          <span class="font-semibold">
          {{ order.createdAt | date: 'HH:mm' }}
        </span>
        </div>
      </td>
      <td class="text-center font-bold"><span class="p-column-title">Réalisé le:</span>
        {{ order.occurredAt | date: 'dd/MM/yyyy' }}
      </td>

      <td class="text-center">
        <span class="p-column-title">Total HT:</span>
        {{ order.totalAmountTaxFree | number:'1.2-2' }} DZD
      </td>

      <td class="text-center">
        <span class="p-column-title">TVA:</span>
        <span class="font-semibold"
              *ngIf="order.taxable != undefined && order.taxable == true">{{ order.taxRate * 100 | number:'1.2-2' }}
          %</span>
        <span *ngIf="order.taxable == undefined || order.taxable == false">/</span>
      </td>

      <td class="text-center">
        <span class="p-column-title">TTC:</span>
        <span
          *ngIf="order.taxable != undefined && order.taxable == true">{{ order.totalAmountTaxIncluded | number:'1.2-2' }}
          DZD</span>
        <span *ngIf="order.taxable == undefined || order.taxable == false">/</span>
      </td>

      <td *ngIf="this.isPayableOrder()" class="text-center">
        <span class="p-column-title">Versement:</span>
        <app-colorized-progressbar
          [totalValue]="order.totalEffectiveAmount"
          [currentValue]="order.totalEffectiveAmount - order.remainingAmount"
          valueTooltipSuffix="DZD">
        </app-colorized-progressbar>
      </td>

      <td class="text-center">
        <span class="p-column-title">Reste:</span>
        {{ order.remainingAmount | number:'1.2-2' }} DZD
      </td>

      <td><span class="p-column-title">Description:</span>{{ order.description }}</td>

      <td pFrozenColumn alignFrozen="right">
        <span class="p-column-title">Actions:</span>
        <app-order-split-button appendTo="body" label="Actions" styleClass="p-button-sm w-full"
                                itemsStyleClass="table-right-button"

                                [item]="order"
                                [parentToastId]="this.toastId"
                                [(processing)]="this.processing"

                                [editable]="true" (editEventEmitter)="this.edit($event)"
                                [deletable]="true" (deleteEventEmitter)="delete($event)"

                                [printableReports]="this.printableReports"
        />
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="rowexpansion" let-order>
    <tr>
      <td colspan="12">
        <p-table [value]="order.orderItemResponses" dataKey="id" responsiveLayout="stack" class="w-full"
                 styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines">
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn><i class="fa-solid fa-box mr-2"></i>Produit</th>
              <th pResizableColumn class="text-center"><i class="fa-solid fa-dolly mr-2"></i>Quantité</th>
              <th pResizableColumn class="text-center"><i class="fa-solid fa-coins mr-2"></i>Prix unitaire</th>
              <th pResizableColumn class="text-center"><i class="fa-solid fa-hand-holding-dollar mr-2"></i>Remise total
              </th>
              <th pResizableColumn class="text-center"><i class="fa-solid fa-sack-dollar> mr-2"></i>Prix total</th>
              <th pResizableColumn class="text-center"><i class="fa-solid fa-comment mr-2"></i>Description</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-orderItem>
            <tr>
              <td><span class="p-column-title">Produit:</span>{{ orderItem.product?.name }}</td>
              <td class="text-center">
                <span class="p-column-title">Quantité:</span>
                <span class="font-semibold">{{ orderItem.quantity | number:'1.3-3' }}</span>
              </td>
              <td class="text-center">
                <span class="p-column-title">Prix unitaire:</span>
                <span class="font-semibold">{{ orderItem.unitPrice | number: '1.2-2' }} DZD</span>
              </td>
              <td class="text-center"><span class="p-column-title">Remise total:</span>
                {{ orderItem.totalDiscount || 0 | number: '1.2-2' }} DZD
              </td>
              <td class="text-center">
                <span class="p-column-title">Prix total:</span>
                <span class="font-semibold">{{ orderItem.totalPrice | number: '1.2-2' }} DZD</span>
              </td>
              <td class="text-center">
                <span class="p-column-title">Déscription:</span>
                {{ orderItem.description }}
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="6">Aucun produit inclu.</td>
            </tr>
          </ng-template>
        </p-table>
      </td>
    </tr>
  </ng-template>
</p-table>
