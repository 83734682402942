import {OrderRepository} from "./order.repository";
import {HttpService} from "../../services/http/http.service";
import {Injectable} from "@angular/core";
import {IReportingOrder} from "../../models/transaction/reporting-order/reporting-order.interface";
import {ReportingOrderSearchRequest} from "../../models/transaction/reporting-order/reporting-order-search.request";
import {ReportingOrderCreationRequest} from "../../models/transaction/reporting-order/reporting-order-creation.request";
import {ReportingOrderUpdateRequest} from "../../models/transaction/reporting-order/reporting-order-update.request";

@Injectable()
export class ReportingOrderRepository extends OrderRepository<IReportingOrder, ReportingOrderSearchRequest, ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {

  constructor(httpService: HttpService) {
    super(httpService, "/reportingOrders");
  }
}
