<p-toast></p-toast>
<div class="div-center">
  <form [formGroup]="this.loginCredentialsForm">
    <div class="img">
      <img class="logo" src="./assets/logo.png" alt="">
    </div>
    <div class="mb-4 title">
      <h1>Authentification</h1>
    </div>

    <p-inputGroup class="mb-2">
      <p-inputGroupAddon>
        <i class="pi pi-user"></i>
      </p-inputGroupAddon>
      <input pInputText placeholder="Nom d'utilisateur / E-mail" formControlName="username"/>
    </p-inputGroup>

    <p-inputGroup class="mb-3">
      <p-inputGroupAddon>
        <i class="pi pi-lock"></i>
      </p-inputGroupAddon>
      <p-password [feedback]="false" placeholder="Mot de passe" formControlName="password"></p-password>
    </p-inputGroup>

    <div class="mb-3 flex justify-content-center">
      <p-checkbox formControlName="remember" [binary]="true" id="remember" class="mr-2"></p-checkbox>
      <label for="remember">Se souvenir de moi</label>
    </div>

    <div class="pr-3 pl-3">
      <p-button label="S'AUTHENTIFIER" icon="pi pi-sign-in" iconPos="right" [loading]="isLogging"
                (onClick)="login()" [rounded]="true" styleClass="w-full primary-button" [raised]="true"></p-button>
    </div>
  </form>
</div>
