import {Component, inject} from "@angular/core";
import {OrderBuilderModalDefinition, OrdersTableComponent} from "../../shared/orders-table/orders-table.component";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {SaleOrderService} from "../../../../core/services/transaction/sale-order.service";
import {ISaleOrder} from "../../../../core/models/transaction/sale-order/sale-order.interface";
import {SaleOrderSearchRequest} from "../../../../core/models/transaction/sale-order/sale-order-search.request";
import {SaleOrderCreationRequest} from "../../../../core/models/transaction/sale-order/sale-order-creation.request";
import {SaleOrderUpdateRequest} from "../../../../core/models/transaction/sale-order/sale-order-update.request";
import {PrintableReport} from "../../../../core/ui/item-split-button/order-split-button/order-split-button.component";
import {ReportType} from "../../../../core/models/reporting/report-type";
import {SourceType} from "../../../../core/models/reporting/source-type";
import {
  TableSaleOrderBuilderComponent
} from "../../shared/order-builder/table-order-builder/table-sale-order-builder/table-sale-order-builder.component";
import {PaymentRequestStrategy} from "../../shared/order-builder/order-builder.component";
import {AllowedReportTypes} from "../../shared/order-builder/reporting/allowed-report.type";

@Component({
  selector: 'app-sale-orders-crud-table',
  templateUrl: './../../shared/orders-table/orders-table.component.html',
  styleUrls: ['./../../shared/orders-table/orders-table.component.scss'],
})
export class SaleOrdersTableComponent extends OrdersTableComponent<ISaleOrder, SaleOrderSearchRequest, SaleOrderCreationRequest, SaleOrderUpdateRequest> {

  saleOrdersService = inject(SaleOrderService);

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): SaleOrderSearchRequest {
    const occurredAfter = this.occurrenceRange?.at(0)?.toISOString();
    const occurredBefore = this.occurrenceRange?.at(1)?.toISOString();
    const contactId = this.contact?.id;

    return new SaleOrderSearchRequest(occurredAfter, occurredBefore, undefined, undefined, contactId, pageIndex, pageSize, offsetIndicators);
  }

  protected getService(): GenericServiceService<ISaleOrder, SaleOrderSearchRequest, SaleOrderCreationRequest, SaleOrderUpdateRequest> {
    return this.saleOrdersService;
  }

  protected isCustomerTransactions(): boolean {
    return true;
  }

  protected isProviderTransactions(): boolean {
    return false;
  }

  protected override getPrintableReports(): PrintableReport[] {
    const printableReports = super.getPrintableReports();
    printableReports.push({
      label: "Bon pour",
      header: "Impréssion d'un bon pour",
      message: "Voulez vous vraiment imprimer un bon pour ?",
      reportType: ReportType.ORDER,
      sourceType: SourceType.SALE_ORDER,
    });

    printableReports.push({
      label: "Bon de livraison",
      header: "Impréssion d'un bon de livraison",
      message: "Voulez vous vraiment imprimer un bon de livraison ?",
      reportType: ReportType.DELIVERY_ORDER,
      sourceType: SourceType.SALE_ORDER,
    });

    return printableReports;
  }

  getCreationOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TableSaleOrderBuilderComponent> {
    return {
      component: TableSaleOrderBuilderComponent,
      inputs: [
        {
          name: 'allowedReportTypes',
          value: [
            AllowedReportTypes.ORDER_ENFORCE_NO_VAT,
            AllowedReportTypes.DELIVERY_ORDER_ENFORCE_NO_VAT,
            AllowedReportTypes.INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE,
            AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_FALSE
          ]
        }
      ]
    };
  }

  protected getEditionOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TableSaleOrderBuilderComponent> {
    return {
      component: TableSaleOrderBuilderComponent,
      inputs: [
        {
          name: 'allowedReportTypes',
          value: [
            AllowedReportTypes.ORDER_ENFORCE_NO_VAT,
            AllowedReportTypes.DELIVERY_ORDER_ENFORCE_NO_VAT,
            AllowedReportTypes.INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE,
            AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_FALSE
          ]
        }
      ]
    };
  }


  protected getOrderPaymentStrategy(): PaymentRequestStrategy {
    return PaymentRequestStrategy.FORM_PAYMENT;
  }

  protected isPayableOrder(): boolean {
    return true;
  }
}
