import {Component, inject, Input} from "@angular/core";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {IPayment} from "../../../../core/models/payment/payment.interface";
import {PaymentSearchRequest} from "../../../../core/models/payment/payment-search.request";
import {PaymentCreationRequest} from "../../../../core/models/payment/payment-creation.request";
import {PaymentType} from "../../../../core/models/payment/payment-type";
import {PaymentService} from "../../../../core/services/payment/payment.service";
import {PaymentMethodUtils} from "../../../../core/models/payment/payment-method";
import {ContactRangeCrudTableComponent} from "../../../../core/ui/crud-table/contact-range-crud-table.component";

@Component({
  selector: 'app-payments-crud-table',
  templateUrl: './payments-table.component.html',
  styleUrls: ['./payments-table.component.scss'],
})
export class PaymentsTableComponent extends ContactRangeCrudTableComponent<IPayment, PaymentSearchRequest, PaymentCreationRequest, undefined> {

  protected readonly PaymentMethodUtils = PaymentMethodUtils;

  paymentService = inject(PaymentService);

  @Input()
  paymentType!: PaymentType;

  protected getService(): GenericServiceService<IPayment, PaymentSearchRequest, PaymentCreationRequest, undefined> {
    return this.paymentService;
  }

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): PaymentSearchRequest {
    const occurredAfter = this.occurrenceRange?.at(0)?.toISOString();
    const occurredBefore = this.occurrenceRange?.at(1)?.toISOString();
    const contactId = this.contact?.id;

    return new PaymentSearchRequest(occurredAfter, occurredBefore, undefined, contactId, this.paymentType, pageIndex, pageSize, offsetIndicators);
  }
}
