<div class="label-container">
    <p class="text">{{ productInfo?.name | slice:0:20 }}</p>
    <ngx-barcode6
        [bc-format]="barcodeFormat"
        [bc-value]="productInfo?.barcode ?? ''"
        [bc-margin-top]="0"
        [bc-margin-bottom]="0"
        [bc-height]="height"
        class="barcode"
    />
    <p class="text">{{ productInfo?.sellingPrice }} DA</p>
</div>
