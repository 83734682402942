import {Component, EventEmitter, inject, Input} from "@angular/core";
import {IContact} from "../../../../core/models/contact/contact.interface";
import {ContactSearchRequest} from "../../../../core/models/contact/contact-search.request";
import {CrudTableComponent} from "../../../../core/ui/crud-table/crud-table.component";
import {ContactService} from "../../../../core/services/contact/contact.service";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {ContactCreationRequest} from "../../../../core/models/contact/contact-creation.request";
import {ContactUpdateRequest} from "../../../../core/models/contact/contact-update.request";
import {IWarranty} from "../../../../core/models/warranty/warranty.interface";

@Component({
  selector: 'app-contacts-crud-table',
  templateUrl: './contacts-table.component.html',
  styleUrls: ['./contacts-table.component.scss'],
})
export class ContactsTableComponent extends CrudTableComponent<IContact, ContactSearchRequest, ContactCreationRequest, ContactUpdateRequest> {

  contactService = inject(ContactService);

  protected historyReportDate = new Date();

  @Input()
  includeCustomers: boolean | undefined;

  @Input()
  includeProviders: boolean | undefined;

  @Input()
  forceCustomerCreation = false;

  @Input()
  forceProviderCreation = false;

  warrantyCreateFormVisibilityEventEmitter = new EventEmitter<IWarranty | undefined>;

  protected getService(): GenericServiceService<IContact, ContactSearchRequest, unknown, unknown> {
    return this.contactService;
  }

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): ContactSearchRequest {
    return new ContactSearchRequest(undefined, this.includeCustomers, this.includeProviders, pageIndex, pageSize, offsetIndicators);
  }
}
