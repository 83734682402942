import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../services/http/http.service';
import {ReportType} from "../../models/reporting/report-type";
import {SourceType} from "../../models/reporting/source-type";
import {WarrantyPrintingRequest} from "../../models/reporting/warranty-printing.request";

@Injectable()
export class ReportRepository {
  constructor(private httpService: HttpService) {
  }

  transactionHistory(id: string, year: number | undefined): Observable<Blob> {
    const reportData = {
      reportStyle: {
        name: 'A'
      },
      year: year
    };

    return this.httpService.getPdf('/reports/contact/' + id + '/transactionsHistory/download', reportData);
  }

  detailedTransactionHistory(id: string, year: number | undefined): Observable<Blob> {
    const requestPayload = {
      reportStyle: {name: 'A'},
      year: year
    };

    return this.httpService.getPdf('/reports/contact/' + id + '/detailedTransactionsHistory/download', requestPayload);
  }

  paymentReceipt(id: string): Observable<Blob> {
    const reportData = {
      reportType: 'PAYMENT_RECEIPT',
      reportStyle: {
        name: 'A'
      }
    };

    return this.httpService.getPdf('/reports/payment/' + id + '/paymentReceipt/download', reportData);
  }

  orderReport(id: string, sourceType: SourceType, type: ReportType): Observable<Blob> {
    const reportData = {
      reportType: type,
      reportStyle: {
        name: 'A'
      }
    };

    return this.httpService.getPdf('/reports/' + sourceType + "/" + id + '/download', reportData);
  }

  warranty(warranty: WarrantyPrintingRequest) {
    return this.httpService.getPdf('/reports/warranty/download', warranty);
  }
}
