import { Component, inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { UiService } from "../../services/ui.service";
import { MenuItem, MessageService } from "primeng/api";

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  providers: [MessageService]
})
export class TopbarComponent implements OnInit {
  public rightMenuItems: MenuItem[] | undefined;

  protected authenticationService = inject(AuthenticationService);
  protected uiService = inject(UiService);

  ngOnInit(): void {
    this.rightMenuItems = [
      {
        label: 'Se déconnecter',
        icon: 'pi pi-sign-out',
        command: () => this.authenticationService.logout()
      }
    ]
    
  }
}
