import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {ProductFormComponent} from "../shared/product-form/product-form.component";
import {IProduct} from "../../../../core/models/inventory/product/product.interface";
import {ProductFormData} from "../shared/product-form/product-form-data.";
import {ValidationReport, ValidationViolation} from "../../../../core/ui/validation/validation-report";

@Component({
  selector: 'app-product-edition-form',
  templateUrl: '../shared/product-form/product-form.component.html',
  styleUrl: '../shared/product-form/product-form.component.scss',
})
export class ProductEditionFormComponent extends ProductFormComponent {

  override ngOnInit(): void {
    super.ngOnInit();

    this.disableAvailableQuantity = true;
  }

  protected onConfirm(entity: IProduct, formData: ProductFormData): Observable<IProduct> {
    return this.productService.update(entity.id, formData.toProductUpdateRequest());
  }

  protected override onResetFormData(entity: IProduct): Observable<ProductFormData> {
    return new Observable<ProductFormData>(formDataSubscriber => {
      return this.productCategoryService.findById(entity.productCategory?.id)
        .subscribe({
          next: (productCategory) => {
            formDataSubscriber.next(ProductFormData.fromProduct(entity, productCategory));
          },

          error: (error) => {
            console.error(error);

            formDataSubscriber.next(ProductFormData.fromProduct(entity, undefined));
          }
        });
    });
  }
}
