import {inject, Injectable} from "@angular/core";
import {HttpService} from "../../services/http/http.service";
import {map, Observable} from "rxjs";
import {ILoginResponse} from "../../models/login/login.response.interface";
import {LoginRequest} from "../../models/login/login.request";
import {IUser} from "../../models/login/user.interface";

@Injectable()
export class UserRepository {

  private httpService = inject(HttpService);

  login(loginRequest: LoginRequest): Observable<ILoginResponse> {
    return this.httpService.post('/users/login', loginRequest).pipe(map(data => data.body))
  }

  getUserDetails(): Observable<IUser> {
    return this.httpService.get('/users/me/', false);
  }
}
