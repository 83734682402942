import {Component} from "@angular/core";
import {IContact} from "../../../core/models/contact/contact.interface";
import {ContactFormComponent} from "../shared/contact-form/contact-form.component";
import {Observable} from "rxjs";
import {ContactFormData} from "../shared/contact-form/contact-form-data.";

@Component({
  selector: 'app-contact-edition-form',
  templateUrl: '../shared/contact-form/contact-form.component.html',
  styleUrl: '../shared/contact-form/contact-form.component.scss',
})
export class ContactEditionFormComponent extends ContactFormComponent {

  protected override onResetFormData(entity: IContact | undefined, previousFormData: ContactFormData): Observable<ContactFormData> {
    const formData = ContactFormData.fromContact(entity);
    formData.transactionalDetails.isCustomer = this.forceCustomer || formData.transactionalDetails?.isCustomer;
    formData.transactionalDetails.isProvider = this.forceProvider || formData.transactionalDetails?.isProvider;

    return new Observable<ContactFormData>(subscriber => subscriber.next(formData));
  }

  protected onConfirm(contact: IContact, request: ContactFormData): Observable<IContact> {
    return this.contactService.update(contact.id, request.toContactUpdateRequest());
  }
}
