import { Component, Input } from '@angular/core';
import { IProduct } from '../../models/inventory/product/product.interface';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrl: './barcode.component.scss'
})
export class BarcodeComponent {
  @Input() productInfo: Pick<IProduct, 'name' | 'barcode' | 'sellingPrice'> | undefined;
  @Input() height: number = 20;

  protected barcodeFormat: string = 'CODE128';
}
