import {Injectable} from "@angular/core";
import {OrderService} from "./shared/order.service";
import {IReportingOrder} from "../../models/transaction/reporting-order/reporting-order.interface";
import {ReportingOrderSearchRequest} from "../../models/transaction/reporting-order/reporting-order-search.request";
import {ReportingOrderCreationRequest} from "../../models/transaction/reporting-order/reporting-order-creation.request";
import {ReportingOrderUpdateRequest} from "../../models/transaction/reporting-order/reporting-order-update.request";
import {ReportingOrderRepository} from "../../repositories/order/reporting-order.repository";

@Injectable()
export class ReportingOrderService extends OrderService<IReportingOrder, ReportingOrderSearchRequest, ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {

  constructor(orderRepository: ReportingOrderRepository) {
    super(orderRepository);
  }
}
