import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ComponentsModule} from "../../shared/components/components.module";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService} from "primeng/api";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {
  ProductCategoriesDashboardComponent
} from "./product-category/product-categories-dashboard/product-categories-dashboard.component";
import {
  ProductCategoriesTableComponent
} from "./product-category/product-categories-table/product-categories-table.component";
import {ProductsModule} from "./product/products.module";
import {ProductCategoryFormModule} from "./product-category/product-category-form/product-category-form.module";

@NgModule({
  declarations: [
    ProductCategoriesTableComponent,
    ProductCategoriesDashboardComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ToolbarModule,
    FileUploadModule,
    FormsModule,
    RippleModule,
    ChipModule,
    UiModule,
    StyleClassModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    DividerModule,
    FloatLabelModule,
    ProductsModule,
    ProductCategoryFormModule
  ],
  exports: [
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class InventoryModule {
}
