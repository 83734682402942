import {Component} from "@angular/core";
import {
  ReportingOrdersDashboardComponent
} from "../shared/reporting-orders-dashboard/reporting-orders-dashboard.component";
import {ReportingOrderType} from "../../../../core/models/transaction/reporting-order/reporting-order-type";

@Component({
  selector: 'app-estimates-orders-dashboard',
  templateUrl: './../shared/reporting-orders-dashboard/reporting-orders-dashboard.component.html',
  styleUrls: ['./../shared/reporting-orders-dashboard/reporting-orders-dashboard.component.scss']
})
export class EstimateOrdersDashboardComponent extends ReportingOrdersDashboardComponent {
  protected getAddLabel(): string {
    return "Nouveau devis";
  }

  protected getEditLabel(): string {
    return "Devis";
  }

  protected getReportingOrderType(): ReportingOrderType {
    return ReportingOrderType.ESTIMATE;
  }
}
