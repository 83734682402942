<p-toolbar styleClass="shadow-1 p-2 white p-fixed">
  <ng-template pTemplate="start">
    <p-button [raised]="true" [text]="true" styleClass="p-2" class="m-0 mr-3 primary-pulse"
              (onClick)="uiService.showSideBar()">
      <p-avatar image="./assets/logo.png" size="large" shape="circle"></p-avatar>
    </p-button>
    <app-navigation-breadcrumb class="hidden md:inline"></app-navigation-breadcrumb>
  </ng-template>
  <ng-template pTemplate="center">
  </ng-template>
  <ng-template pTemplate="end">
    <p-button [text]="true" [label]="this.authenticationService.getUserDetails()?.name" badge="0"
              badgeClass="p-badge-danger"
              [outlined]="true" (click)="menu.toggle($event)"
              styleClass="user-button">
      <p-avatar [image]="this.authenticationService.getUserDetails()?.attributes?.picture" size="normal" shape="circle"
                class="mr-2 mt-1"></p-avatar>
                
    </p-button>
    
    <p-tieredMenu #menu [model]="rightMenuItems" [popup]="true"></p-tieredMenu>
  </ng-template>
</p-toolbar>
<app-navigation-breadcrumb class="nav-breadcrumb p-fixed md:hidden"></app-navigation-breadcrumb>