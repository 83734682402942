import {OrderBuilderDashboardComponent} from "../../shared/order-builder-dashboard/order-builder-dashboard.component";
import {Component} from "@angular/core";
import {AllowedReportType, AllowedReportTypes} from "../../shared/order-builder/reporting/allowed-report.type";

@Component({
  templateUrl: './table-purchase-order-builder-dashboard.component.html',
  styleUrl: "./table-purchase-order-builder-dashboard.component.scss"
})
export class TablePurchaseOrderBuilderDashboardComponent extends OrderBuilderDashboardComponent {

  allowedReportTypes: AllowedReportType[] = [
    AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_TRUE
  ]

  override getTabPrefix(): string {
    return "Fournisseur";
  }
}
