<p-dialog header="Header" [(visible)]="this.visible" [modal]="true">
  <p-toast [key]="this.toastId"></p-toast>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-sitemap mr-2"></i>
      <span class="font-bold white-space-nowrap" [textContent]="headerLabel"></span>
    </div>
  </ng-template>

  <div class="flex">
    <form>
      <div class="flex flex-column ">
        <div class="flex flex-row flex-wrap gap-4">
          <div class="w-full xl:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-calendar-check"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-calendar id="occurredAt"
                            dateFormat="dd/mm/yy"
                            inputId="occurredAt"
                            name="occurredAt"
                            placeholder="Réalisé le..."
                            appendTo="body"
                            [showIcon]="true"
                            [showButtonBar]="true"
                            [(ngModel)]="this.formData.occurredAt">
                </p-calendar>
                <label for="occurredAt">Réalisé le</label>
              </p-floatLabel>
            </p-inputGroup>

            <div class="mt-4">
              <app-contact-auto-complete id="contact"
                                         class="flex-grow-1"
                                         appendTo="body"
                                         [draggable]="false"
                                         [parentToastId]="this.toastId"
                                         [(entity)]="this.formData.contact"
                                         [includeCustomers]="this.paymentType == undefined || PaymentType.INGOING == this.paymentType"
                                         [includeProviders]="this.paymentType == undefined || PaymentType.OUTGOING == this.paymentType"/>
            </div>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-sack-dollar"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="amount" name="amount" inputId="amount" mode="decimal"
                               [(ngModel)]="formData.amount" [minFractionDigits]="2" [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="amount">Montant</label>
              </p-floatLabel>
            </p-inputGroup>

            <div class="mt-4">
              <app-payment-method-dropdown [id]="'paymentMethod'" appendTo="body" class="flex-grow-1"
                                           [(entity)]="this.formData.paymentMethod"/>
            </div>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-comment"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <textarea id="description" name="description" rows="5" cols="30" pInputTextarea [(ngModel)]="this.formData.description"></textarea>
                <label for="description">Déscription</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2">
      <div class="flex-grow-1">
        <p-button icon="pi pi-times" (click)="this.cancel()" label="Annuler" severity="danger"
                  styleClass="w-full"></p-button>
      </div>
      <div class="flex-grow-1">
        <p-button icon="pi pi-check" (click)="this.validate()" label="Confirmer" severity="success"
                  pAutoFocus [autofocus]="true" [loading]="this.confirming" iconPos="right"
                  styleClass="w-full"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
