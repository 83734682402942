import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {ProductFormComponent} from "../shared/product-form/product-form.component";
import {IProduct} from "../../../../core/models/inventory/product/product.interface";
import {ProductFormData} from "../shared/product-form/product-form-data.";
import {ValidationReport, ValidationViolation} from "../../../../core/ui/validation/validation-report";

@Component({
  selector: 'app-product-creation-form',
  templateUrl: '../shared/product-form/product-form.component.html',
  styleUrl: '../shared/product-form/product-form.component.scss',
})
export class ProductCreationFormComponent extends ProductFormComponent {

  override ngOnInit(): void{
    super.ngOnInit();

    this.disableAvailableQuantity = false;
  }

  protected override onValidate(formData: ProductFormData): ValidationReport {
    let validationReport = super.onValidate(formData);

    if (formData.availableQuantity && formData.availableQuantity < 0) {
      const violation = new ValidationViolation();
      violation.fieldName = "availableQuantity";
      violation.errorDetails = "La quantité disponible doit être un nombre positive";

      validationReport.violations.push(violation);
    }

    return validationReport;
  }

  protected onConfirm(entity: IProduct | undefined, formData: ProductFormData): Observable<IProduct> {
    return this.productService.create(formData.toProductCreationRequest());
  }
}
