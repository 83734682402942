import {Component} from '@angular/core';
import {OrdersDashboardComponent} from "../../shared/orders-dashboard/orders-dashboard.component";


@Component({
  selector: 'app-sale-orders-dashboard',
  templateUrl: './purchase-orders-dashboard.component.html',
  styleUrls: ['./purchase-orders-dashboard.component.scss']
})
export class PurchaseOrdersDashboardComponent extends OrdersDashboardComponent {
}
