import {Injectable} from '@angular/core';
import {ReportRepository} from '../../repositories/reporting/report.repository';
import {Observable} from "rxjs";
import {ReportType} from "../../models/reporting/report-type";
import {SourceType} from "../../models/reporting/source-type";
import {WarrantyPrintingRequest} from "../../models/reporting/warranty-printing.request";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(public reportRepository: ReportRepository) {
  }

  transactionHistory(id: string, year: number | undefined): Observable<Blob> {
    return this.reportRepository.transactionHistory(id, year);
  }

  detailedTransactionHistory(id: string, year: number | undefined): Observable<Blob> {
    return this.reportRepository.detailedTransactionHistory(id, year);
  }

  paymentReceipt(id: string): Observable<Blob> {
    return this.reportRepository.paymentReceipt(id);
  }

  orderReport(id: string, sourceType: SourceType, reportType: ReportType): Observable<Blob> {
    return this.reportRepository.orderReport(id, sourceType, reportType);
  }

  warranty(warranty: WarrantyPrintingRequest): Observable<Blob> {
    return this.reportRepository.warranty(warranty);
  }
}
