import {IProduct} from "../../../../core/models/inventory/product/product.interface";
import {OrderItemsContainerComponent} from "../../../../core/ui/order-items-container/order-items-container.component";
import {IContact} from "../../../../core/models/contact/contact.interface";
import {Component, EventEmitter, inject, Input, OnInit, Output} from "@angular/core";
import {SequenceGenerator} from "../../../../core/util/generators/sequence.generator";
import {PaymentType} from "../../../../core/models/payment/payment-type";
import {PaymentMethod} from "../../../../core/models/payment/payment-method";
import {PaymentCreationRequest} from "../../../../core/models/payment/payment-creation.request";
import {OrderCreationRequest} from "../../../../core/models/transaction/shared/order/order-creation.request";
import {IOrderItem} from "../../../../core/models/transaction/shared/order-item/order-item.interface";
import {OrderUpdateRequest} from "../../../../core/models/transaction/shared/order/order-update.request";
import {IOrder} from "../../../../core/models/transaction/shared/order/order.interface";
import {RequestType} from "../../../../core/util/enums/request-type.enum";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {NotificationHandler} from "../../../../core/util/handlers/notification.handler";
import {IPayment} from "../../../../core/models/payment/payment.interface";
import {DateConverter} from "../../../../core/util/converters/date-converter";
import {PrintingHandler} from "../../../../core/util/handlers/printing.handler";
import {ReportService} from "../../../../core/services/report/report.service";
import {SourceType} from "../../../../core/models/reporting/source-type";
import {Receipt} from "../../../../core/ui/numeric-pad/numeric-pad.component";
import {ContactService} from "../../../../core/services/contact/contact.service";
import {ProductService} from "../../../../core/services/inventory/product/product.service";
import {CloningHelper} from "../../../../core/util/helpers/cloning.helper";
import {Observable} from "rxjs";
import {EntityGenerator} from "../../../../core/util/generators/entity.generator";
import {DropdownChangeEvent} from "primeng/dropdown";
import {AllowedReportType} from "./reporting/allowed-report.type";
import {VatPolicy} from "./reporting/vat.policy";

@Component({
  template: ''
})
export abstract class OrderBuilderComponent<CreateRequest extends OrderCreationRequest, UpdateRequest extends OrderUpdateRequest> implements OnInit {
  public readonly VatPolicy = VatPolicy;

  private dateConverter = inject(DateConverter);
  private contactService = inject(ContactService);
  private productService = inject(ProductService);
  private reportService = inject(ReportService);

  private printingHandler = inject(PrintingHandler);
  private notificationHandler = inject(NotificationHandler);

  private entityGenerator = inject(EntityGenerator);

  protected toastId = SequenceGenerator.generate();


  @Input()
  requestType!: RequestType;

  @Input()
  allowedReportTypes: AllowedReportType[] = [];

  @Input()
  paymentRequestStrategy = PaymentRequestStrategy.NO_PAYMENT;

  @Input()
  printingToastId!: string;

  @Output()
  onSuccessHandler = new EventEmitter<IOrder>;

  protected order: IOrder | undefined;
  protected contact: IContact | undefined;
  protected occurredAt: Date | undefined = new Date();
  protected selectedReportType: AllowedReportType | undefined;
  protected vatPercent = 19.0;
  protected includeVat = false;

  protected validating = false;

  protected paymentReceiptVisibility = false;
  protected paymentFormVisibilityEventEmitter = new EventEmitter<IPayment | undefined>;

  protected customerOrder: boolean = false;
  protected providerOrder: boolean = false;
  protected vatPolicy = VatPolicy.ENFORCE_NO_VAT;
  protected paymentType!: PaymentType;
  protected totalAmount = 0;

  ngOnInit(): void {
    this.customerOrder = this.isCustomerOrder();
    this.providerOrder = this.isProviderOrder();
    this.paymentType = this.getPaymentType();
    this.selectedReportType = this.allowedReportTypes[0];

    this.updateVatPolicy(this.selectedReportType.vatPolicy);
  }

  // Prepare the order for creation / edition
  public prefill(order: IOrder | undefined) {
    this.order = order;

    if (order) {
      const occurredAt = order.occurredAt;
      const description = order.description;
      const orderItems = order.orderItemResponses.map(orderItem => CloningHelper.orderItem(orderItem));

      // Form data filling
      this.occurredAt = this.dateConverter.fromString(occurredAt);

      // Remote calls filling
      this.contactService.findById(order.contactId).subscribe({
        next: (contact) => this.contact = contact
      });

      // @ts-ignore
      const productIds: string[] = order.orderItemResponses
        .filter(orderItem => orderItem.productId != undefined)
        .map(orderItem => orderItem.productId);

      this.productService.findByIds(productIds).subscribe({
        next: (products) => {
          orderItems.forEach(orderItem => {
            orderItem.product = products.find(product => product.id == orderItem.productId);
          });
        }
      });

      // Children filling
      this.getOrderItemsContainer().setItems(orderItems);
      this.getOrderItemsContainer().setDescription(description);
    }
  }

  // Main validation process
  protected onValidate() {
    // Clear old errors
    this.notificationHandler.clearInputsErrors();

    const reportType = this.selectedReportType;
    const description = this.getOrderItemsContainer().getDescription();

    // Validation
    const contact = this.contact;
    const occurredAt = this.occurredAt;
    const taxable = this.includeVat;
    const items = this.getOrderItemsContainer().getItems();

    if (!contact) {
      this.notificationHandler.addInputError("input", "contact");

      return this.notificationHandler.notifyValidationOperationFailure(this.toastId, 'Champs du contact vide', "Veuillez d'abords sélectionner une personne avant de valider");
    }

    if (!occurredAt) {
      this.notificationHandler.addInputError("input", "occurredAt");

      return this.notificationHandler.notifyValidationOperationFailure(this.toastId, 'Champs de la date vide', "Veuillez d'abords sélectionner la date avant de valider");
    }

    if (items.length == 0) {
      return this.notificationHandler.notifyValidationOperationFailure(this.toastId, 'Panier vide', "Veuillez d'abords remplir votre panier avant de valider");
    }

    // Processing
    switch (this.paymentRequestStrategy) {
      case PaymentRequestStrategy.NO_PAYMENT:
        this.persistOrder({
          contact: contact!,
          occurredAt: occurredAt!,
          reportType: reportType!,
          description: description,
          taxable: taxable,
          orderItems: items,
          paymentCreationRequest: undefined
        });
        break;
      case PaymentRequestStrategy.RECEIPT_PAYMENT:
        this.paymentReceiptVisibility = true;
        break;
      case PaymentRequestStrategy.FORM_PAYMENT:
        const paymentType = this.paymentType;
        const totalAmount = this.getOrderItemsContainer().getTotalAmount();
        const payment = this.entityGenerator.getEmptyPayment(paymentType, contact, totalAmount, occurredAt);

        this.paymentFormVisibilityEventEmitter.emit(payment);
        break;
    }
  }

  // Order confirmation
  protected onPaymentRequestConfirmed(paymentCreationRequest: PaymentCreationRequest) {
    const contact = this.contact;
    const occurredAt = this.occurredAt;
    const taxable = this.includeVat;
    const reportType = this.selectedReportType;

    const items = this.getOrderItemsContainer().getItems();
    const description = this.getOrderItemsContainer().getDescription();

    this.persistOrder({
      contact: contact!,
      occurredAt: occurredAt!,
      reportType: reportType!,
      taxable: taxable,
      orderItems: items,
      description: description,
      paymentCreationRequest: paymentCreationRequest
    });
  }

  protected onReceiptRequestConfirmed(receipt: Receipt) {
    this.paymentReceiptVisibility = false;

    const contact = this.contact;
    const occurredAt = this.occurredAt;
    const paymentType = this.paymentType;
    const reportType = this.selectedReportType;
    const taxable = this.includeVat;

    const amount = this.getOrderItemsContainer().getTotalAmount();
    const description = this.getOrderItemsContainer().getDescription();
    const items = this.getOrderItemsContainer().getItems();

    this.persistOrder({
      contact: contact!,
      occurredAt: occurredAt!,
      reportType: reportType!,
      taxable: taxable,
      description: description,
      orderItems: items,
      paymentCreationRequest: {
        amount: amount,
        contactId: contact!.id,
        occurredAt: occurredAt!,
        paymentType: paymentType,
        paymentMethod: PaymentMethod.CASH,

        description: '',
        order: undefined
      }
    });
  }

  protected onReceiptRequestCanceled() {
    this.paymentReceiptVisibility = false;
  }

  // Items filling
  protected onProductSelected(product: IProduct) {
    this.getOrderItemsContainer().addItem(product);
  }

  protected onTotalAmountChanged(totalAmount: number) {
    this.totalAmount = totalAmount;
  }

  // Other callbacks
  protected onPrintableReportChange(event: DropdownChangeEvent) {
    this.updateVatPolicy(event.value.vatPolicy);
  }

  private updateVatPolicy(vatPolicy: VatPolicy): void {
    this.vatPolicy = vatPolicy;

    switch (vatPolicy) {
      case VatPolicy.ENFORCE_NO_VAT:
        this.includeVat = false;
        break
      case VatPolicy.ENFORCE_NO_VAT_HIDDEN:
        this.includeVat = false;
        break;
      case VatPolicy.ENFORCE_VAT:
        this.includeVat = true;
        break;
      case VatPolicy.NO_ENFORCEMENT_DEFAULT_TRUE:
        this.includeVat = true;
        break;
      case VatPolicy.NO_ENFORCEMENT_DEFAULT_FALSE:
        this.includeVat = false;
        break;
    }
  }

  // Helpers
  private persistOrder(formData: OrderFormData) {
    this.validating = true;

    switch (this.requestType) {
      case RequestType.CREATE:
        this.createOrder(this.order!, formData);
        break;
      case RequestType.UPDATE:
        this.updateOrder(this.order!, formData);
        break;
    }
  }

  private createOrder(order: IOrder, formData: OrderFormData) {
    let createRequest = this.buildCreateRequest(order, formData);

    this.getService().create(createRequest).subscribe({
      next: response => {
        this.onConfirmSuccessful(response);
      },

      error: (error) => {
        this.onConfirmFailed(error);
      },
    });
  }

  private updateOrder(order: IOrder, formData: OrderFormData) {
    let updateRequest = this.buildUpdateRequest(order!, formData);

    this.getService().update(order!.id, updateRequest).subscribe({
      next: response => {
        this.onConfirmSuccessful(response);
      },

      error: (error) => {
        this.onConfirmFailed(error);
      },
    });
  }

  private printOrder(order: IOrder): Observable<void> {
    return new Observable(subscriber => {
      const reportType = this.selectedReportType!.reportType;

      this.reportService.orderReport(order.id, this.getSourceType(), reportType!).subscribe({
        next: (report) => {
          this.printingHandler.print(report);

          subscriber.next();
        },

        error: (error) => subscriber.error(error)
      });
    });
  }

  private resetOrder() {
    this.contact = undefined;
    this.occurredAt = new Date();

    this.getOrderItemsContainer().reset();
  }

  // Ending callbacks
  private onConfirmSuccessful(order: IOrder) {
    this.notificationHandler.notifyOperationSuccess(this.toastId);

    // Printing if needed
    const reportType = this.selectedReportType!.reportType;
    if (reportType) {
      this.notificationHandler.notifyPrintingJobInitiated(this.printingToastId);

      setTimeout(() => {
        this.printOrder(order).subscribe({
          next: () => {
            this.notificationHandler.notifyPrintingJobSuccess(this.printingToastId);
          },
          error: (error) => {
            this.notificationHandler.notifyPrintingJobFailure(this.printingToastId);
          }
        });
      }, 1000);
    }

    // Declaring that everything is done successfully
    this.validating = false;
    this.resetOrder();
    this.onSuccessHandler.emit(order);
  }

  private onConfirmFailed(error: any) {
    this.validating = false;
    this.notificationHandler.notifyErrorOperationFailure(this.toastId, error);
  }

  // Abstract functions
  protected abstract getOrderItemsContainer(): OrderItemsContainerComponent;

  protected abstract isCustomerOrder(): boolean;

  protected abstract isProviderOrder(): boolean;

  protected abstract getPaymentType(): PaymentType;

  protected abstract getSourceType(): SourceType;

  protected abstract buildCreateRequest(order: IOrder | undefined, orderFormData: OrderFormData): CreateRequest;

  protected abstract buildUpdateRequest(order: IOrder, orderFormData: OrderFormData): UpdateRequest;

  protected abstract getService(): GenericServiceService<IOrder, any, CreateRequest, UpdateRequest>;
}

export interface OrderFormData {
  contact: IContact,
  occurredAt: Date,
  description: string,
  taxable: boolean,
  reportType: AllowedReportType,
  orderItems: IOrderItem[],
  paymentCreationRequest: PaymentCreationRequest | undefined,
}

export enum PaymentRequestStrategy {
  NO_PAYMENT,
  RECEIPT_PAYMENT,
  FORM_PAYMENT
}
