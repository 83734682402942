import {Component, Input, OnInit} from '@angular/core';
import {ColorsHelper} from "../../util/helpers/colors.helper";


@Component({
  selector: 'app-colorized-progressbar',
  templateUrl: './colorized-progressbar.component.html',
  styleUrl: './colorized-progressbar.component.scss'
})
export class ColorizedProgressbarComponent implements OnInit {
  @Input()
  currentValue: number = 0;

  @Input()
  totalValue: number = 0;

  @Input()
  valueTooltipSuffix: string = "";

  progress!: number;
  percentage!: number;

  ngOnInit(): void {
    if (this.currentValue == undefined || Number.isNaN(this.currentValue)) {
      this.currentValue = 0;
    }

    if (this.totalValue != undefined && !Number.isNaN(this.totalValue) && this.totalValue > 0) {
      this.progress = (this.currentValue / this.totalValue);
      this.percentage = this.progress * 100;
    } else {
      this.progress = 0;
      this.percentage = 0;
    }
  }

  public getColor() {
    return ColorsHelper.toRgb(this.progress);
  }

  public getTextColorClass() {
    if (this.progress >= 0.8)
      return "gray-50";
    return "text-black-alpha-90";
  }

  public getTooltipSuffix() {
    if (this.valueTooltipSuffix && this.valueTooltipSuffix != '') {
      return ' ' + this.valueTooltipSuffix;
    }

    return '';
  }
}
