<p-toast [key]="this.toastId"></p-toast>
<p-blockUI [blocked]="this.validating"></p-blockUI>
<p-dialog [(visible)]="this.paymentReceiptVisibility">
  <ng-template pTemplate="headless">
    <div class="flex flex-column gap-4"
         style="border-radius: 12px; background-image: radial-gradient(circle at left top, var(--surface-0), var(--surface-200))">
      <app-numeric-pad [total]="this.totalAmount"
                       (onConfirm)="this.onReceiptRequestConfirmed($event)"
                       (onCancel)="this.onReceiptRequestCanceled()">
      </app-numeric-pad>
    </div>
  </ng-template>
</p-dialog>

<app-payment-creation-form headerPrefix="Nouveau paiment"
                           [persist]="false"
                           [paymentType]="this.paymentType"
                           [visibilityEventEmitter]="this.paymentFormVisibilityEventEmitter"
                           (onValidateSuccessHandler)="this.onPaymentRequestConfirmed($event)">

</app-payment-creation-form>

<div class="flex flex-column">
  <div class="flex flex-column md:flex-row toolbar">
    <div class="flex flex-column md:flex-row justify-content-between w-full p-2">
      <div class="flex flex-column md:flex-row gap-1">
        <div class="flex flex-column">
          <app-contact-auto-complete id="contact"
                                     [floatLabel]="false"
                                     [(entity)]="this.contact"
                                     [includeCustomers]="this.customerOrder"
                                     [includeProviders]="this.providerOrder"

                                     [parentToastId]="this.toastId">
          </app-contact-auto-complete>
          <p-inputGroup class="mt-1">
            <p-inputGroupAddon>
              <i class="fa-solid fa-calendar-check"></i>
            </p-inputGroupAddon>
            <p-calendar id="occurredAt"
                        dateFormat="dd/mm/yy"
                        inputId="occurredAt"
                        name="occurredAt"
                        placeholder="Réalisé le..."
                        appendTo="body"
                        [showIcon]="true"
                        [showButtonBar]="true"
                        [(ngModel)]="this.occurredAt">
            </p-calendar>
          </p-inputGroup>
        </div>
        <div class="flex flex-column">

        </div>
        <p-divider type="solid" layout="vertical" class="hidden md:inline toolbar-divider"></p-divider>
        <div class="flex flex-column justify-content-center gap-1">
          <div [class]="this.selectedReportType ? 'mt-4': ''">
            <span class="p-float-label">
              <p-dropdown inputId="printableReportType" optionLabel="label" [options]="this.allowedReportTypes"
                          [(ngModel)]="this.selectedReportType" (onChange)="this.onPrintableReportChange($event)">
                <ng-template pTemplate="selectedItem">
                  <div class="flex align-items-center gap-2" *ngIf="this.selectedReportType">
                    <i class="fa-solid fa-file-lines"></i>
                    <div>{{ this.selectedReportType.label }}</div>
                  </div>
                </ng-template>
                <ng-template let-report pTemplate="item">
                  <div class="flex align-items-center gap-2">
                    <i class="fa fa-star"></i>
                    <div>{{ report.label }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
              <label for="printableReportType">Document à imprimer</label>
            </span>
          </div>
          <div class="flex flex-row" *ngIf="this.vatPolicy != VatPolicy.ENFORCE_NO_VAT_HIDDEN">
            <p-checkbox inputId="includeVat" class="font-bold"
                        [binary]="true" [label]="'Inclure la TVA (' + this.vatPercent + '%)'"
                        [(ngModel)]="this.includeVat"
                        [disabled]="this.vatPolicy == VatPolicy.ENFORCE_NO_VAT || this.vatPolicy == VatPolicy.ENFORCE_VAT">
            </p-checkbox>
          </div>
        </div>
      </div>
      <div class="flex flex-column justify-content-center my-2">
        <p-button class="validation-button" icon="pi pi-check" severity="success" (onClick)="this.onValidate()"
                  label="Valider" iconPos="right" [loading]="this.validating"></p-button>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <p-splitter [style]="{'height': '100%'}"
              styleClass="mb-5"
              [layout]="isSmallScreen ? 'vertical' : 'horizontal'"
              class="w-full">
    <ng-template pTemplate>
      <div class="col w-full">
        <app-order-items-table #orderItemsTable
                              class="w-full"
                              [taxPercent]="this.vatPercent"
                              [includeTax]="this.includeVat"
                              (totalAmountChange)="this.onTotalAmountChanged($event)">
        </app-order-items-table>
      </div>
    </ng-template>
    <ng-template pTemplate>
      <div class="col w-full">
        <app-product-table-catalog
          class="w-full"
          [pageSize]="15"
          (onItemSelected)="this.onProductSelected($event)"/>
      </div>
    </ng-template>
  </p-splitter>
  </div>
</div>
