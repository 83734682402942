import {ProductsDashboardComponent} from "./products-dashboard/products-dashboard.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../../shared/components/components.module";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {ConfirmationService, MessageService} from "primeng/api";
import {ProductsCrudTableComponent} from "./products-crud-table/products-crud-table.component";
import {ProductCreationFormComponent} from "./product-creation-form/product-creation-form.component";
import {ProductEditionFormComponent} from "./product-edition-form/product-edition-form.component";
import {ChipsModule} from "primeng/chips";
import {InputNumberModule} from "primeng/inputnumber";
import {CoreModule} from "../../../core/core.module";
import {
  ProductCategoryAutoCompleteModule
} from "../../../core/ui/item-auto-complete/product-category-auto-complete/product-category-auto-complete.module";

@NgModule({
  declarations: [
    ProductsCrudTableComponent,
    ProductsDashboardComponent,

    ProductCreationFormComponent,
    ProductEditionFormComponent
  ],
  exports: [
    ProductCreationFormComponent,
    ProductEditionFormComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ToolbarModule,
    FileUploadModule,
    FormsModule,
    RippleModule,
    ChipModule,
    UiModule,
    StyleClassModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    DividerModule,
    FloatLabelModule,
    ChipsModule,
    InputNumberModule,
    CoreModule,
    ProductCategoryAutoCompleteModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class ProductsModule {

}
