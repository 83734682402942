import {Directive, OnDestroy, OnInit} from "@angular/core";
import {map, Subscription} from "rxjs";
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[appNumberInput]',
})
export class NumberDirective implements OnInit, OnDestroy {

  private subscription: Subscription | undefined;

  constructor(private ngControl: NgControl) {
  }

  ngOnInit() {
    const ctrl = this.ngControl.control;

    if (ctrl) {
      this.subscription = ctrl.valueChanges
        .pipe(map(v => {
          if (v) {
            // TODO: this is probably blocking order creation with no payments
            return parseFloat(v);
          }

          return undefined;
        }))
        .subscribe(v => ctrl.setValue(v, {emitEvent: false}));
    } else {
      console.warn("Couldn't subscribe to ", this.ngControl);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
