import {Component, inject, OnInit} from '@angular/core';
import {UiService} from "../../services/ui.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {

  protected uiService = inject(UiService);

  ngOnInit() {
  }
}


