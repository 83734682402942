import {Component, OnInit, inject} from '@angular/core';
import {ContactsDashboardComponent} from "../shared/contacts-dashboard/contacts-dashboard.component";


@Component({
  selector: 'app-providers-dashboard',
  templateUrl: './providers-dashboard.component.html',
  styleUrls: ['./providers-dashboard.component.scss']
})
export class ProvidersDashboardComponent extends ContactsDashboardComponent {
}
