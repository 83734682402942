import {EntityFormComponentEntry, ItemAutoCompleteComponent} from "../item-auto-complete.component";
import {IProductCategory} from "../../../models/inventory/product-category/product-category.interface";
import {Observable} from "rxjs";
import {Component, inject, Input} from "@angular/core";
import {ProductCategorySearchRequest} from "../../../models/inventory/product-category/product-category-search.request";
import {ProductCategoryService} from "../../../services/inventory/product-category/product-category.service";
import {GenericSearchResponse} from "../../../models/shared/generic-search.response";
import {
  ProductCategoryCreationFormComponent
} from "../../../../features/inventory/product-category/product-category-form/product-category-creation-form/product-category-creation-form.component";
import {
  ProductCategoryEditionFormComponent
} from "../../../../features/inventory/product-category/product-category-form/product-category-edition-form/product-category-edition-form.component";

@Component({
  selector: 'app-product-category-auto-complete',
  templateUrl: '../item-auto-complete.component.html',
  styleUrl: '../item-auto-complete.component.scss'
})
export class ProductCategoryAutoCompleteComponent extends ItemAutoCompleteComponent<IProductCategory> {

  productCategoryService = inject(ProductCategoryService);

  performSearch(query: string): Observable<GenericSearchResponse<IProductCategory>> {
    const request: ProductCategorySearchRequest = new ProductCategorySearchRequest(query, 0, 15);

    return this.productCategoryService.search(request);
  }

  getPlaceholder(): string {
    return "Catégorie";
  }

  getIcon(): string {
    return "fa fa-sitemap";
  }

  getCreationHeaderPrefix(): string {
    return "Nouvelle catégorie";
  }

  getEditionHeaderPrefix(): string {
    return "Catégorie";
  }

  // @ts-ignore
  getCreationFormType(): EntityFormComponentEntry<ProductCategoryCreationFormComponent> {
    return {
      type: ProductCategoryCreationFormComponent,
      extraInputs: []
    };
  }

  // @ts-ignore
  getEditionFormType(): EntityFormComponentEntry<ProductCategoryEditionFormComponent> {
    return {
      type: ProductCategoryEditionFormComponent,
      extraInputs: []
    };
  }
}
