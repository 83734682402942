import {ItemSplitButtonComponent} from "../item-split-button.component";
import {IContact} from "../../../models/contact/contact.interface";
import {Component, EventEmitter, Input} from "@angular/core";
import {IWarranty, IWarrantyUtils} from "../../../models/warranty/warranty.interface";

@Component({
  selector: 'app-contact-split-button',
  templateUrl: '../item-split-button.component.html',
  styleUrl: '../item-split-button.component.scss'
})
export class ContactSplitButtonComponent extends ItemSplitButtonComponent<IContact> {

  @Input()
  historyReportDate!: Date;

  @Input()
  warrantyCreateFormVisibilityEventEmitter!: EventEmitter<IWarranty | undefined>;

  override ngOnInit() {
    this.items.push(
      {
        icon: 'pi pi-file',
        label: 'Imprimer',
        styleClass: this.itemsStyleClass ? this.itemsStyleClass : '',

        items: [
          {
            icon: 'pi pi-arrow-right',
            label: 'Garantie',
            target: 'body',
            command: () => {
              this.printWithCustomDialog(this.warrantyCreateFormVisibilityEventEmitter, IWarrantyUtils.fromContact(this.item));
            }
          },
          {
            icon: 'pi pi-arrow-right',
            label: 'Historique du solde',
            target: 'body',
            command: e => {
              this.printWithDefaultDialog<number>('Historique du solde', "Veuillez saisir l'année des transactions à considérer, ou laisser vide pour tout include", this.historyReportDate?.getFullYear(), (id: string, year: number) => this.reportService.transactionHistory(id, year));
            }
          },
          {
            icon: 'pi pi-arrow-right',
            label: 'Historique détaillé des transactions',
            target: 'body',
            command: (e) => {
              this.printWithDefaultDialog('Historique detaillé des transactions', "Veuillez saisir l'année des transactions à considérer, ou laisser vide pour tout include", this.historyReportDate?.getFullYear(), (id: string, year: number) => this.reportService.detailedTransactionHistory(id, year));
            }
          }
        ]
      });

    super.ngOnInit();
  }
}
