<p-sidebar [(visible)]="uiService.sideBarVisibility">
  <ng-template pTemplate="headless">
    <div class="flex flex-row align-items-center p-3">
      <p-button [text]="true" styleClass="p-2" class="m-0 mr-3"
                (onClick)="uiService.showSideBar()">
        <p-avatar image="./assets/logo.png" size="large" shape="circle"></p-avatar>
      </p-button>
      <span class="h-fit text-4xl">TasyirSoft</span>
    </div>

    <ul class="list-none p-3 m-0">
      <li>
        <a [routerLink]="['/home']" routerLinkActive="active"
           pRipple
           class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple vertical-align-middle no-underline">
          <i class="fa-solid fa-house mr-2"></i>
          <span class="font-medium uppercase">Dashboard</span>
        </a>
      </li>

      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-people-arrows mr-2"></i>
          <span class="font-medium">CONTACTS</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/contacts/customers']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-person-walking-arrow-right mr-2"></i>
              <span class="font-medium uppercase">Liste des clients</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/contacts/providers']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-person-walking-arrow-loop-left mr-2"></i>
              <span class="font-medium uppercase">Liste des fournisseurs</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-warehouse mr-2"></i>
          <span class="font-medium">INVENTAIRE</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/inventory/productCategories']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-sitemap mr-2"></i>
              <span class="font-medium uppercase">Categories de produit</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/inventory/products']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-boxes-stacked mr-2"></i>
              <span class="font-medium uppercase">Liste des produits</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-file-invoice mr-2"></i>
          <span class="font-medium">ACHATS</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/purchaseorders/tablePurchaseOrderDashboard']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-plus mr-2"></i>
              <span class="font-medium uppercase">Nouvelle achat</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/purchaseorders/purchaseOrders']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-file-import mr-2"></i>
              <span class="font-medium uppercase">Registre des achats</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-file-invoice-dollar mr-2"></i>
          <span class="font-medium">VENTES</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/saleorders/counterTableSaleOrderDashboard']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-plus mr-2"></i>
              <span class="font-medium uppercase">Nouvelle vente comptoire</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/saleorders/invoiceableTableSaleOrderDashboard']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-plus mr-2"></i>
              <span class="font-medium uppercase">Nouvelle vente facturée</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/saleorders/saleOrders']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-file-import fa-flip-horizontal mr-2"></i>
              <span class="font-medium uppercase">Registre des ventes</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-money-bill-transfer mr-2"></i>
          <span class="font-medium">PAIMENTS</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/payments/customerPayments']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-hand-holding-dollar mr-2"></i>
              <span class="font-medium uppercase">Paiments des clients</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/payments/providerPayments']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-hand-holding-dollar fa-flip-horizontal mr-2"></i>
              <span class="font-medium uppercase">Paiments des fournisseurs</span>
            </a>
          </li>
        </ul>
      </li>

      <li>
        <a
          pRipple
          pStyleClass="@next"
          enterFromClass="hidden"
          enterActiveClass="slidedown"
          leaveToClass="hidden"
          leaveActiveClass="slideup"
          class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
          <i class="fa-solid fa-file-lines mr-2"></i>
          <span class="font-medium">FACTURATION</span>
          <i class="pi pi-chevron-down ml-auto"></i>
        </a>
        <ul
          class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
          <li>
            <a [routerLink]="['/reportingorders/invoices']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-file-invoice mr-2"></i>
              <span class="font-medium uppercase">Liste des factures</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/reportingorders/estimates']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-file-invoice-dollar mr-2"></i>
              <span class="font-medium uppercase">Liste des devis</span>
            </a>
          </li>
          <li>
            <a [routerLink]="['/reportingorders/proformaInvoices']" routerLinkActive="active" pRipple
               class="flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple no-underline">
              <i class="fa-solid fa-file-invoice-dollar mr-2"></i>
              <span class="font-medium uppercase">Liste des factures proformas</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-template>
</p-sidebar>
