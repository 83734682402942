import {ProductCategoryRequest} from "../../../../../core/models/inventory/product-category/product-category.request";
import {
  ProductCategoryCreateRequest
} from "../../../../../core/models/inventory/product-category/product-category-create.request";
import {
  ProductCategoryUpdateRequest
} from "../../../../../core/models/inventory/product-category/product-category-update.request";
import {IProductCategory} from "../../../../../core/models/inventory/product-category/product-category.interface";

export class ProductCategoryFormData extends ProductCategoryRequest {

  public toProductCategoryCreationRequest(): ProductCategoryCreateRequest {
    const request = new ProductCategoryCreateRequest();
    request.name = this.name;

    return request;
  }

  public toProductCategoryUpdateRequest(): ProductCategoryUpdateRequest {
    const request = new ProductCategoryUpdateRequest();
    request.name = this.name;

    return request;
  }

  public static fromProductCategory(productCategory: IProductCategory | undefined): ProductCategoryFormData {
    const formData = new ProductCategoryFormData();

    if (productCategory) {
      formData.name = productCategory.name;
    }

    return formData;
  }
}
