import {inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {MessageService} from "primeng/api";
import {BusinessErrorHandler} from "./business-error.handler";
import {ValidationViolation} from "../../ui/validation/validation-report";

@Injectable()
export class NotificationHandler {

  private renderer: Renderer2;
  private messageService = inject(MessageService);
  private businessErrorHandler = inject(BusinessErrorHandler);

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  // Toast notifications
  public notifyValidationOperationFailure(toastId: string, summary: string, message: string) {
    this.messageService.add({
      key: toastId,
      severity: 'error',
      summary: 'Opération échouée',
      detail: message
    });
  }

  public notifyErrorOperationFailure(toastId: string, error: any) {
    this.messageService.add({
      key: toastId,
      severity: 'error',
      summary: 'Opération échouée',
      detail: this.businessErrorHandler.convert(error)
    });
  }

  public notifyOperationSuccess(toastId: string) {
    this.messageService.add({
      key: toastId,
      severity: 'success',
      summary: 'Opération réussie',
      detail: 'Opération faite avec succés'
    });
  }

  // Form notifications
  public clearInputsErrors() {
    // Clear old errors
    const inputs = document.getElementsByTagName("input");
    const dropdowns = document.getElementsByTagName("p-dropdown");

    this.clearInputError(inputs);
    this.clearInputError(dropdowns);
  }

  public addInputError(elementTag: string, fieldName: string) {
    const element = document.querySelector(elementTag + "[id='" + fieldName + "']");

    if (element) {
      this.renderer.addClass(element, "ng-invalid");
      this.renderer.addClass(element, "ng-dirty");
    }
  }

  public addInputsViolation(violation: ValidationViolation, toastId: string) {
    this.addInputViolation(violation, 'input', toastId);
    this.addInputViolation(violation, 'p-dropdown', toastId);
  }

  public addInputViolation(violation: ValidationViolation, elementTag: string, toastId: string) {
    const element = document.querySelector(elementTag + "[id='" + violation.fieldName + "']");

    if (element) {
      this.renderer.addClass(element, "ng-invalid");
      this.renderer.addClass(element, "ng-dirty");
      this.messageService.add({
        key: toastId,
        severity: 'error',
        summary: violation.errorSummary,
        detail: violation.errorDetails
      });
    }
  }

  private clearInputError(elements: HTMLCollectionOf<any>) {
    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        let element = elements.item(i);

        this.renderer.removeClass(element, "ng-invalid");
        this.renderer.removeClass(element, "ng-dirty");
      }
    }
  }
}
