import {GenericSearchRequest} from "../../../shared/generic-search.request";
import {RangeSearchRequestInterface} from "../../../shared/range-search-request.interface";
import {ContactSearchRequestInterface} from "../../../shared/contact-search-request.interface";

export class OrderSearchRequest extends GenericSearchRequest implements RangeSearchRequestInterface, ContactSearchRequestInterface {

  occurredAfter: string | undefined;
  occurredBefore: string | undefined;
  referenceNumber: string | undefined;
  serialNumber: string | undefined;
  contactId: string | undefined;

  constructor(occurredAfter: string | undefined, occurredBefore: string | undefined,
              referenceNumber: string | undefined, serialNumber: string | undefined,
              contactId: string | undefined,
              pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined
  ) {
    super(pageIndex, pageSize, offsetIndicators);

    this.occurredAfter = occurredAfter;
    this.occurredBefore = occurredBefore;
    this.referenceNumber = referenceNumber;
    this.serialNumber = serialNumber;
    this.contactId = contactId;
  }
}
