import {OrderRepository} from "./order.repository";
import {IPurchaseOrder} from "../../models/transaction/purchase-order/purchase-order.interface";
import {PurchaseOrderSearchRequest} from "../../models/transaction/purchase-order/purchase-order-search.request";
import {PurchaseOrderCreationRequest} from "../../models/transaction/purchase-order/purchase-order-creation.request";
import {PurchaseOrderUpdateRequest} from "../../models/transaction/purchase-order/purchase-order-update.request";
import {HttpService} from "../../services/http/http.service";
import {Injectable} from "@angular/core";

@Injectable()
export class PurchaseOrderRepository extends OrderRepository<IPurchaseOrder, PurchaseOrderSearchRequest, PurchaseOrderCreationRequest, PurchaseOrderUpdateRequest> {

  constructor(httpService: HttpService) {
    super(httpService, "/purchaseOrders");
  }
}
