import {GenericSearchRequest} from "../../shared/generic-search.request";

export class ProductCategorySearchRequest extends GenericSearchRequest {

  name: string | undefined;

  constructor(name: string | undefined, pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined = undefined) {
    super(pageIndex, pageSize, offsetIndicators);

    this.name = name;
  }
}
