<div class="p-4 flex flex-row flex-wrap" style="max-width: 625px">
  <div class="card mb-4">
    <p-fieldset>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
          <p-avatar [image]="this.authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.logoUrl"
                    size="large"
                    shape="circle" class="mr-2 mt-1"></p-avatar>
          <span [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.name"
                class="font-bold text-xl"></span>
        </div>
      </ng-template>

      <div class="card">
        <div class="flex flex-column flex-wrap">
          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-10rem">
              <span class="font-bold text-lg">ID:</span>
            </div>
            <div>
              <span class="vertical-align-middle"
                    [textContent]="authenticationService.getUserDetails()?.attributes?.account?.id"></span>
            </div>
          </div>
          <div class="flex flex-row align-content-center flex-wrap mb-1">
            <div class="w-10rem">
              <span class="font-bold text-lg">N° de série:</span>
            </div>
            <div>
              <span
                [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.serialNumber"
                class="vertical-align-middle"></span>
            </div>
          </div>
          <p-divider></p-divider>
          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-10rem">
              <span class="font-bold text-lg">E-mail:</span>
            </div>
            <div>
              <span [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.email"
                    class="vertical-align-middle"></span>
            </div>
          </div>

          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-10rem">
              <span class="font-bold text-lg">Adresse:</span>
            </div>
            <div>
              <span [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.address"
                    class="vertical-align-middle"></span>
            </div>
          </div>

          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-10rem">
              <span class="font-bold text-lg">N° de telephone:</span>
            </div>
            <div>
              <span
                [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.phoneNumbers"
                class="vertical-align-middle"></span>
            </div>
          </div>

          <p-divider></p-divider>

          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-12rem">
              <span class="font-bold text-lg">NIF:</span>
            </div>
            <div>
              <span
                [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.fiscalNumber"
                class="vertical-align-middle"></span>
            </div>
          </div>

          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-12rem">
              <span class="font-bold text-lg">NIS:</span>
            </div>
            <div>
              <span
                [textContent]="authenticationService.getUserDetails()?.attributes?.account?.companyDetails?.statisticsNumber"
                class="vertical-align-middle"></span>
            </div>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>
  <div class="card">
    <p-fieldset>
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2 px-2">
          <p-avatar [image]="this.authenticationService.getUserDetails()?.attributes?.picture" size="large"
                    shape="circle" class="mr-2 mt-1"></p-avatar>
          <span [textContent]="authenticationService.getUserDetails()?.attributes?.name"
                class="font-bold text-xl"></span>
        </div>
      </ng-template>

      <div class="card">
        <div class="flex flex-column flex-wrap">
          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-12rem">
              <span class="font-bold text-lg">ID:</span>
            </div>
            <div>
              <span [textContent]="authenticationService.getUserDetails()?.attributes?.id"
                    class="vertical-align-middle"></span>
            </div>
          </div>
          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-12rem">
              <span class="font-bold text-lg">E-mail:</span>
            </div>
            <div>
              <span [textContent]="authenticationService.getUserDetails()?.attributes?.email"
                    class="vertical-align-middle"></span>
            </div>
          </div>
          <div class="flex flex-row flex-wrap mb-1">
            <div class="w-12rem">
              <span class="font-bold text-lg">Nom d'utilisateur:</span>
            </div>
            <div>
              <span [textContent]="authenticationService.getUserDetails()?.attributes?.username"
                    class="vertical-align-middle"></span>
            </div>
          </div>
          <p-divider></p-divider>
          <div class="flex flex-row flex-wrap mb-1">
            <div class="flex flex-row align-content-center w-12rem">
              <span class="font-bold text-lg mt-1">E-mail vérifié ?</span>
            </div>
            <div>
              <p-inputSwitch [disabled]="true"
                             [ngModel]="authenticationService.getUserDetails()?.attributes?.emailVerified"></p-inputSwitch>
            </div>
          </div>
          <div class="flex flex-row flex-wrap">
            <div class="w-12rem">
              <span class="font-bold text-lg">Dernière connexion:</span>
            </div>
            <div>
              <span class="vertical-align-middle"
                    [textContent]="MathHelper.toDate(authenticationService.getUserDetails()?.attributes?.updatedAt) | date: 'dd-MM-yyyy @ HH:mm:ss'"></span>
            </div>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>
</div>
