import { Component, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";

export interface Receipt {
  remaining: number,
  paid: number,
}

@Component({
  selector: 'app-numeric-pad',
  templateUrl: './numeric-pad.component.html',
  styleUrls: ['./numeric-pad.component.scss']
})
export class NumericPadComponent<T> implements OnInit {
  @Input() total: number = 0;
  @Output() onConfirm: EventEmitter<Receipt> = new EventEmitter<Receipt>();
  @Output() onCancel: EventEmitter<T> = new EventEmitter();

  paymentAmount: number = this.total;
  remaining: number = 0;
  isFirstChange: boolean = true;

  protected confirm(): void {
    this.getRemaining();
    this.onConfirm.emit({ remaining: this.remaining, paid: parseInt(this.paymentAmount.toString()) });
  }

  protected cancel(): void {
    this.onCancel.emit();
  }

  protected append(val: number): void {
    if(this.isFirstChange) {
      this.paymentAmount = val;
      this.getRemaining();
      this.isFirstChange = false;
    } else {
      this.paymentAmount = parseInt(this.paymentAmount.toString() + val);
      this.getRemaining();
    }
  }

  protected clearInput(): void {
    this.paymentAmount = 0;
    this.getRemaining();
  }

  protected delete(): void {
    if (this.paymentAmount >= 10) {
      this.paymentAmount = parseInt(this.paymentAmount.toString().slice(0, -1));
    } else {
      this.paymentAmount = 0;
    }
    this.getRemaining();
  }

  private getRemaining(): void {
    this.remaining = this.total - this.paymentAmount;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    const key = event.key;
    if (!isNaN(parseInt(key))) {
      this.append(parseInt(key));
    } else if (key === 'Backspace') {
      this.delete();
    } else if (key === 'Escape') {
      this.cancel();
    } else if (key === 'Enter') {
      this.confirm();
    }
  }

  ngOnInit(): void {
    this.paymentAmount = this.total;
  }
}
