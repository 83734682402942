import {Component, EventEmitter, inject, Input, OnInit, Output} from "@angular/core";
import {Observable} from "rxjs";
import {ValidationReport} from "../validation/validation-report";
import {MessageService} from "primeng/api";
import {NameableEntity} from "../../models/shared/nameable.entity";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";
import {BusinessErrorHandler} from "../../util/handlers/business-error.handler";
import {SequenceGenerator} from "../../util/generators/sequence.generator";
import {NotificationHandler} from "../../util/handlers/notification.handler";

@Component({template: ''})
export abstract class EntityFormComponent<Entity extends IdentifiableEntity & NameableEntity, FormData> implements OnInit {

  messageService = inject(MessageService);
  businessErrorHandler = inject(BusinessErrorHandler);

  notificationHandler = inject(NotificationHandler);

  @Input()
  entity: Entity | undefined;

  @Input()
  visibilityEventEmitter!: EventEmitter<Entity | undefined>;

  @Input()
  headerPrefix!: string;

  @Input()
  persist: boolean = true;

  @Output()
  onValidateSuccessHandler = new EventEmitter<FormData>();

  @Output()
  onPersistSuccessHandler: EventEmitter<Entity> = new EventEmitter<Entity>();

  @Output()
  onFailureHandler: EventEmitter<Entity> = new EventEmitter<Entity>();

  protected toastId = SequenceGenerator.generate();
  protected formData!: FormData;

  protected headerLabel: string | undefined;
  protected visible: boolean = false;
  protected confirming = false;

  ngOnInit(): void {
    this.formData = this.onCreateFormData(this.entity);
    this.visibilityEventEmitter.subscribe(entity => {
      this.onResetFormData(entity, this.formData).subscribe(formData => {
        this.formData = formData;
        this.headerLabel = this.headerPrefix + (entity && entity.name && entity.name.length > 0 ? ": " + entity.name : "");

        this.entity = entity;
        this.visible = true;
      });
    });
  }

  public cancel() {
    this.visible = false;
  }

  public validate() {
    // Resetting inputs
    this.notificationHandler.clearInputsErrors();

    // Validate inputs
    const validationReport = this.onValidate(this.formData);

    if (validationReport.violations.length > 0) {
      // Marking invalid inputs
      validationReport.violations.forEach(violation => {
        this.notificationHandler.addInputsViolation(violation, this.toastId);
      });
    } else if (this.persist) {
      // Processing request
      this.confirming = true;
      this.onConfirm(this.entity, this.formData).subscribe({
        next: response => {
          this.visible = false;
          this.confirming = false;

          this.onPersistSuccessHandler.emit(response);
        },

        error: error => {
          console.error(error);

          this.confirming = false;
          this.messageService.add({
            key: this.toastId,
            severity: 'error',
            summary: 'Opération échouée',
            detail: this.businessErrorHandler.convert(error)
          });

          this.onFailureHandler.emit(error);
        }
      });
    } else {
      this.visible = false;
      this.onValidateSuccessHandler.emit(this.formData);
    }
  }

  protected abstract onCreateFormData(entity: Entity | undefined): FormData;

  protected abstract onValidate(formData: FormData): ValidationReport;

  protected abstract onConfirm(entity: Entity | undefined, formData: FormData): Observable<Entity>;

  protected onResetFormData(entity: Entity | undefined, currentFormData: FormData): Observable<FormData> {
    return new Observable<FormData>(subscriber => subscriber.next(this.onCreateFormData(entity)));
  }
}
