import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputGroupModule } from "primeng/inputgroup";
import { NumericPadComponent } from "./numeric-pad.component";
import { InputNumberModule } from "primeng/inputnumber";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { FormsModule } from "@angular/forms";

@NgModule(
{
  declarations: [
    NumericPadComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    InputTextModule,
    InputGroupAddonModule,
    InputGroupModule,
    InputNumberModule,
    FormsModule,
    ButtonModule,
  ],
  exports: [
    NumericPadComponent
  ]
})
export class NumericPadModule{

}
