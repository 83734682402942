import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../shared/components/components.module";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {ConfirmationService, MessageService} from "primeng/api";
import {
  ContactAutoCompleteModule
} from "../../core/ui/item-auto-complete/contact-auto-complete/contact-auto-complete.module";
import {CoreModule} from "../../core/core.module";
import {InputNumberModule} from "primeng/inputnumber";
import {InputTextareaModule} from "primeng/inputtextarea";
import {
  PurchaseOrdersDashboardComponent
} from "./purchase-orders/purchase-orders-dashboard/purchase-orders-dashboard.component";
import {PurchaseOrdersTableComponent} from "./purchase-orders/purchase-orders-table/purchase-orders-table.component";
import {SaleOrdersDashboardComponent} from "./sale-orders/sale-orders-dashboard/sale-orders-dashboard.component";
import {SaleOrdersTableComponent} from "./sale-orders/sale-orders-table/sale-orders-table.component";
import {
  ReportingOrdersTableComponent
} from "./reporting-orders/shared/reporting-orders-table/reporting-orders-table.component";
import {
  EstimateOrdersDashboardComponent
} from "./reporting-orders/estimate-orders-dashboard/estimate-orders-dashboard.component";
import {
  ProformaInvoiceOrdersDashboardComponent
} from "./reporting-orders/proforma-invoice-orders-dashboard/proforma-invoice-orders-dashboard.component";
import {
  InvoiceOrdersDashboardComponent
} from "./reporting-orders/invoice-orders-dashboard/invoice-orders-dashboard.component";
import {OrderBuilderModule} from "./shared/order-builder/order-builder.module";
import {
  InvoiceableTableSaleOrderBuilderDashboard
} from "./sale-orders/table-sale-order-builder-dashboard/invoiceable-table-sale-order-builder-dashboard/invoiceable-table-sale-order-builder-dashboard";
import {
  CounterTableSaleOrderBuilderDashboard
} from "./sale-orders/table-sale-order-builder-dashboard/counter-table-sale-order-builder-dashboard/counter-table-sale-order-builder-dashboard";
import {
  TablePurchaseOrderBuilderDashboardComponent
} from "./purchase-orders/table-purchase-order-builder-dashboard/table-purchase-order-builder-dashboard.component";
import { SpeedDialModule } from "primeng/speeddial";

@NgModule({
  declarations: [
    PurchaseOrdersTableComponent,
    PurchaseOrdersDashboardComponent,
    TablePurchaseOrderBuilderDashboardComponent,

    SaleOrdersTableComponent,
    SaleOrdersDashboardComponent,
    CounterTableSaleOrderBuilderDashboard,
    InvoiceableTableSaleOrderBuilderDashboard,

    ReportingOrdersTableComponent,
    InvoiceOrdersDashboardComponent,
    EstimateOrdersDashboardComponent,
    ProformaInvoiceOrdersDashboardComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ToolbarModule,
    FileUploadModule,
    FormsModule,
    RippleModule,
    ChipModule,
    UiModule,
    StyleClassModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    DividerModule,
    FloatLabelModule,
    ContactAutoCompleteModule,
    SpeedDialModule,
    CoreModule,
    InputNumberModule,
    InputTextareaModule,
    OrderBuilderModule
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class OrdersModule {

}
