import {
  AfterViewInit,
  Component,
  ComponentRef,
  EnvironmentInjector,
  inject,
  Type,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import {IOrder} from "../../../../core/models/transaction/shared/order/order.interface";
import {OrderCreationRequest} from "../../../../core/models/transaction/shared/order/order-creation.request";
import {OrderSearchRequest} from "../../../../core/models/transaction/shared/order/order-search.request";
import {OrderUpdateRequest} from "../../../../core/models/transaction/shared/order/order-update.request";
import {ProductService} from "../../../../core/services/inventory/product/product.service";
import {PrintableReport} from "../../../../core/ui/item-split-button/order-split-button/order-split-button.component";
import {ContactRangeCrudTableComponent} from "../../../../core/ui/crud-table/contact-range-crud-table.component";
import {OrderBuilderComponent, PaymentRequestStrategy} from "../order-builder/order-builder.component";
import {RequestType} from "../../../../core/util/enums/request-type.enum";
import {SequenceGenerator} from "../../../../core/util/generators/sequence.generator";

@Component({
  template: ''
})
export abstract class OrdersTableComponent<O extends IOrder, SR extends OrderSearchRequest, CR extends OrderCreationRequest, UR extends OrderUpdateRequest> extends ContactRangeCrudTableComponent<O, SR, CR, UR> implements AfterViewInit {
  private environmentInjector = inject(EnvironmentInjector);

  @ViewChild('orderCreationBuilderContainer', {read: ViewContainerRef})
  orderCreationBuilderContainer!: ViewContainerRef;

  @ViewChild('orderEditionBuilderContainer', {read: ViewContainerRef})
  orderEditionBuilderContainer!: ViewContainerRef;

  printingToastId = SequenceGenerator.generate();

  customerTransactions = false;
  providerTransactions = false;

  printableReports: PrintableReport[] | undefined;

  productService = inject(ProductService);

  orderCreationBuilderVisibility = false;
  orderEditionBuilderVisibility = false;

  orderCreationBuilder!: ComponentRef<OrderBuilderComponent<CR, UR>>;
  orderEditionBuilder!: ComponentRef<OrderBuilderComponent<CR, UR>>;

  targetedOrderForUpdate: IOrder | undefined;

  override ngOnInit() {
    super.ngOnInit();

    this.customerTransactions = this.isCustomerTransactions();
    this.providerTransactions = this.isProviderTransactions();
    this.printableReports = this.getPrintableReports();

    this.createFormVisibilityEventEmitter.subscribe(value => {
      this.orderCreationBuilderVisibility = true;
    });

    this.editFormVisibilityEventEmitter.subscribe(value => {
      this.targetedOrderForUpdate = value;
      this.orderEditionBuilder.instance.prefill(value);
      this.orderEditionBuilderVisibility = true;
    })
  }

  ngAfterViewInit(): void {
    // ---> Create Order Builder
    const orderCreationBuilderDefinition = this.getCreationOrderBuilderModalDefinition();
    this.orderCreationBuilder = this.orderCreationBuilderContainer.createComponent(orderCreationBuilderDefinition.component, {environmentInjector: this.environmentInjector});
    this.orderCreationBuilder.setInput('requestType', RequestType.CREATE);
    this.orderCreationBuilder.setInput('paymentRequestStrategy', this.getOrderPaymentStrategy());
    this.orderCreationBuilder.setInput('printingToastId', this.printingToastId);

    for (let input of orderCreationBuilderDefinition.inputs) {
      this.orderCreationBuilder.setInput(input.name, input.value);
    }

    this.orderCreationBuilder.instance.onSuccessHandler.subscribe((order: O) => this.onCreateSuccessful(order));

    // ---> Edit Order Builder
    const orderEditionBuilderDefinition = this.getEditionOrderBuilderModalDefinition();
    this.orderEditionBuilder = this.orderEditionBuilderContainer.createComponent(orderEditionBuilderDefinition.component, {environmentInjector: this.environmentInjector});
    this.orderEditionBuilder.setInput('requestType', RequestType.UPDATE);
    this.orderEditionBuilder.setInput('paymentRequestStrategy', PaymentRequestStrategy.NO_PAYMENT);
    this.orderEditionBuilder.setInput('printingToastId', this.printingToastId);

    for (let input of orderEditionBuilderDefinition.inputs) {
      this.orderEditionBuilder.setInput(input.name, input.value);
    }

    this.orderEditionBuilder.instance.onSuccessHandler.subscribe((order: O) => this.onEditSuccessful(order));
  }

  override onCreateSuccessful(order: O) {
    this.orderCreationBuilderVisibility = false;

    super.onCreateSuccessful(order);
  }

  override onEditSuccessful(order: O) {
    this.orderEditionBuilderVisibility = false;

    super.onEditSuccessful(order);
  }

  protected onViewOrderDetails(order: O) {
    // @ts-ignore
    const productIds: string[] = order.orderItemResponses
      .filter(orderItem => orderItem.productId != undefined)
      .map(orderItem => orderItem.productId);

    this.productService.findByIds(productIds).subscribe({
      next: products => {
        products.forEach(product => {
          order.orderItemResponses.forEach(orderItem => {
            if (product.id == orderItem.productId) {
              orderItem.product = product;
            }
          })
        })
      }
    });
  }

  protected getPrintableReports(): PrintableReport[] {
    return [];
  }

  protected abstract isCustomerTransactions(): boolean;

  protected abstract isProviderTransactions(): boolean;

  protected abstract getCreationOrderBuilderModalDefinition(): OrderBuilderModalDefinition<OrderBuilderComponent<CR, UR>>;

  protected abstract getEditionOrderBuilderModalDefinition(): OrderBuilderModalDefinition<OrderBuilderComponent<CR, UR>>;

  protected abstract getOrderPaymentStrategy(): PaymentRequestStrategy;
  protected abstract isPayableOrder(): boolean;
}

export interface OrderBuilderModalDefinition<T extends OrderBuilderComponent<any, any>> {
  component: Type<T>;
  inputs: {
    name: string,
    value: any
  }[]
}
