<p-toast [key]="this.toastId"></p-toast>
<p-confirmDialog></p-confirmDialog>

<p-confirmDialog key="printingConfirmDialog">
  <ng-template pTemplate="message" let-message>
    <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
      <i class="pi pi-question-circle text-6xl text-primary-500"></i>
      <p>{{ message.message }}</p>
    </div>
  </ng-template>
</p-confirmDialog>

<app-payment-creation-form [paymentType]="this.paymentType"
                           [headerPrefix]="this.addHeaderPrefix"
                           [visibilityEventEmitter]="this.createFormVisibilityEventEmitter"

                           (onPersistSuccessHandler)="this.onCreateSuccessful($event)"
/>

<p-table
  dataKey="id"
  [value]="this.tableSearchManager.items"

  [paginator]="true"
  [rows]="this.pageSize"
  [totalRecords]="this.tableSearchManager.totalItemsCount"

  [lazy]="true"
  [loading]="this.tableSearchManager.searching || this.processing"
  [(first)]="this.tableSearchManager.first"
  (onLazyLoad)="tableSearchManager.search($event)"

  [rowHover]="true"
  [resizableColumns]="true"
  responsiveLayout="stack"
  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

  [scrollable]="true"

  [showFirstLastIcon]="false"
  [showPageLinks]="false"

  currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
      <button pButton pRipple [label]="this.addButtonLabel" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()">
      </button>

      <div class="flex-grow-1 xl:flex-grow-0">
        <p-calendar selectionMode="range"
                    dateFormat="dd/mm/yy"
                    placeholder="Réalisé le..."
                    [showIcon]="true"
                    [showButtonBar]="true"
                    [(ngModel)]="this.occurrenceRange"
                    (ngModelChange)="this.onOccurrenceRangeChanged()">
        </p-calendar>
      </div>

      <div class="flex-grow-1">
        <app-contact-auto-complete id="contact"
                                  [draggable]="true"
                                  [floatLabel]="false"
                                  [(entity)]="this.contact"
                                  [includeCustomers]="PaymentType.INGOING == this.paymentType"
                                  [includeProviders]="PaymentType.OUTGOING == this.paymentType"

                                  [parentToastId]="this.toastId"
                                  (entityChange)="this.onContactChanged()">
        </app-contact-auto-complete>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-hashtag mr-2"></i>Référence</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-user mr-2"></i>Contact</th>
      <th pResizableColumn class="text-center"><i class="fa-regular fa-calendar-plus mr-2"></i>Crée le</th>
      <th pResizableColumn class="text-center"><i class="fa-regular fa-calendar-check mr-2"></i>Réalisé le</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-scale-unbalanced mr-2"></i>Solde avant</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-sack-dollar mr-2"></i>Montant</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-scale-balanced mr-2"></i>Solde après</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-hand-holding-dollar mr-2"></i>Méthode</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-file-invoice-dollar mr-2"></i>Transaction</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-sack-dollar mr-2"></i>Assignation</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-comment mr-2"></i>Déscription</th>

      <th style="width:30px" pFrozenColumn alignFrozen="right"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-payment>
    <tr>
      <td class="text-center font-semibold"><span class="p-column-title">Référence:</span>{{ payment.serialNumber }}
      </td>
      <td class="text-center"><span class="p-column-title">Contact:</span>{{ payment.contact?.name }}</td>

      <td class="text-center"><span class="p-column-title">Crée le:</span>
        <div>
          <span class="font-bold">
          {{ payment.createdAt | date: 'dd MMM yyyy' }}
        </span>
          <span> - </span>
          <span class="font-semibold">
          {{ payment.createdAt | date: 'HH:mm' }}
        </span>
        </div>
      </td>
      <td class="text-center font-bold"><span class="p-column-title">Réalisé le:</span>
        {{ payment.occurredAt | date: 'dd/MM/yyyy' }}
      </td>

      <td class="text-center"><span class="p-column-title">Solde avant:</span>
        <p-tag styleClass="text-sm" value="{{ payment.oldContactBalance | number:'1.2-2' }} DZD" severity="warning">
        </p-tag>
      </td>
      <td class="text-center">
        <span class="p-column-title">Montant:</span>
        <div [class]="(payment.paymentType == PaymentType.INGOING ? 'text-green-700' : 'text-red-600')">
          <i [class]="'mr-1 fa ' + (payment.paymentType == PaymentType.INGOING ? 'fa-plus font-light': 'fa-minus')"></i>
          <span class="font-semibold">{{ payment.amount | number:'1.2-2' }} DZD</span>
        </div>
      </td>
      <td class="text-center"><span class="p-column-title">Solde après:</span>
        <p-tag styleClass="text-sm" value="{{ payment.newContactBalance | number:'1.2-2' }} DZD"
               [severity]="payment.paymentType == PaymentType.INGOING ? 'success' : 'danger'">
        </p-tag>
      </td>

      <td class="text-center"><span class="p-column-title">Méthode:</span>
        <p-tag styleClass="text-sm">{{ PaymentMethodUtils.asString(payment.paymentMethod) }}</p-tag>
      </td>

      <td>
        <span class="p-column-title">Transaction:</span>
        <div *ngIf="payment.order" class="flex flex-row justify-content-center">
          <a
            class="p-1 p-button p-button-help p-button-outlined p-button-raised no-underline">{{ OrderType.toString(payment.order.orderType) }}</a>
        </div>
      </td>

      <td class="text-center">
        <span class="p-column-title">Assignation:</span>
        <app-colorized-progressbar
          [totalValue]="payment.amount"
          [currentValue]="payment.amount - payment.remainingAmount"
          valueTooltipSuffix="DZD">
        </app-colorized-progressbar>
      </td>

      <td><span class="p-column-title">Description:</span>{{ payment.description }}</td>

      <td pFrozenColumn alignFrozen="right">
        <span class="p-column-title">Actions:</span>
        <app-payment-split-button appendTo="body" label="Actions" styleClass="p-button-sm w-full"
                                  itemsStyleClass="table-right-button"
                                  [item]="payment"
                                  [parentToastId]="this.toastId"
                                  [(processing)]="this.processing"
                                  [editable]="false"
                                  [deletable]="true" (deleteEventEmitter)="delete($event)"


        />
      </td>
    </tr>
  </ng-template>
</p-table>
