import { Injectable, Injector, ComponentFactoryResolver, ComponentRef, ApplicationRef } from "@angular/core";
import { BarcodeComponent } from "../../ui/barcode/barcode.component";
import { IProduct } from "../../models/inventory/product/product.interface";

@Injectable({
  providedIn: 'root'
})
export class BarcodeHandler {
  private componentFactoryResolver: ComponentFactoryResolver;
  private injector: Injector;
  private appRef: ApplicationRef;

  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    injector: Injector,
    appRef: ApplicationRef
  ) {
    this.componentFactoryResolver = componentFactoryResolver;
    this.injector = injector;
    this.appRef = appRef;
  }

  public printBarcode(
    productInfo: Pick<IProduct, 'name' | 'barcode' | 'sellingPrice'>,
    size: { height: number, width: number }
  ): void {
    const printWindow = window.open('', '', 'width=800,height=600');

    if (printWindow) {
      const componentRef = this.createBarcodeComponent(productInfo, size.height);

      printWindow.document.open();
      printWindow.document.write(this.generateHtmlTemplate(size));
      printWindow.document.close();

      setTimeout(() => {
        const barcodeElement = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
        printWindow.document.body.appendChild(barcodeElement);
        printWindow.focus();
        printWindow.print();
        printWindow.close();

        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      }, 500);
    }
  }

  private createBarcodeComponent(
    productInfo: Pick<IProduct, 'name' | 'barcode' | 'sellingPrice'>,
    height: number
  ): ComponentRef<BarcodeComponent> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BarcodeComponent);
    const componentRef = componentFactory.create(this.injector);

    (componentRef.instance as BarcodeComponent).productInfo = productInfo;
    (componentRef.instance as BarcodeComponent).height = height;

    this.appRef.attachView(componentRef.hostView);

    return componentRef;
  }

  private generateHtmlTemplate(size: {height: number, width: number}): string {
    return `<html>
          <head>
            <style>
              @page {
                size: ${size.width}mm ${size.height}mm;
                margin: 1px 3px;
                text-align: center !important;
              }
                body {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                margin: 0;
              }
              .label-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 0 2px;
                box-sizing: border-box;
                overflow: hidden;
              }
              .text {
                font-size: 10px;
                margin-top: 0px;
                margin-bottom: 1px;
                text-align: center;
              }
            </style>
          </head>
          <body></body>
        </html>`;
  }
}
