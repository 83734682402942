import {WarrantyFormComponent} from "./warranty-form.component";
import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../../shared/components/components.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {PasswordModule} from "primeng/password";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputNumberModule} from "primeng/inputnumber";

@NgModule({
  declarations: [
    WarrantyFormComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    PasswordModule,
    FloatLabelModule,
    InputNumberModule
  ],
  exports: [
    WarrantyFormComponent
  ]
})
export class WarrantyFormModule {

}
