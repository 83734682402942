import {Component, inject} from "@angular/core";
import {CrudTableComponent} from "../../../../core/ui/crud-table/crud-table.component";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {IProduct} from "../../../../core/models/inventory/product/product.interface";
import {ProductSearchRequest} from "../../../../core/models/inventory/product/product-search.request";
import {ProductCreateRequest} from "../../../../core/models/inventory/product/product-create.request";
import {ProductUpdateRequest} from "../../../../core/models/inventory/product/product-update.request";
import {ProductService} from "../../../../core/services/inventory/product/product.service";

@Component({
  selector: 'app-products-crud-table',
  templateUrl: './products-crud-table.component.html',
  styleUrls: ['./products-crud-table.component.scss'],
})
export class ProductsCrudTableComponent extends CrudTableComponent<IProduct, ProductSearchRequest, ProductCreateRequest, ProductUpdateRequest> {

  productService = inject(ProductService);

  protected getService(): GenericServiceService<IProduct, ProductSearchRequest, ProductCreateRequest, ProductUpdateRequest>{
    return this.productService;
  }

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): ProductSearchRequest {
    return new ProductSearchRequest(undefined, undefined, undefined, undefined, pageIndex, pageSize, offsetIndicators);
  }
}
