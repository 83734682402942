import {Component} from '@angular/core';
import {CrudDashboardComponent} from "../../../../core/ui/crud-dashboard/crud-dashboard.component";


@Component({
  selector: 'app-product-dashboard',
  templateUrl: './products-dashboard.component.html',
  styleUrls: ['./products-dashboard.component.scss']
})
export class ProductsDashboardComponent extends CrudDashboardComponent  {
}
