export class MathHelper {

  public static toDate(timestamp: number | undefined): Date | null {
    if(timestamp){
        return new Date(timestamp * 1000)
    }

    return null;
  }
}
