import {GenericSearchRequest} from "../shared/generic-search.request";

export class ContactSearchRequest extends GenericSearchRequest {

  name: string | undefined
  isCustomer: boolean | undefined;
  isProvider: boolean | undefined;
  ids: string[] | undefined;

  constructor(name: string | undefined, isCustomer: boolean | undefined, isProvider: boolean | undefined, pageIndex: number = 0, pageSize: number = 10, offsetIndicators: any[] | undefined = undefined) {
    super(pageIndex, pageSize, offsetIndicators);

    this.name = name;
    this.isCustomer = isCustomer;
    this.isProvider = isProvider;
  }
}
