import {map, Observable} from "rxjs";
import {GenericRepository} from "../../repositories/shared/generic.repository";

export class GenericServiceService<Entity, SearchRequest, CreateRequest, UpdateRequest> {

  constructor(private repository: GenericRepository<Entity, SearchRequest, CreateRequest, UpdateRequest>) {
  }

  public search(request: SearchRequest) {
    return this.repository.search(request);
  }

  public findById(id: string): Observable<Entity> {
    return this.repository.findById(id);
  }

  public findByIds(ids: string[]): Observable<Entity[]> {
    if (ids.length == 0) {
      return new Observable<Entity[]>(subscriber => subscriber.next([]));
    }

    return this.repository.findByIds(ids);
  }

  public create(request: CreateRequest): Observable<Entity> {
    return this.repository.create(request)
      .pipe(map(v => v.body!));
  }

  public update(id: string, request: UpdateRequest): Observable<Entity> {
    return this.repository.update(id, request)
      .pipe(map(v => v.body!));
  }

  public delete(id: string): Observable<Entity> {
    return this.repository.delete(id)
      .pipe(map(v => v.body!));
  }
}
