import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ConfirmationService, MessageService} from "primeng/api";
import {NameableEntity} from "../../models/shared/nameable.entity";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";
import {BusinessErrorHandler} from "../../util/handlers/business-error.handler";
import {TableSearchManager} from "../../util/managers/table-search.manager";
import {GenericSearchRequest} from "../../models/shared/generic-search.request";
import {GenericServiceService} from "../../services/shared/generic-service.service";
import {SequenceGenerator} from "../../util/generators/sequence.generator";
import {IProduct} from "../../models/inventory/product/product.interface";


@Component({
  template: ''
})
export abstract class ItemTableCatalogComponent<Entity extends IdentifiableEntity & NameableEntity, SearchRequest extends GenericSearchRequest> implements OnInit {
  protected messageService = inject(MessageService);
  protected tableSearchManager!: TableSearchManager<Entity, SearchRequest>;

  @Input()
  pageSize!: number;

  @Output()
  onItemSelected = new EventEmitter<IProduct>();

  protected tableSelectedItem: Entity | undefined;
  protected searchRequest!: SearchRequest;
  protected processing = false;
  protected toastId = SequenceGenerator.generate();

  ngOnInit(): void {
    this.searchRequest = this.buildSearchRequest(this.pageSize, undefined);
    this.tableSearchManager = new TableSearchManager<Entity, SearchRequest>(this.pageSize, this.searchRequest, this.getService());
  }

  protected onSelectItem(product: IProduct) {
    this.onItemSelected.next(product);
  }

  protected abstract buildSearchRequest(pageSize: number, offsetIndicators: any[] | undefined): SearchRequest;

  protected abstract getService(): GenericServiceService<Entity, SearchRequest, any, any>;
}
