import {Component, Input, inject} from '@angular/core';

@Component({
  selector: 'app-quick-access-button',
  templateUrl: './quick-access-button.component.html',
  styleUrls: ['./quick-access-button.component.scss']
})
export class QuickAccessButtonComponent {
  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() link: string = '';
}
