import {NgModule} from "@angular/core";
import {PaymentMethodDropdownComponent} from "./payment-method-dropdown.component";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {FormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    PaymentMethodDropdownComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    DropdownModule,
    FormsModule
  ],
  exports: [
    PaymentMethodDropdownComponent
  ]
})
export class PaymentMethodDropdownModule {

}
