import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ComponentsModule } from "../../shared/components/components.module";
import { QuickAccessButtonComponent } from "./quick-access-button/quick-access-button.component";
import { QuickAccessComponent } from "./quick-access/quick-access.component";

@NgModule({
    declarations: [
        QuickAccessButtonComponent,
        QuickAccessComponent,
    ],
    imports: [
        CommonModule,
        ComponentsModule,
    ],
    exports: [],
})
export class QuickAccessPanelModule {}