import { MenuItem } from "primeng/api";
import {RequestType} from "../../../../core/util/enums/request-type.enum";
import {PaymentRequestStrategy} from "../order-builder/order-builder.component";
import {Component, OnInit} from "@angular/core";

@Component({
  template: ''
})
export abstract class OrderBuilderDashboardComponent implements OnInit {
  public readonly PaymentRequestStrategy = PaymentRequestStrategy;
  public readonly RequestType = RequestType;

  protected tabsCount = 0;
  protected tabPrefix!: string;
  protected orderBuilderTabs: {
    id: number
    title: string
    icon: string
  }[] = [];

  ngOnInit(): void {
    this.tabPrefix = this.getTabPrefix();

    this.onAddTab();
  }

  protected onAddTab() {
    if(this.tabsCount < 12 ) {
      let nextIndex = 0;
      while (this.orderBuilderTabs.some(tab => tab.id === nextIndex)) {
          nextIndex++;
      }
      this.tabsCount++;
      this.orderBuilderTabs.push({
          id: nextIndex,
          title: this.tabPrefix ? (this.tabPrefix + ' ' + (nextIndex + 1)) : ((nextIndex + 1) + ''),
          icon: 'fa-solid fa-user'
      });
    }
}


  protected onCloseTab(tabId: number) {
    if(this.tabsCount > 1) {
      this.orderBuilderTabs = this.orderBuilderTabs.filter(tab => tab.id != tabId);
      this.tabsCount--;
    }
  }

  abstract getTabPrefix(): string;
}
