import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../services/http/http.service';
import {IProduct} from '../../models/inventory/product/product.interface';
import {GenericRepository} from "../shared/generic.repository";
import {ProductSearchRequest} from "../../models/inventory/product/product-search.request";
import {ProductCreateRequest} from "../../models/inventory/product/product-create.request";
import {ProductUpdateRequest} from "../../models/inventory/product/product-update.request";

@Injectable()
export class ProductRepository extends GenericRepository<IProduct, ProductSearchRequest, ProductCreateRequest, ProductUpdateRequest> {
  constructor(httpService: HttpService) {
    super(httpService, "/products");
  }

  findByBarCode(barcode: string): Observable<IProduct> {
    return this.httpService.get('/products/findByBarcode/?barcode=' + barcode, false);
  }
}
