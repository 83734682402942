import {ItemTableCatalogComponent} from "../item-table-catalog.component";
import {IProduct} from "../../../models/inventory/product/product.interface";
import {Component, EventEmitter, inject} from "@angular/core";
import {ProductSearchRequest} from "../../../models/inventory/product/product-search.request";
import {GenericServiceService} from "../../../services/shared/generic-service.service";
import {ProductService} from "../../../services/inventory/product/product.service";
import {IProductCategory} from "../../../models/inventory/product-category/product-category.interface";
import { NotificationHandler } from "../../../util/handlers/notification.handler";

@Component({
  selector: 'app-product-table-catalog',
  templateUrl: 'product-table-catalog.component.html',
  styleUrl: 'product-table-catalog.component.scss'
})
export class ProductTableCatalogComponent extends ItemTableCatalogComponent<IProduct, ProductSearchRequest> {
  private notificationHandler = inject(NotificationHandler);
  barcode: string | undefined;
  productCategory: IProductCategory | undefined;

  productService = inject(ProductService);
  createFormVisibilityEventEmitter = new EventEmitter;

  protected onProductCategoryChanged() {
    this.searchRequest.productCategoryId = this.productCategory?.id;

    this.tableSearchManager.lockLongDelayAndRefreshItems();
  }

  protected onBarcodeSearch(event: KeyboardEvent) {
    // ENTER
    if (event.key == 'Enter') {
      if (this.barcode) {
        this.processing = true;

        this.productService.findByBarCode(this.barcode).subscribe({
          next: product => {

            this.onSelectItem(product);
            this.processing = false;
            this.barcode = "";
          },
          error: (error) => {
            console.error(error);

            this.messageService.add({
              key: this.toastId,
              severity: 'error',
              summary: 'Aucun élément trouvé',
              detail: 'Aucun produit ne correspond au code barre ' + this.barcode
            });

            this.processing = false;
            this.barcode = "";
          }
        })
      } else {
        this.messageService.add({
          key: this.toastId,
          severity: 'error',
          summary: 'Aucun élément trouvé',
          detail: 'Le champs de code à barre est vide',
        })
      }
    }
  }

  protected buildSearchRequest(pageSize: number, offsetIndicators: any[] | undefined): ProductSearchRequest {
    return new ProductSearchRequest(undefined, this.productCategory?.id, undefined, undefined, 0, pageSize, offsetIndicators);
  }

  protected getService(): GenericServiceService<IProduct, ProductSearchRequest, any, any> {
    return this.productService;
  }

  protected onCreateSuccessful(response: IProduct) {
    this.notificationHandler.notifyOperationSuccess(this.toastId);
    this.tableSearchManager.lockLongDelayAndRefreshItems();
  }

  protected create() {
    this.createFormVisibilityEventEmitter.emit(undefined);
  }
}

