import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {LoginComponent} from "./login/login.component";
import {HomeComponent} from './home/home.component';
import {ComponentsModule} from "../shared/components/components.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {PasswordModule} from "primeng/password";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {DividerModule} from "primeng/divider";
import {ToggleButtonModule} from "primeng/togglebutton";
import {PanelModule} from "primeng/panel";
import {CardModule} from "primeng/card";
import {ToolbarModule} from "primeng/toolbar";
import {ContactsModule} from "./contact/contacts.module";
import {InventoryModule} from "./inventory/inventory.module";
import {PaymentsModule} from "./payments/payments.module";
import {OrdersModule} from "./orders/orders.module";
import { QuickAccessPanelModule } from "./quickacces-panel/quick-access-panel.module";
import { SpeedDialModule } from 'primeng/speeddial';

@NgModule({
  declarations: [
    LoginComponent,
    HomeComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    InputGroupAddonModule,
    InputGroupModule,
    PasswordModule,
    ProgressSpinnerModule,
    DividerModule,
    ToggleButtonModule,
    PanelModule,
    CardModule,
    ToolbarModule,
    ContactsModule,
    InventoryModule,
    OrdersModule,
    SpeedDialModule,
    PaymentsModule,
    QuickAccessPanelModule,
  ],
  exports: []
})
export class FeaturesModule {
}
