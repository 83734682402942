import {TableSaleOrderBuilderDashboardComponent} from "../table-sale-order-builder-dashboard.component";
import {Component} from "@angular/core";
import {PaymentRequestStrategy} from "../../../shared/order-builder/order-builder.component";
import {AllowedReportType, AllowedReportTypes} from "../../../shared/order-builder/reporting/allowed-report.type";

@Component({
  templateUrl: '../table-sale-order-builder-dashboard.component.html',
  styleUrl: "../table-sale-order-builder-dashboard.component.scss"
})
export class InvoiceableTableSaleOrderBuilderDashboard extends TableSaleOrderBuilderDashboardComponent {

  paymentRequestStrategy = PaymentRequestStrategy.FORM_PAYMENT;
  allowedReportTypes: AllowedReportType[] = [
    AllowedReportTypes.ORDER_ENFORCE_NO_VAT,
    AllowedReportTypes.DELIVERY_ORDER_ENFORCE_NO_VAT,
    AllowedReportTypes.INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE,
    AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_FALSE
  ]
}
