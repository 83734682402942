import {Component, inject, Input} from "@angular/core";
import {EntityFormComponent} from "../../../../core/ui/crud-form/entity-form.component";
import {IContact} from "../../../../core/models/contact/contact.interface";
import {ValidationReport, ValidationViolation} from "../../../../core/ui/validation/validation-report";
import {ContactService} from "../../../../core/services/contact/contact.service";
import {ContactFormData} from "./contact-form-data.";

@Component({
  template: ''
})
export abstract class ContactFormComponent extends EntityFormComponent<IContact, ContactFormData> {

  public contactService = inject(ContactService);

  @Input()
  enableInitialBalance!: boolean;

  @Input()
  forceCustomer = false;

  @Input()
  forceProvider = false;

  @Input()
  draggable = true;

  protected onCreateFormData(entity: IContact | undefined): ContactFormData {
    return new ContactFormData();
  }

  protected onValidate(formData: ContactFormData): ValidationReport {
    const violations: ValidationViolation[] = [];

    if (formData.name == undefined) {
      const nameViolation = new ValidationViolation();
      nameViolation.fieldName = "name";
      nameViolation.errorDetails = "Le nom est obligatoire";

      violations.push(nameViolation);
    }

    return new ValidationReport(violations);
  }
}
