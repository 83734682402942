import {NgModule} from "@angular/core";
import {OrderItemsTableComponent} from "./order-items-table.component";
import {ToastModule} from "primeng/toast";
import {TableModule} from "primeng/table";
import {DecimalPipe, NgIf} from "@angular/common";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {FormsModule} from "@angular/forms";
import {CoreModule} from "../../../core.module";
import {InputNumberModule} from "primeng/inputnumber";
import {FieldsetModule} from "primeng/fieldset";
import {AvatarModule} from "primeng/avatar";
import {PanelModule} from "primeng/panel";
import {CardModule} from "primeng/card";
import {ToolbarModule} from "primeng/toolbar";
import {OrderItemsContainerComponent} from "../order-items-container.component";

@NgModule(
  {
    declarations: [
      OrderItemsContainerComponent,
      OrderItemsTableComponent,
    ],
    providers: [],
    imports: [
      ToastModule,
      TableModule,
      DecimalPipe,
      ButtonModule,
      InputTextModule,
      FormsModule,
      CoreModule,
      InputNumberModule,
      FieldsetModule,
      AvatarModule,
      PanelModule,
      CardModule,
      ToolbarModule,
      NgIf
    ],
    exports: [
      OrderItemsTableComponent
    ]
  })
export class OrderItemsTableModule {

}
