import {Component} from "@angular/core";
import {EnumDropdownComponent} from "../enum-dropdown.component";
import {PaymentMethod, PaymentMethodUtils} from "../../../models/payment/payment-method";

@Component({
  selector: 'app-payment-method-dropdown',
  templateUrl: '../enum-dropdown.component.html',
  styleUrl: '../enum-dropdown.component.scss'
})
export class PaymentMethodDropdownComponent extends EnumDropdownComponent<PaymentMethod> {

  getIcon(): string {
    return "fa fa-hand-holding-dollar";
  }

  getPlaceholder(): string {
    return "Methode de paiment";
  }

  findAll(): PaymentMethod[] {
    return Object.values(PaymentMethod);
  }

  asString(entity: PaymentMethod): string {
    return PaymentMethodUtils.asString(entity);
  }

  asEntity(item: string): PaymentMethod | undefined {
    return PaymentMethodUtils.fromString(item);
  }
}
