import {NgModule} from "@angular/core";
import {
  ProductTableCatalogModule
} from "../../../../core/ui/item-table-catalog/product-table-catalog/product-table-catalog.module";
import {SplitterModule} from "primeng/splitter";
import {
  TableSaleOrderBuilderComponent
} from "./table-order-builder/table-sale-order-builder/table-sale-order-builder.component";
import {
  OrderItemsTableModule
} from "../../../../core/ui/order-items-container/order-items-table/order-items-table.module";
import {ButtonModule} from "primeng/button";
import {DecimalPipe, NgForOf, NgIf} from "@angular/common";
import {ToolbarModule} from "primeng/toolbar";
import {
  ContactAutoCompleteModule
} from "../../../../core/ui/item-auto-complete/contact-auto-complete/contact-auto-complete.module";
import {CalendarModule} from "primeng/calendar";
import {FloatLabelModule} from "primeng/floatlabel";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {FormsModule} from "@angular/forms";
import {ToastModule} from "primeng/toast";
import {DividerModule} from "primeng/divider";
import {RadioButtonModule} from "primeng/radiobutton";
import {
  TablePurchaseOrderBuilderComponent
} from "./table-order-builder/table-purchase-order-builder/table-purchase-order-builder.component";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {BlockUIModule} from "primeng/blockui";
import {PaymentsModule} from "../../../payments/payments.module";
import {NumericPadModule} from "../../../../core/ui/numeric-pad/numeric-pad.module";
import {DialogModule} from "primeng/dialog";
import {AvatarModule} from "primeng/avatar";
import {
  TableReportingOrderBuilderComponent
} from "./table-order-builder/table-reporting-order-builder/table-reporting-order-builder.component";
import {CheckboxModule} from "primeng/checkbox";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    TableSaleOrderBuilderComponent,
    TablePurchaseOrderBuilderComponent,
    TableReportingOrderBuilderComponent
  ],
  imports: [
    ProductTableCatalogModule,
    SplitterModule,
    OrderItemsTableModule,
    ButtonModule,
    DecimalPipe,
    ToolbarModule,
    ContactAutoCompleteModule,
    CalendarModule,
    FloatLabelModule,
    InputGroupAddonModule,
    InputGroupModule,
    FormsModule,
    ToastModule,
    DividerModule,
    NgForOf,
    RadioButtonModule,
    ProgressSpinnerModule,
    BlockUIModule,
    PaymentsModule,
    NumericPadModule,
    DialogModule,
    AvatarModule,
    NgIf,
    CheckboxModule,
    DropdownModule
  ],
  providers: [],
  exports: [
    TableSaleOrderBuilderComponent,
    TablePurchaseOrderBuilderComponent,
    TableReportingOrderBuilderComponent
  ]
})
export class OrderBuilderModule {

}
