import {Injectable} from '@angular/core';
import {PaymentRepository} from '../../repositories/payment/payment.repository';
import {IPayment} from '../../models/payment/payment.interface';
import {GenericServiceService} from "../shared/generic-service.service";
import {PaymentSearchRequest} from "../../models/payment/payment-search.request";
import {PaymentCreationRequest} from "../../models/payment/payment-creation.request";
import {OrderType} from "../../models/payment/order-type";

@Injectable()
export class PaymentService extends GenericServiceService<IPayment, PaymentSearchRequest, PaymentCreationRequest, undefined> {

  paymentRepository: PaymentRepository;

  constructor(paymentRepository: PaymentRepository) {
    super(paymentRepository);

    this.paymentRepository = paymentRepository;
  }

  findByOrderId(orderType: OrderType, orderId: string) {
    return this.paymentRepository.findByOrderId(orderType, orderId);
  }
}
