import {Component, inject} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';

import {AuthenticationService} from '../../core/services/authentication/authentication.service';
import {MessageService} from 'primeng/api';
import {LoginRequest} from "../../core/models/login/login.request";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})

export class LoginComponent {
  public isLogging = false;
  public loginCredentialsForm = new FormGroup({
    username: new FormControl(localStorage.getItem('Username')),
    password: new FormControl(localStorage.getItem('Password')),
    remember: new FormControl(true)
  });

  private authenticationService = inject(AuthenticationService);
  private messageService = inject(MessageService);

  login() {
    const rememberMe = <boolean>this.loginCredentialsForm.value.remember;
    const loginRequest = new LoginRequest(
      <string>this.loginCredentialsForm.value.username,
      <string>this.loginCredentialsForm.value.password
    );

    this.isLogging = true;
    this.authenticationService.login(loginRequest, rememberMe).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Authentification',
          detail: 'Authentification faite avec succès'
        });

        this.isLogging = false;
      },

      error: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Erreur d\'authentification',
          detail: 'Nom d\'utilisateur ou mot de passe invalide, veuillez réessayer'
        });

        this.isLogging = false;
      }
    });
  }
}
