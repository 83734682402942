import {OrderRepository} from "./order.repository";
import {HttpService} from "../../services/http/http.service";
import {Injectable} from "@angular/core";
import {ISaleOrder} from "../../models/transaction/sale-order/sale-order.interface";
import {SaleOrderSearchRequest} from "../../models/transaction/sale-order/sale-order-search.request";
import {SaleOrderCreationRequest} from "../../models/transaction/sale-order/sale-order-creation.request";
import {SaleOrderUpdateRequest} from "../../models/transaction/sale-order/sale-order-update.request";

@Injectable()
export class SaleOrderRepository extends OrderRepository<ISaleOrder, SaleOrderSearchRequest, SaleOrderCreationRequest, SaleOrderUpdateRequest> {

  constructor(httpService: HttpService) {
    super(httpService, "/saleOrders");
  }
}
