<p-dialog header="Header" [(visible)]="this.visible" [modal]="true">
  <p-toast [key]="this.toastId"></p-toast>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-sitemap mr-2"></i>
      <span class="font-bold white-space-nowrap" [textContent]="headerLabel"></span>
    </div>
  </ng-template>

  <div class="flex">
    <form>
      <div class="flex flex-column ">
        <div class="flex flex-row flex-wrap gap-4">
          <div class="w-full xl:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-box"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="name" name="name" [(ngModel)]="formData.name" required="true"/>
                <label for="name">Nom</label>
              </p-floatLabel>
            </p-inputGroup>

            <div class="mt-4">
              <app-product-category-auto-complete id="productCategory"
                                                  class="flex-grow-1"
                                                  appendTo="body"
                                                  [parentToastId]="this.toastId"
                                                  [(entity)]="this.formData.productCategory"/>
            </div>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-hashtag"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="reference" name="reference" [(ngModel)]="formData.reference"/>
                <label for="barcode">Référence</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-barcode"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="barcode" name="barcode" [(ngModel)]="formData.barcode"/>
                <label for="barcode">Code barre principal</label>
              </p-floatLabel>
            </p-inputGroup>

            <div class="flex flex-row mt-4">
              <p-inputGroup class="w-fit">
                <p-inputGroupAddon>
                  <i class="fa fa-barcode"></i>
                </p-inputGroupAddon>
              </p-inputGroup>
              <app-value-chips id="secondaryBarcodes" class="flex-grow-1"
                               placeholder="Codes barres supplémentaires"
                               [values]="formData.secondaryBarcodes"
              ></app-value-chips>
            </div>
          </div>
          <div class="w-full xl:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-coins"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="purchasePrice" name="purchasePrice" inputId="purchasePrice"
                               mode="decimal"
                               [(ngModel)]="formData.purchasePrice" [minFractionDigits]="2" [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="purchasePrice">Prix d'achat</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-money-bill"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="sellingPrice" name="sellingPrice" inputId="sellingPrice"
                               mode="decimal"
                               [(ngModel)]="formData.sellingPrice" [minFractionDigits]="2" [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="sellingPrice">Prix de vente</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-money-bill-transfer"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="resellerPrice" name="resellerPrice" inputId="resellerPrice"
                               mode="decimal"
                               [(ngModel)]="formData.resellerPrice" [minFractionDigits]="2" [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="resellerPrice">Prix de revendeur</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
          <div class="w-full xl:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-dolly"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="availableQuantity" name="availableQuantity"
                               inputId="availableQuantity"
                               mode="decimal"
                               [(ngModel)]="formData.availableQuantity" [minFractionDigits]="2" [maxFractionDigits]="2"
                               [disabled]="this.disableAvailableQuantity">
                </p-inputNumber>
                <label for="availableQuantity">Quantité disponible</label>
              </p-floatLabel>
            </p-inputGroup>
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-people-carry-box"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="resellerPriceSwitchingQuantity" name="resellerPriceSwitchingQuantity"
                               inputId="resellerPriceSwitchingQuantity"
                               mode="decimal"
                               [(ngModel)]="formData.resellerPriceSwitchingQuantity" [minFractionDigits]="2"
                               [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="resellerPriceSwitchingQuantity">Quantité de revendeur</label>
              </p-floatLabel>
            </p-inputGroup>
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-triangle-exclamation"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-inputNumber appNumberInput id="minimalAllowedQuantity" name="minimalAllowedQuantity"
                               inputId="minimalAllowedQuantity"
                               mode="decimal"
                               [(ngModel)]="formData.minimalAllowedQuantity" [minFractionDigits]="2"
                               [maxFractionDigits]="2">
                </p-inputNumber>
                <label for="minimalAllowedQuantity">Quantité minimal autorisée</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2">
      <div class="flex-grow-1">
        <p-button icon="pi pi-times" (click)="this.cancel()" label="Annuler" severity="danger"
                  styleClass="w-full"></p-button>
      </div>
      <div class="flex-grow-1">
        <p-button icon="pi pi-check" (click)="this.validate()" label="Confirmer" severity="success"
                  pAutoFocus [autofocus]="true" [loading]="this.confirming" iconPos="right"
                  styleClass="w-full"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
