import {IOrderItem} from "./order-item.interface";

export class OrderItemRequest {

  quantity!: number;
  unitPrice!: number;
  productId: string | undefined;
  totalDiscount: number = 0;
  description: string | undefined;

  public static fromOrderItem(orderItem: IOrderItem) {
    const request = new OrderItemRequest();
    request.quantity = orderItem.quantity;
    request.unitPrice = orderItem.unitPrice;
    request.productId = orderItem.productId;
    request.totalDiscount = orderItem.totalDiscount;
    request.description = orderItem.description;

    return request;
  }
}
