<div class="layout-wrapper">
  <app-topbar *ngIf="this.authService.isUserLoggedIn()"></app-topbar>
  <app-sidebar *ngIf="this.authService.isUserLoggedIn()"></app-sidebar>

  <div class="layout-main">
    <div class="layout-content">
      <router-outlet/>
    </div>
  </div>
</div>
