import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http/http.service';
import {IPayment} from '../../models/payment/payment.interface';
import {GenericRepository} from "../shared/generic.repository";
import {PaymentSearchRequest} from "../../models/payment/payment-search.request";
import {PaymentCreationRequest} from "../../models/payment/payment-creation.request";
import {OrderType} from "../../models/payment/order-type";
import {map} from "rxjs";

@Injectable()
export class PaymentRepository extends GenericRepository<IPayment, PaymentSearchRequest, PaymentCreationRequest, undefined> {
  constructor(httpService: HttpService) {
    super(httpService, "/payments");
  }

  public findByOrderId(orderType: OrderType, orderId: string) {
    return this.httpService.get('/payments/findByOrder?orderId=' + orderId + '&orderType=' + orderType, false)
      .pipe(map<IPayment, IPayment>(entity => {
        this.onEdit(entity);

        return entity;
      }));
  }

  override onEdit(entity: IPayment | undefined): IPayment | undefined {
    const payment = super.onEdit(entity);

    if (payment) {
      payment.name = payment.serialNumber;
    }

    return payment;
  }
}
