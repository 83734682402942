import {OrderCreationRequest} from "./order-creation.request";
import {PaymentMethod} from "../../../payment/payment-method";

export class PayableOrderCreationRequest extends OrderCreationRequest {

  payment: {
    amount: number;
    paymentMethod: PaymentMethod,
    description: string | undefined
  } | undefined;
}
