import {Component} from "@angular/core";
import {OrderItemsContainerComponent} from "../order-items-container.component";

@Component({
  selector: 'app-order-items-table',
  templateUrl: 'order-items-table.component.html',
  styleUrl: 'order-items-table.component.scss'
})
export class OrderItemsTableComponent extends OrderItemsContainerComponent {

}
