import {Injectable} from "@angular/core";
import {IPurchaseOrder} from "../../models/transaction/purchase-order/purchase-order.interface";
import {PurchaseOrderSearchRequest} from "../../models/transaction/purchase-order/purchase-order-search.request";
import {PurchaseOrderCreationRequest} from "../../models/transaction/purchase-order/purchase-order-creation.request";
import {PurchaseOrderUpdateRequest} from "../../models/transaction/purchase-order/purchase-order-update.request";
import {OrderService} from "./shared/order.service";
import {PurchaseOrderRepository} from "../../repositories/order/purchase-order.repository";

@Injectable()
export class PurchaseOrderService extends OrderService<IPurchaseOrder, PurchaseOrderSearchRequest, PurchaseOrderCreationRequest, PurchaseOrderUpdateRequest> {

  constructor(orderRepository: PurchaseOrderRepository) {
    super(orderRepository);
  }
}
