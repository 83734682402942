import {Component} from '@angular/core';
import {OrdersDashboardComponent} from "../../shared/orders-dashboard/orders-dashboard.component";


@Component({
  selector: 'app-sale-orders-dashboard',
  templateUrl: './sale-orders-dashboard.component.html',
  styleUrls: ['./sale-orders-dashboard.component.scss']
})
export class SaleOrdersDashboardComponent extends OrdersDashboardComponent {
}
