import {Component, inject} from "@angular/core";
import {EntityFormComponent} from "../../../../../core/ui/crud-form/entity-form.component";
import {ValidationReport, ValidationViolation} from "../../../../../core/ui/validation/validation-report";
import {ProductCategoryFormData} from "./product-category-form-data.";
import {IProductCategory} from "../../../../../core/models/inventory/product-category/product-category.interface";
import {ProductCategoryService} from "../../../../../core/services/inventory/product-category/product-category.service";

@Component({
  template: ''
})
export abstract class ProductCategoryFormComponent extends EntityFormComponent<IProductCategory, ProductCategoryFormData> {

  public productCategoryService = inject(ProductCategoryService);

  protected override onCreateFormData(entity: IProductCategory | undefined): ProductCategoryFormData {
    return new ProductCategoryFormData();
  }

  protected onValidate(formData: ProductCategoryFormData): ValidationReport {
    const violations: ValidationViolation[] = [];

    if (formData.name == undefined) {
      const nameViolation = new ValidationViolation();
      nameViolation.fieldName = "name";
      nameViolation.errorDetails = "Le nom est obligatoire";

      violations.push(nameViolation);
    }

    return new ValidationReport(violations);
  }
}
