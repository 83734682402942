import {NgModule} from "@angular/core";
import {ContactAutoCompleteComponent} from "./contact-auto-complete.component";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {CommonModule, NgComponentOutlet, NgTemplateOutlet} from "@angular/common";

@NgModule({
  declarations: [
    ContactAutoCompleteComponent
  ],
  providers: [],
  imports: [
    CommonModule,
    InputGroupModule,
    InputGroupAddonModule,
    AutoCompleteModule,
    ButtonModule,
    FormsModule,
    NgTemplateOutlet,
    NgComponentOutlet,
  ],
  exports: [
    ContactAutoCompleteComponent
  ]
})
export class ContactAutoCompleteModule {

}
