import {Component, inject} from "@angular/core";
import {TableOrderBuilderComponent} from "../table-order-builder.component";
import {PaymentType} from "../../../../../../core/models/payment/payment-type";
import {IOrder} from "../../../../../../core/models/transaction/shared/order/order.interface";
import {OrderFormData} from "../../order-builder.component";
import {
  SaleOrderCreationRequest
} from "../../../../../../core/models/transaction/sale-order/sale-order-creation.request";
import {SaleOrderUpdateRequest} from "../../../../../../core/models/transaction/sale-order/sale-order-update.request";
import {SaleOrderService} from "../../../../../../core/services/transaction/sale-order.service";
import {GenericServiceService} from "../../../../../../core/services/shared/generic-service.service";
import {OrderItemRequest} from "../../../../../../core/models/transaction/shared/order-item/order-item.request";
import {ReportType} from "../../../../../../core/models/reporting/report-type";
import {SourceType} from "../../../../../../core/models/reporting/source-type";
import {VatPolicy} from "../../reporting/vat.policy";

@Component({
  selector: "app-table-sale-order-builder",
  templateUrl: "../table-order-builder.component.html",
  styleUrl: "../table-order-builder.component.scss"
})
export class TableSaleOrderBuilderComponent extends TableOrderBuilderComponent<SaleOrderCreationRequest, SaleOrderUpdateRequest> {

  private orderService = inject(SaleOrderService);

  isCustomerOrder(): boolean {
    return true;
  }

  isProviderOrder(): boolean {
    return false;
  }

  protected getPaymentType(): PaymentType {
    return PaymentType.INGOING;
  }

  protected buildCreateRequest(order: IOrder | undefined, orderFormData: OrderFormData): SaleOrderCreationRequest {
    const orderRequest = new SaleOrderCreationRequest();
    orderRequest.contactId = orderFormData.contact.id;
    orderRequest.occurredAt = orderFormData.occurredAt;
    orderRequest.description = orderFormData.description;
    orderRequest.taxable = orderFormData.taxable;
    orderRequest.invoiced = ReportType.INVOICE == orderFormData.reportType.reportType;
    orderRequest.orderItems = orderFormData.orderItems.map(orderItem => OrderItemRequest.fromOrderItem(orderItem));

    if (orderFormData.paymentCreationRequest) {
      orderRequest.payment = {
        amount: orderFormData.paymentCreationRequest.amount,
        paymentMethod: orderFormData.paymentCreationRequest.paymentMethod,
        description: orderFormData.paymentCreationRequest.description
      }
    }

    return orderRequest;
  }

  protected buildUpdateRequest(order: IOrder, orderFormData: OrderFormData): SaleOrderUpdateRequest {
    const orderRequest = new SaleOrderUpdateRequest();
    orderRequest.contactId = orderFormData.contact.id;
    orderRequest.occurredAt = orderFormData.occurredAt;
    orderRequest.description = orderFormData.description;
    orderRequest.orderItems = orderFormData.orderItems.map(orderItem => OrderItemRequest.fromOrderItem(orderItem));

    return orderRequest;
  }

  protected getService(): GenericServiceService<IOrder, any, SaleOrderCreationRequest, SaleOrderUpdateRequest> {
    return this.orderService;
  }

  protected getSourceType(): SourceType {
    return SourceType.SALE_ORDER;
  }
}
