import {PaymentRequest} from "./payment.request";

export class PaymentCreationRequest extends PaymentRequest {

  order: TargetOrderRequest | undefined;
}

export class TargetOrderRequest {

}
