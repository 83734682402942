import {Component, inject} from "@angular/core";
import {WarrantyFormData} from "./warranty-form-data.";
import {EntityFormComponent} from "../../../core/ui/crud-form/entity-form.component";
import {ValidationReport, ValidationViolation} from "../../../core/ui/validation/validation-report";
import {Observable} from "rxjs";
import {PrintingHandler} from "../../../core/util/handlers/printing.handler";
import {ReportService} from "../../../core/services/report/report.service";
import {AuthenticationService} from "../../../core/services/authentication/authentication.service";
import {IWarranty} from "../../../core/models/warranty/warranty.interface";

@Component({
  selector: 'app-warranty-form',
  templateUrl: 'warranty-form.component.html',
  styleUrl: 'warranty-form.component.scss',
})
export class WarrantyFormComponent extends EntityFormComponent<IWarranty, WarrantyFormData> {

  public authenticationService = inject(AuthenticationService);
  public reportService = inject(ReportService);
  public printingHandler = inject(PrintingHandler);

  protected onConfirm(entity: IWarranty | undefined, formData: WarrantyFormData): Observable<IWarranty> {
    return new Observable<IWarranty>(subscriber => {
      const warranty = formData.toWarrantyPrintingRequest();

      this.reportService.warranty(warranty).subscribe({
        next: warrantyBlob => {
          this.printingHandler.open(warrantyBlob);
          subscriber.next(entity);
        },

        error: (error) => {
          subscriber.error(error);
        }
      });
    });
  }


  protected onCreateFormData(entity: IWarranty | undefined): WarrantyFormData {
    return new WarrantyFormData();
  }

  protected override onResetFormData(entity: IWarranty | undefined, currentFormData: WarrantyFormData): Observable<WarrantyFormData> {
    return new Observable<WarrantyFormData>(subscriber => {
      const user = this.authenticationService.getUserDetails()!;

      currentFormData.period = "1 an";
      currentFormData.date = new Date();
      currentFormData.sourceContactName = user.attributes.account.companyDetails.name || "";
      currentFormData.sourceContactAddress = user.attributes.account.companyDetails.address || "";
      currentFormData.targetContactName = entity?.contact?.name || "";
      currentFormData.targetContactAddress = entity?.contact?.address || "";

      subscriber.next(currentFormData);
    });
  }

  protected onValidate(formData: WarrantyFormData): ValidationReport {
    const violations: ValidationViolation[] = [];

    if (formData.date == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "date";
      violation.errorDetails = "La date est obligatoire";

      violations.push(violation);
    }

    if (formData.period == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "period";
      violation.errorDetails = "La période est obligatoire";

      violations.push(violation);
    }

    return new ValidationReport(violations);
  }
}
