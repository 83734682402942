import {ReportType} from "../../../../../core/models/reporting/report-type";

import {VatPolicy} from "./vat.policy";

export interface AllowedReportType {
  reportType: ReportType | undefined,
  label: string,
  vatPolicy: VatPolicy,
}

export class AllowedReportTypes {

  // Concrete orders
  public static NO_REPORT_NO_ENFORCEMENT_DEFAULT_TRUE: AllowedReportType = {
    reportType: undefined,
    label: 'Ne rien imprimer',
    vatPolicy: VatPolicy.NO_ENFORCEMENT_DEFAULT_TRUE
  }

  public static NO_REPORT_NO_ENFORCEMENT_DEFAULT_FALSE: AllowedReportType = {
    reportType: undefined,
    label: 'Ne rien imprimer',
    vatPolicy: VatPolicy.NO_ENFORCEMENT_DEFAULT_FALSE
  }

  public static NO_REPORT_ENFORCE_VAT: AllowedReportType = {
    reportType: undefined,
    label: 'Ne rien imprimer',
    vatPolicy: VatPolicy.ENFORCE_VAT
  }

  public static NO_REPORT_ENFORCE_NO_VAT: AllowedReportType = {
    reportType: undefined,
    label: 'Ne rien imprimer',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT
  }

  public static NO_REPORT_ENFORCE_NO_VAT_HIDDEN: AllowedReportType = {
    reportType: undefined,
    label: 'Ne rien imprimer',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT_HIDDEN
  }

  public static ORDER_ENFORCE_NO_VAT: AllowedReportType = {
    reportType: ReportType.ORDER,
    label: 'Bon pour',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT
  }

  public static INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE: AllowedReportType = {
    reportType: ReportType.INVOICE,
    label: 'Facture',
    vatPolicy: VatPolicy.NO_ENFORCEMENT_DEFAULT_TRUE
  }

  public static DELIVERY_ORDER_ENFORCE_NO_VAT: AllowedReportType = {
    reportType: ReportType.DELIVERY_ORDER,
    label: 'Bon de livraison',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT
  }

  public static ORDER_ENFORCE_NO_VAT_HIDDEN: AllowedReportType = {
    reportType: ReportType.ORDER,
    label: 'Bon pour',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT_HIDDEN
  }

  // Reporting orders
  public static REPORTING_INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE: AllowedReportType = {
    reportType: ReportType.REPORTING_ORDER,
    label: 'Imprimer la facture',
    vatPolicy: VatPolicy.NO_ENFORCEMENT_DEFAULT_TRUE
  }

  public static REPORTING_PROFORMA_INVOICE_ENFORCE_VAT: AllowedReportType = {
    reportType: ReportType.REPORTING_ORDER,
    label: 'Imprimer la facture proforma',
    vatPolicy: VatPolicy.ENFORCE_VAT
  }

  public static REPORTING_ESTIMATE_ENFORCE_NO_VAT: AllowedReportType = {
    reportType: ReportType.REPORTING_ORDER,
    label: 'Imprimer le devis',
    vatPolicy: VatPolicy.ENFORCE_NO_VAT
  }
}
