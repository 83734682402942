<p-dialog header="Header" [(visible)]="this.visible" [modal]="true" [draggable]="draggable">
  <p-toast [key]="this.toastId"></p-toast>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-people-arrows"></i>
      <span class="font-bold white-space-nowrap" [textContent]="headerLabel"></span>
    </div>
  </ng-template>

  <div class="flex">
    <form>
      <div class="flex flex-column ">
        <div class="flex flex-row gap-2">
          <div class="w-full md:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-user"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="name" name="name" [(ngModel)]="formData.name" required="true"/>
                <label for="name">Nom</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-map"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="address" name="address" [(ngModel)]="formData.address"/>
                <label for="address">Adresse</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-phone"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="phoneNumber" name="phoneNumber" [(ngModel)]="formData.phoneNumber"/>
                <label for="phoneNumber">N° de téléphone</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-envelope"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="email" name="email" [(ngModel)]="formData.email"/>
                <label for="email">E-mail</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
          <div class="w-full md:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-coins"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="initialBalance" name="initialBalance"
                       [(ngModel)]="formData.transactionalDetails.initialBalance"
                       [disabled]="!this.enableInitialBalance"/>
                <label for="initialBalance">Solde initial</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-tablet"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="companyRegistrationNumber" name="companyRegistrationNumber"
                       [(ngModel)]="formData.transactionalDetails.companyRegistrationNumber"/>
                <label for="companyRegistrationNumber">N° RC</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-file-invoice"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="fiscalRegistrationNumber" name="fiscalRegistrationNumber"
                       [(ngModel)]="formData.transactionalDetails.fiscalRegistrationNumber"/>
                <label for="fiscalRegistrationNumber">NIF</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa fa-square-poll-vertical"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="statisticsNumber" name="statisticsNumber"
                       [(ngModel)]="formData.transactionalDetails.statisticsNumber"/>
                <label for="statisticsNumber">NIS</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
        </div>
        <div class="flex flex-row justify-content-center align-items-center mt-4 gap-5">
          <div class="flex flex-row gap-1">
            <p-checkbox name="isCustomer" [binary]="true"
                        [(ngModel)]="formData.transactionalDetails.isCustomer"
                        inputId="isCustomer" [disabled]="this.forceCustomer"></p-checkbox>
            <p-tag severity="success" value="Client" styleClass="m-1"></p-tag>
          </div>

          <div class="flex flex-row gap-1">
            <p-checkbox name="isProvider" [binary]="true"
                        [(ngModel)]="formData.transactionalDetails.isProvider"
                        inputId="isProvider" [disabled]="this.forceProvider"></p-checkbox>
            <p-tag severity="danger" value="Fournisseur" styleClass="m-1"></p-tag>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2">
      <div class="flex-grow-1">
        <p-button icon="pi pi-times" (click)="this.cancel()" label="Annuler" severity="danger"
                  styleClass="w-full"></p-button>
      </div>
      <div class="flex-grow-1">
        <p-button icon="pi pi-check" (click)="this.validate()" label="Confirmer" severity="success"
                  pAutoFocus [autofocus]="true" [loading]="this.confirming" iconPos="right"
                  styleClass="w-full"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
