import {Component, inject, Input} from "@angular/core";
import {TableOrderBuilderComponent} from "../table-order-builder.component";
import {PaymentType} from "../../../../../../core/models/payment/payment-type";
import {OrderItemRequest} from "../../../../../../core/models/transaction/shared/order-item/order-item.request";
import {IOrder} from "../../../../../../core/models/transaction/shared/order/order.interface";
import {OrderFormData} from "../../order-builder.component";
import {GenericServiceService} from "../../../../../../core/services/shared/generic-service.service";
import {SourceType} from "../../../../../../core/models/reporting/source-type";
import {
  ReportingOrderCreationRequest
} from "../../../../../../core/models/transaction/reporting-order/reporting-order-creation.request";
import {ReportingOrderType} from "../../../../../../core/models/transaction/reporting-order/reporting-order-type";
import {
  ReportingOrderUpdateRequest
} from "../../../../../../core/models/transaction/reporting-order/reporting-order-update.request";
import {ReportingOrderService} from "../../../../../../core/services/transaction/reporting-order.service";

@Component({
  selector: "app-table-reporting-order-builder",
  templateUrl: "../table-order-builder.component.html",
  styleUrl: "../table-order-builder.component.scss"
})
export class TableReportingOrderBuilderComponent extends TableOrderBuilderComponent<ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {

  @Input()
  reportingOrderType!: ReportingOrderType;

  orderService = inject(ReportingOrderService);

  isCustomerOrder(): boolean {
    return true;
  }

  isProviderOrder(): boolean {
    return false;
  }

  protected getPaymentType(): PaymentType {
    return PaymentType.OUTGOING;
  }

  protected buildCreateRequest(order: IOrder | undefined, orderFormData: OrderFormData): ReportingOrderCreationRequest {
    const orderRequest = new ReportingOrderCreationRequest();
    orderRequest.contactId = orderFormData.contact.id;
    orderRequest.occurredAt = orderFormData.occurredAt;
    orderRequest.description = orderFormData.description;
    orderRequest.orderItems = orderFormData.orderItems.map(orderItem => OrderItemRequest.fromOrderItem(orderItem));
    orderRequest.reportingOrderType = this.reportingOrderType;

    return orderRequest;
  }

  protected buildUpdateRequest(order: IOrder, orderFormData: OrderFormData): ReportingOrderUpdateRequest {
    const orderRequest = new ReportingOrderUpdateRequest();
    orderRequest.contactId = orderFormData.contact.id;
    orderRequest.occurredAt = orderFormData.occurredAt;
    orderRequest.description = orderFormData.description;
    orderRequest.orderItems = orderFormData.orderItems.map(orderItem => OrderItemRequest.fromOrderItem(orderItem));

    return orderRequest;
  }

  protected getService(): GenericServiceService<IOrder, any, ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {
    return this.orderService;
  }

  protected getSourceType(): SourceType {
    return SourceType.REPORTING_ORDER;
  }
}
