import {ProductRequest} from "../../../../../core/models/inventory/product/product.request";
import {ProductCreateRequest} from "../../../../../core/models/inventory/product/product-create.request";
import {ProductUpdateRequest} from "../../../../../core/models/inventory/product/product-update.request";
import {IProduct} from "../../../../../core/models/inventory/product/product.interface";
import {IProductCategory} from "../../../../../core/models/inventory/product-category/product-category.interface";

export class ProductFormData extends ProductRequest {

  availableQuantity: number = 0;
  productCategory!: IProductCategory | undefined;

  public toProductCreationRequest(): ProductCreateRequest {
    const request = new ProductCreateRequest();
    request.name = this.name;
    request.barcode = this.barcode;
    request.reference = this.reference;
    request.productCategoryId = this.productCategory?.id;
    request.purchasePrice = this.purchasePrice;
    request.sellingPrice = this.sellingPrice;
    request.resellerPrice = this.resellerPrice;
    request.availableQuantity = this.availableQuantity;
    request.minimalAllowedQuantity = this.minimalAllowedQuantity;
    request.resellerPriceSwitchingQuantity = this.resellerPriceSwitchingQuantity;
    request.secondaryBarcodes = this.secondaryBarcodes;

    return request;
  }

  public toProductUpdateRequest(): ProductUpdateRequest {
    const request = new ProductUpdateRequest();
    request.name = this.name;
    request.barcode = this.barcode;
    request.reference = this.reference;
    request.productCategoryId = this.productCategory?.id;
    request.purchasePrice =  this.purchasePrice;
    request.sellingPrice = this.sellingPrice;
    request.resellerPrice = this.resellerPrice;
    request.minimalAllowedQuantity = this.minimalAllowedQuantity;
    request.resellerPriceSwitchingQuantity = this.resellerPriceSwitchingQuantity;
    request.secondaryBarcodes = this.secondaryBarcodes;

    return request;
  }

  public static fromProduct(product: IProduct | undefined, productCategory: IProductCategory | undefined): ProductFormData {
    const formData = new ProductFormData();

    if (product) {
      formData.name = product.name;
      formData.name = product.name;
      formData.barcode = product.barcode;
      formData.reference = product.reference;
      formData.productCategory = productCategory;
      formData.productCategoryId = productCategory?.id;
      formData.purchasePrice = product.purchasePrice;
      formData.sellingPrice = product.sellingPrice;
      formData.resellerPrice = product.resellerPrice;
      formData.availableQuantity = product.availableQuantity;
      formData.minimalAllowedQuantity = product.minimalAllowedQuantity;
      formData.resellerPriceSwitchingQuantity = product.resellerPriceSwitchingQuantity;
      formData.secondaryBarcodes = product.secondaryBarcodes;
    }

    return formData;
  }
}
