import {Component} from "@angular/core";
import {IContact} from "../../../core/models/contact/contact.interface";
import {ContactFormComponent} from "../shared/contact-form/contact-form.component";
import {Observable} from "rxjs";
import {ContactFormData} from "../shared/contact-form/contact-form-data.";

@Component({
  selector: 'app-contact-creation-form',
  templateUrl: '../shared/contact-form/contact-form.component.html',
  styleUrl: '../shared/contact-form/contact-form.component.scss',
})
export class ContactCreationFormComponent extends ContactFormComponent {

  protected override onResetFormData(entity: IContact | undefined, previousFormData: ContactFormData): Observable<ContactFormData> {
    const formData = new ContactFormData();
    formData.transactionalDetails.isCustomer = this.forceCustomer;
    formData.transactionalDetails.isProvider = this.forceProvider;

    return new Observable(subscriber => subscriber.next(formData));
  }

  protected onConfirm(contact: IContact | undefined, request: ContactFormData): Observable<IContact> {
    return this.contactService.create(request.toContactCreationRequest());
  }
}
