import {GenericRepository} from "../shared/generic.repository";
import {IOrder} from "../../models/transaction/shared/order/order.interface";
import {OrderSearchRequest} from "../../models/transaction/shared/order/order-search.request";
import {OrderCreationRequest} from "../../models/transaction/shared/order/order-creation.request";
import {OrderUpdateRequest} from "../../models/transaction/shared/order/order-update.request";

export class OrderRepository<O extends IOrder, SR extends OrderSearchRequest, CR extends OrderCreationRequest, UR extends OrderUpdateRequest> extends GenericRepository<O, SR, CR, UR> {

  override onEdit(entity: O | undefined): O | undefined {
    const order = super.onEdit(entity);

    if (order) {
      order.name = order.serialNumber;
    }

    return order;
  }
}
