import {ReportStyle} from "./report-style";
import {IdentifiableEntity} from "../shared/identifiable-entity";
import {NameableEntity} from "../shared/nameable.entity";
import {SequenceGenerator} from "../../util/generators/sequence.generator";

export class WarrantyPrintingRequest implements IdentifiableEntity, NameableEntity {

  id = SequenceGenerator.generate();
  name = "Garantie";

  reportStyle: ReportStyle = new ReportStyle();
  nature = "";
  type = "";
  note = "";
  sequence = "";
  amount = "";
  orderNumber = "";
  orderDate = "";
  period = "";
  sourceContactName = "";
  sourceContactAddress = "";
  targetContactName = "";
  targetContactAddress = "";
}
