import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../shared/components/components.module";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {ConfirmationService, MessageService} from "primeng/api";
import {CustomerPaymentsDashboardComponent} from "./customer-payments-dashboard/customer-payments-dashboard.component";
import {PaymentsTableComponent} from "./shared/payments-table/payments-table.component";
import {ProviderPaymentsDashboardComponent} from "./providers-payments-dashboard/provider-payments-dashboard.component";
import {
  ContactAutoCompleteModule
} from "../../core/ui/item-auto-complete/contact-auto-complete/contact-auto-complete.module";
import {PaymentCreationFormComponent} from "./payment-creation-form/payment-creation-form.component";
import {CoreModule} from "../../core/core.module";
import {InputNumberModule} from "primeng/inputnumber";
import {
  PaymentMethodDropdownModule
} from "../../core/ui/enum-dropdown/payment-method-dropdown/payment-method-dropdown.module";
import {InputTextareaModule} from "primeng/inputtextarea";

@NgModule({
    declarations: [
        PaymentsTableComponent,
        PaymentCreationFormComponent,
        CustomerPaymentsDashboardComponent,
        ProviderPaymentsDashboardComponent
    ],
    imports: [
        CommonModule,
        ComponentsModule,
        ToolbarModule,
        FileUploadModule,
        FormsModule,
        RippleModule,
        ChipModule,
        UiModule,
        StyleClassModule,
        InputGroupModule,
        InputGroupAddonModule,
        PasswordModule,
        DividerModule,
        FloatLabelModule,
        ContactAutoCompleteModule,
        CoreModule,
        InputNumberModule,
        PaymentMethodDropdownModule,
        InputTextareaModule
    ],
    exports: [
        PaymentCreationFormComponent
    ],
    providers: [
        MessageService,
        ConfirmationService
    ]
})
export class PaymentsModule {

}
