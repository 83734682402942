import {Injectable} from "@angular/core";
import {OrderService} from "./shared/order.service";
import {ISaleOrder} from "../../models/transaction/sale-order/sale-order.interface";
import {SaleOrderSearchRequest} from "../../models/transaction/sale-order/sale-order-search.request";
import {SaleOrderCreationRequest} from "../../models/transaction/sale-order/sale-order-creation.request";
import {SaleOrderUpdateRequest} from "../../models/transaction/sale-order/sale-order-update.request";
import {SaleOrderRepository} from "../../repositories/order/sale-order.repository";

@Injectable()
export class SaleOrderService extends OrderService<ISaleOrder, SaleOrderSearchRequest, SaleOrderCreationRequest, SaleOrderUpdateRequest> {

  constructor(orderRepository: SaleOrderRepository) {
    super(orderRepository);
  }
}
