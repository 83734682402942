<div class="flex flex-column">
  <p-toast [key]="this.toastId"></p-toast>
  <p-table
    dataKey="id"
    selectionMode="single"
    [(selection)]="this.tableSelectedItem"
    [value]="this.items"

    [rowHover]="true"
    [resizableColumns]="true"
    responsiveLayout="stack"
    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

    [scrollable]="true"

    [showFirstLastIcon]="true"
    [showPageLinks]="true"

    currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="caption">
      <div class="flex flex-row">
        <p-toolbar class="w-full">
          <div class="p-toolbar-group-start">
            <p-button icon="pi pi-plus" severity="success" (onClick)="this.onAddEmptyOrderItem()"
                      size="small"></p-button>
          </div>
          <div class="p-toolbar-group-center">
            <div class="flex flex-row align-content-center justify-content-center gap-2">
              <i class="fa-solid fa-sack-dollar pt-1"></i>
              <span class="font-bold text-center text-xl">{{ this.totalAmount | number:'1.2-2' }} DZD</span>
            </div>
          </div>
          <div class="p-toolbar-group-end">
          </div>
        </p-toolbar>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-box mr-2"></i>Produit</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-dolly mr-2"></i>Quantité</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-coins mr-2"></i>Prix unitaire</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-hand-holding-dollar mr-2"></i>Remise total
        </th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-sack-dollar> mr-2"></i>Prix total</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-comment mr-2"></i>Description</th>
        <th style="width: 5rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-orderItem let-editing="editing">
      <tr>
        <td class="text-left">
          <span class="p-column-title">Produit:</span>
          <span *ngIf="orderItem.product">{{ orderItem.product.name }}</span>
          <p *ngIf="!orderItem.product" class="text-center m-0">/</p>
        </td>
        <td class="text-center" [pEditableColumn]="orderItem.quantity" pEditableColumnField="quantity">
          <span class="p-column-title">Quantité:</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputNumber appNumberInput id="quantity" name="quantity"
                             inputId="quantity"
                             mode="decimal"
                             (onInput)="this.onOrderItemEdited(orderItem)"
                             [(ngModel)]="orderItem.quantity" [minFractionDigits]="2" [maxFractionDigits]="2">
              </p-inputNumber>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="font-semibold">{{ orderItem.quantity | number:'1.3-3' }}</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="text-center" [pEditableColumn]="orderItem.unitPrice" pEditableColumnField="unitPrice">
          <span class="p-column-title">Prix unitaire:</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputNumber appNumberInput id="unitPrice" name="unitPrice"
                             inputId="unitPrice"
                             mode="decimal"
                             (onInput)="this.onOrderItemEdited(orderItem)"
                             [(ngModel)]="orderItem.unitPrice" [minFractionDigits]="2" [maxFractionDigits]="2">
              </p-inputNumber>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="font-semibold">{{ orderItem.unitPrice | number:'1.2-2' }} DZD</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="text-center" [pEditableColumn]="orderItem.totalDiscount" pEditableColumnField="totalDiscount">
          <span class="p-column-title">Remise total:</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-inputNumber appNumberInput id="totalDiscount" name="totalDiscount"
                             inputId="totalDiscount"
                             mode="decimal"
                             (onInput)="this.onOrderItemEdited(orderItem)"
                             [(ngModel)]="orderItem.totalDiscount" [minFractionDigits]="2" [maxFractionDigits]="2">
              </p-inputNumber>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="font-semibold">{{ orderItem.totalDiscount | number:'1.2-2' }} DZD</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="text-center">
          <span class="p-column-title">Prix total:</span>
          <span class="font-semibold">{{ orderItem.totalPrice | number: '1.2-2' }} DZD</span>
        </td>
        <td class="text-center" [pEditableColumn]="orderItem.description" pEditableColumnField="description">
          <span class="p-column-title">Déscription:</span>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text"
                     (input)="this.onOrderItemEdited(orderItem)"
                     [(ngModel)]="orderItem.description"/>
            </ng-template>
            <ng-template pTemplate="output">
              <span class="font-semibold">{{ orderItem.description }}</span>
            </ng-template>
          </p-cellEditor>
        </td>
        <td pFrozenColumn alignFrozen="right">
          <div class="flex flex-row gap-1">
            <p-button icon="pi pi-plus" [style]="{width: '2rem', height: '2rem'}" severity="success"
                      (click)="this.onIncreaseQuantity(orderItem)"></p-button>
            <p-button icon="pi pi-minus" [style]="{width: '2rem', height: '2rem'}" severity="danger"
                      (click)="this.onDecreaseQuantity(orderItem)"></p-button>
            <button class="printBarCode" (click)="this.printBarCode(orderItem)"><i class="fas fa-barcode"></i></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7" class="text-center">Aucun produit inclu.</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="flex align-items-center justify-content-between">
        <span class="p-input-icon-left w-full">
          <i class="pi pi-question-circle"></i>
          <input type="text" class="w-full" pInputText [(ngModel)]="this.description" placeholder="Déscription"/>
        </span>
      </div>
    </ng-template>
  </p-table>
</div>
