import {Component} from "@angular/core";
import {ProductCategoryFormComponent} from "../product-category-form/product-category-form.component";
import {IProductCategory} from "../../../../../core/models/inventory/product-category/product-category.interface";
import {ProductCategoryFormData} from "../product-category-form/product-category-form-data.";
import {Observable} from "rxjs";

@Component({
  selector: 'app-product-category-creation-form',
  templateUrl: '../product-category-form/product-category-form.component.html',
  styleUrl: '../product-category-form/product-category-form.component.scss',
})
export class ProductCategoryCreationFormComponent extends ProductCategoryFormComponent {

  protected onConfirm(entity: IProductCategory | undefined, formData: ProductCategoryFormData): Observable<IProductCategory> {
    return this.productCategoryService.create(formData.toProductCategoryCreationRequest());
  }
}
