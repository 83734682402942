import {PaymentType} from "./payment-type";
import {PaymentMethod} from "./payment-method";

export class PaymentRequest {

  occurredAt!: Date;
  contactId!: string;
  amount!: number;
  paymentMethod!: PaymentMethod;
  paymentType!: PaymentType;
  description: string | undefined;
}
