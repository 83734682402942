import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SplitButtonModule} from "primeng/splitbutton";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule} from "@angular/forms";
import {FloatLabelModule} from "primeng/floatlabel";
import {ChipsModule} from "primeng/chips";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {ProductTableCatalogComponent} from "./product-table-catalog.component";
import {TableModule} from "primeng/table";
import {TagModule} from "primeng/tag";
import {
  ProductCategoryAutoCompleteModule
} from "../../item-auto-complete/product-category-auto-complete/product-category-auto-complete.module";
import {ToastModule} from "primeng/toast";
import { ProductCreationFormComponent } from "../../../../features/inventory/product/product-creation-form/product-creation-form.component";
import { ProductsModule } from "../../../../features/inventory/product/products.module";
import { ConfirmDialogModule } from "primeng/confirmdialog";

@NgModule(
{
  declarations: [
    ProductTableCatalogComponent,
  ],
  providers: [],
  imports: [
    CommonModule,
    SplitButtonModule,
    AutoCompleteModule,
    FormsModule,
    FloatLabelModule,
    ChipsModule,
    InputGroupAddonModule,
    InputGroupModule,
    TableModule,
    TagModule,
    ProductCategoryAutoCompleteModule,
    ToastModule,
    ConfirmDialogModule,
    ProductsModule,
  ],
  exports: [
    ProductTableCatalogComponent
  ]
})
export class ProductTableCatalogModule{

}
