import {ContactRequest} from "../../../../core/models/contact/contact.request";
import {ContactTransactionDetailsRequest} from "../../../../core/models/contact/contact-transaction-details.request";
import {
  ContactCreationRequest,
  ContactCreationTransactionDetailsRequest
} from "../../../../core/models/contact/contact-creation.request";
import {
  ContactUpdateRequest,
  ContactUpdateTransactionDetailsRequest
} from "../../../../core/models/contact/contact-update.request";
import {IContact} from "../../../../core/models/contact/contact.interface";

export class ContactFormData extends ContactRequest {
  transactionalDetails = new ContactTransactionalDetailsFormData();

  public toContactCreationRequest(): ContactCreationRequest {
    const request = new ContactCreationRequest();
    request.name = this.name;
    request.address = this.address;
    request.email = this.email;
    request.phoneNumber = this.phoneNumber;
    request.transactionalDetails = this.transactionalDetails?.toContactCreationTransactionalDetailsRequest();

    return request;
  }

  public toContactUpdateRequest(): ContactUpdateRequest {
    const request = new ContactUpdateRequest();
    request.name = this.name;
    request.address = this.address;
    request.email = this.email;
    request.phoneNumber = this.phoneNumber;
    request.transactionalDetails = this.transactionalDetails?.toContactUpdateTransactionalDetailsRequest();

    return request;
  }

  public static fromContact(contact: IContact | undefined): ContactFormData {
    const formData = new ContactFormData();

    if (contact) {
      formData.name = contact.name;
      formData.address = contact.address;
      formData.email = contact.email;
      formData.phoneNumber = contact.phoneNumber;
      formData.transactionalDetails = ContactTransactionalDetailsFormData.fromContactTransactionalDetails(contact);
    }

    return formData;
  }
}

export class ContactTransactionalDetailsFormData extends ContactTransactionDetailsRequest {
  initialBalance: number | undefined = 0;

  public toContactCreationTransactionalDetailsRequest(): ContactCreationTransactionDetailsRequest {
    const request = new ContactCreationTransactionDetailsRequest();
    request.isProvider = this.isProvider;
    request.isCustomer = this.isCustomer;
    request.initialBalance = this.initialBalance;
    request.statisticsNumber = this.statisticsNumber;
    request.fiscalRegistrationNumber = this.fiscalRegistrationNumber;
    request.companyRegistrationNumber = this.companyRegistrationNumber;

    return request;
  }

  public toContactUpdateTransactionalDetailsRequest(): ContactUpdateTransactionDetailsRequest {
    const request = new ContactUpdateTransactionDetailsRequest();
    request.isProvider = this.isProvider;
    request.isCustomer = this.isCustomer;
    request.statisticsNumber = this.statisticsNumber;
    request.fiscalRegistrationNumber = this.fiscalRegistrationNumber;
    request.companyRegistrationNumber = this.companyRegistrationNumber;

    return request;
  }

  public static fromContactTransactionalDetails(contact: IContact): ContactTransactionalDetailsFormData {
    const formData = new ContactTransactionalDetailsFormData();

    if (contact) {
      formData.initialBalance = contact.transactionalDetails?.initialBalance;
      formData.isProvider = contact.transactionalDetails?.isProvider;
      formData.isCustomer = contact.transactionalDetails?.isCustomer;
      formData.statisticsNumber = contact.transactionalDetails?.statisticsNumber;
      formData.fiscalRegistrationNumber = contact.transactionalDetails?.fiscalRegistrationNumber;
      formData.companyRegistrationNumber = contact.transactionalDetails?.companyRegistrationNumber;
    }

    return formData;
  }
}
