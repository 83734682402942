import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './features/home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LoginComponent } from './features/login/login.component';
import { LoginGuard } from './core/guards/login.guard';
import { CustomersDashboardComponent } from "./features/contact/customers-dashboard/customers-dashboard.component";
import { ProvidersDashboardComponent } from "./features/contact/providers-dashboard/providers-dashboard.component";
import { ProductCategoriesDashboardComponent } from "./features/inventory/product-category/product-categories-dashboard/product-categories-dashboard.component";
import { ProductsDashboardComponent } from "./features/inventory/product/products-dashboard/products-dashboard.component";
import { CustomerPaymentsDashboardComponent } from "./features/payments/customer-payments-dashboard/customer-payments-dashboard.component";
import { ProviderPaymentsDashboardComponent } from "./features/payments/providers-payments-dashboard/provider-payments-dashboard.component";
import { PurchaseOrdersDashboardComponent } from "./features/orders/purchase-orders/purchase-orders-dashboard/purchase-orders-dashboard.component";
import { SaleOrdersDashboardComponent } from "./features/orders/sale-orders/sale-orders-dashboard/sale-orders-dashboard.component";
import { EstimateOrdersDashboardComponent } from "./features/orders/reporting-orders/estimate-orders-dashboard/estimate-orders-dashboard.component";
import { ProformaInvoiceOrdersDashboardComponent } from "./features/orders/reporting-orders/proforma-invoice-orders-dashboard/proforma-invoice-orders-dashboard.component";
import { InvoiceOrdersDashboardComponent } from "./features/orders/reporting-orders/invoice-orders-dashboard/invoice-orders-dashboard.component";
import { InvoiceableTableSaleOrderBuilderDashboard } from "./features/orders/sale-orders/table-sale-order-builder-dashboard/invoiceable-table-sale-order-builder-dashboard/invoiceable-table-sale-order-builder-dashboard";
import { CounterTableSaleOrderBuilderDashboard } from "./features/orders/sale-orders/table-sale-order-builder-dashboard/counter-table-sale-order-builder-dashboard/counter-table-sale-order-builder-dashboard";
import { TablePurchaseOrderBuilderDashboardComponent } from "./features/orders/purchase-orders/table-purchase-order-builder-dashboard/table-purchase-order-builder-dashboard.component";
import { QuickAccessComponent } from './features/quickacces-panel/quick-access/quick-access.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    data: {
      breadcrumb: 'Accueil'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: {
      breadcrumb: 'Authentification'
    }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Dashboard'
    }
  },
  {
    path: 'contacts',
    children: [
      {
        path: 'customers',
        component: CustomersDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Liste des clients',
          icon: 'fa-solid fa-person-walking-arrow-right'
        }
      },
      {
        path: 'providers',
        component: ProvidersDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Liste des fournisseurs',
          icon: 'fa-solid fa-person-walking-arrow-loop-left'
        }
      }
    ],
    data: {
      breadcrumb: 'Contacts'
    }
  },
  {
    path: 'inventory',
    children: [
      {
        path: 'productCategories',
        component: ProductCategoriesDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Catégories de produits',
          icon: 'fa-solid fa-sitemap'
        }
      },
      {
        path: 'products',
        component: ProductsDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Liste des produits',
          icon: 'fa-solid fa-boxes-stacked'
        }
      }
    ],
    data: {
      breadcrumb: 'Inventaire',
    }
  },
  {
    path: 'saleorders',
    children: [
      {
        path: 'saleOrders',
        component: SaleOrdersDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Registre des ventes',
          icon: 'fa-solid fa-file-import'
        }
      },
      {
        path: 'counterTableSaleOrderDashboard',
        component: CounterTableSaleOrderBuilderDashboard,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Nouvelle vente comptoir',
          icon: 'fa-solid fa-plus'
        }
      },
      {
        path: 'invoiceableTableSaleOrderDashboard',
        component: InvoiceableTableSaleOrderBuilderDashboard,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Nouvelle vente facturée',
          icon: 'fa-solid fa-plus'
        }
      },
    ],
    data: {
      breadcrumb: 'Ventes'
    },
  },
  {
    path: 'purchaseorders',
    children: [
      {
        path: 'tablePurchaseOrderDashboard',
        component: TablePurchaseOrderBuilderDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Nouvel achat',
          icon: 'fa-solid fa-plus'
        }
      },
      {
        path: 'purchaseOrders',
        component: PurchaseOrdersDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Registre des achats',
          icon: 'fa-solid fa-file-import'
        }
      },
    ],
    data: {
      breadcrumb: 'Achats'
    }
  },
  {
        path: 'reportingorders',
        children: [
          {
            path: 'estimates',
            component: EstimateOrdersDashboardComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'Devis',
              icon: 'fa-solid fa-file-invoice-dollar'
            }
          },
          {
            path: 'proformaInvoices',
            component: ProformaInvoiceOrdersDashboardComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'Factures proforma',
              icon: 'fa-solid fa-file-invoice-dollar'
            }
          },
          {
            path: 'invoices',
            component: InvoiceOrdersDashboardComponent,
            canActivate: [AuthGuard],
            data: {
              breadcrumb: 'Factures',
              icon: 'fa-solid fa-file-invoice'
            }
          }
        ],
        data: {
          breadcrumb: 'Facturation'
        }
      },
  {
    path: 'payments',
    children: [
      {
        path: 'customerPayments',
        component: CustomerPaymentsDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Paiements des clients',
          icon: 'fa-solid fa-hand-holding-dollar'
        }
      },
      {
        path: 'providerPayments',
        component: ProviderPaymentsDashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Paiements des fournisseurs',
          icon: 'fa-solid fa-hand-holding-dollar'
        }
      }
    ],
    data: {
      breadcrumb: 'Paiements'
    }
  },
  {
    path: 'navigateTo',
    component: QuickAccessComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Accès rapide'
    }
  },
  {
    path: '**',
    redirectTo: '/home',
    pathMatch: 'full'
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
