import {ItemSplitButtonComponent} from "../item-split-button.component";
import {Component} from "@angular/core";
import {IPayment} from "../../../models/payment/payment.interface";

@Component({
  selector: 'app-payment-split-button',
  templateUrl: '../item-split-button.component.html',
  styleUrl: '../item-split-button.component.scss'
})
export class PaymentSplitButtonComponent extends ItemSplitButtonComponent<IPayment> {

  override ngOnInit() {
    this.items.push({
      icon: 'pi pi-file',
      label: 'Reçu de paiment',
      styleClass: this.itemsStyleClass ? this.itemsStyleClass : '',
      command: (e) => {
        this.printWithDefaultDialog('Reçu de paiment', 'Voulez vous vraiment imprimer le reçu du paiment ?', undefined, (id: string, input: undefined) => this.reportService.paymentReceipt(id));
      }
    });

    super.ngOnInit();
  }
}
