import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {ContactsTableComponent} from "./shared/contacts-table/contacts-table.component";
import {ComponentsModule} from "../../shared/components/components.module";
import {CustomersDashboardComponent} from "./customers-dashboard/customers-dashboard.component";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ConfirmationService, MessageService} from "primeng/api";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {ContactCreationFormComponent} from "./contact-creation-form/contact-creation-form.component";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {ContactEditionFormComponent} from "./contact-edition-form/contact-edition-form.component";
import {ProvidersDashboardComponent} from "./providers-dashboard/providers-dashboard.component";
import {WarrantyFormModule} from "../shared/warranty-form/warranty-form.module";

@NgModule({
  declarations: [
    ContactCreationFormComponent,
    ContactEditionFormComponent,
    ContactsTableComponent,

    CustomersDashboardComponent,
    ProvidersDashboardComponent
  ],
    imports: [
        CommonModule,
        ComponentsModule,
        ToolbarModule,
        FileUploadModule,
        FormsModule,
        RippleModule,
        ChipModule,
        UiModule,
        StyleClassModule,
        InputGroupModule,
        InputGroupAddonModule,
        PasswordModule,
        DividerModule,
        FloatLabelModule,
        WarrantyFormModule
    ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class ContactsModule {
}
