import {SequenceGenerator} from "../../util/generators/sequence.generator";
import {IOrderItem} from "../../models/transaction/shared/order-item/order-item.interface";
import {IProduct} from "../../models/inventory/product/product.interface";
import {Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges} from "@angular/core";
import { BarcodeHandler } from "../../util/handlers/barcode.handler";

@Component({
  template: ''
})
export class OrderItemsContainerComponent implements OnChanges {
  protected barcodeHandler = inject(BarcodeHandler);

  protected toastId = SequenceGenerator.generate();
  protected tableSelectedItem: IOrderItem | undefined;

  protected items: IOrderItem[] = [];
  protected description: string = "";

  protected totalAmount = 0;

  @Output()
  public totalAmountChange = new EventEmitter<number>();

  @Input()
  public taxPercent = 0.0;

  @Input()
  public includeTax = true;

  ngOnChanges(changes: SimpleChanges): void {
    const totalAmountInputs = ['taxPercent', 'includeTax'];
    const totalAmountChange = totalAmountInputs.filter(input => changes[input]).length > 0;

    if (totalAmountChange) {
      this.onRecomputeTotalAmount();
    }
  }

  public getTotalAmount(): number {
    return this.totalAmount;
  }

  public getDescription(): string {
    return this.description;
  }

  public setDescription(description: string): void {
    this.description = description;
  }

  public getItems() {
    return this.items;
  }

  public reset() {
    this.clearItems();
  }

  public setItems(items: IOrderItem[]): void {
    this.clearItems();

    items.forEach(item => this.items.push(item));

    this.onRecomputeTotalAmount();
  }

  public addItem(product: IProduct) {
    const existing = this.items.filter(item => product.id == item.product?.id);

    if (existing.length > 0) {
      const orderItem = existing[0];
      orderItem.quantity = orderItem.quantity + 1;

      this.onRecomputeOrderItem(orderItem);
    } else {
      const unitPrice = product.sellingPrice;
      const item: IOrderItem = {
        id: SequenceGenerator.generate(),
        quantity: 1,
        product: product,
        productId: product.id,
        unitPrice: unitPrice,
        totalPrice: unitPrice,
        totalDiscount: 0,
        description: '',
      };

      this.items.push(item);
      this.onRecomputeOrderItem(item);
    }
  }

  protected onAddEmptyOrderItem() {
    this.items.push({
      id: SequenceGenerator.generate(),
      quantity: 1,
      product: undefined,
      productId: undefined,
      unitPrice: 0,
      totalPrice: 0,
      totalDiscount: 0,
      description: '',
    })
  }

  protected printBarCode(item: any) {
    console.log(item);
    this.barcodeHandler.printBarcode(
      {
        name: item.product.name,
        barcode: item.product.barcode,
        sellingPrice: item.product.sellingPrice,
      },
      {
        height: 35,
        width: 45
      }
    );
  }

  protected onIncreaseQuantity(orderItem: IOrderItem) {
    orderItem.quantity = orderItem.quantity + 1;
    this.onRecomputeOrderItem(orderItem);
  }

  protected onDecreaseQuantity(orderItem: IOrderItem) {
    if (orderItem.quantity > 1) {
      orderItem.quantity = orderItem.quantity - 1;
    } else {
      this.deleteItem(orderItem, orderItem.id);
    }
    this.onRecomputeOrderItem(orderItem);
  }

  protected onOrderItemEdited(orderItem: IOrderItem) {
    this.onRecomputeOrderItem(orderItem);
  }

  // ---> Internal usage

  private clearItems() {
    this.items = [];
    this.totalAmount = 0;
    this.totalAmountChange.emit(0);
  }

  private deleteItem(orderItem: IOrderItem, id: String): void {
    const index = this.items.findIndex(() => orderItem.id === id);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  private onRecomputeOrderItem(item: IOrderItem) {
    item.totalPrice = item.quantity * item.unitPrice - item.totalDiscount;

    this.onRecomputeTotalAmount();
  }

  private onRecomputeTotalAmount() {
    const taxRate = this.includeTax ? this.taxPercent / 100.0 : 0.0;
    const totalAmount = this.items.map(e => e.totalPrice).reduce((old, current) => old + current, 0);
    const totalAmountTaxIncluded = (1 + taxRate) * totalAmount;

    this.totalAmount = totalAmountTaxIncluded;
    this.totalAmountChange.emit(totalAmountTaxIncluded);
  }
}
