import {Injectable} from '@angular/core';
import {ProductRepository} from '../../../repositories/inventory/product.repository';
import {GenericServiceService} from "../../shared/generic-service.service";
import {IProduct} from "../../../models/inventory/product/product.interface";
import {ProductSearchRequest} from "../../../models/inventory/product/product-search.request";
import {ProductUpdateRequest} from "../../../models/inventory/product/product-update.request";
import {ProductCreateRequest} from "../../../models/inventory/product/product-create.request";

@Injectable()
export class ProductService extends GenericServiceService<IProduct, ProductSearchRequest, ProductCreateRequest, ProductUpdateRequest> {

  protected productRepository: ProductRepository;

  constructor(productRepository: ProductRepository) {
    super(productRepository);

    this.productRepository = productRepository;
  }

  findByBarCode(barcode: string) {
    return this.productRepository.findByBarCode(barcode);
  }
}
