import {OrderBuilderComponent} from "../order-builder.component";
import {Component, HostListener, ViewChild} from "@angular/core";
import {
  OrderItemsTableComponent
} from "../../../../../core/ui/order-items-container/order-items-table/order-items-table.component";
import {
  OrderItemsContainerComponent
} from "../../../../../core/ui/order-items-container/order-items-container.component";
import {OrderCreationRequest} from "../../../../../core/models/transaction/shared/order/order-creation.request";
import {OrderUpdateRequest} from "../../../../../core/models/transaction/shared/order/order-update.request";

@Component({
  template: ''
})
export abstract class TableOrderBuilderComponent<CreateRequest extends OrderCreationRequest, UpdateRequest extends OrderUpdateRequest> extends OrderBuilderComponent<CreateRequest, UpdateRequest> {
  isSmallScreen = window.innerWidth < 768;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmallScreen = window.innerWidth < 768;
  }

  @ViewChild(OrderItemsTableComponent)
  private orderItemsTable!: OrderItemsTableComponent;

  getOrderItemsContainer(): OrderItemsContainerComponent {
    return this.orderItemsTable;
  }
}
