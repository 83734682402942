import {Component, inject, Input} from "@angular/core";
import {PaymentFormData} from "./payment-form-data.";
import {IPayment} from "../../../../core/models/payment/payment.interface";
import {EntityFormComponent} from "../../../../core/ui/crud-form/entity-form.component";
import {ValidationReport, ValidationViolation} from "../../../../core/ui/validation/validation-report";
import {PaymentService} from "../../../../core/services/payment/payment.service";
import {PaymentType} from "../../../../core/models/payment/payment-type";
import {Observable} from "rxjs";

@Component({
  template: ''
})
export abstract class PaymentFormComponent extends EntityFormComponent<IPayment, PaymentFormData> {

  protected readonly PaymentType = PaymentType;

  paymentService = inject(PaymentService);

  @Input()
  paymentType!: PaymentType;

  protected override onCreateFormData(entity: IPayment | undefined): PaymentFormData {
    const formData = new PaymentFormData();
    formData.paymentType = this.paymentType;

    return new PaymentFormData();
  }

  protected onValidate(formData: PaymentFormData): ValidationReport {
    const violations: ValidationViolation[] = [];

    // Mandatory information
    if (formData.occurredAt == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "occurredAt";
      violation.errorDetails = "La date est obligatoire";

      violations.push(violation);
    }

    if (formData.contact == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "contact";
      violation.errorDetails = "Le contact est obligatoire";

      violations.push(violation);
    }

    if (formData.amount == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "amount";
      violation.errorDetails = "Le montant est obligatoire";

      violations.push(violation);
    }

    if (formData.paymentMethod == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "paymentMethod";
      violation.errorDetails = "La méthode de paiment est obligatoire";

      violations.push(violation);
    }

    if (formData.paymentType == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "paymentType";
      violation.errorDetails = "Le type de paiment est obligatoire";

      violations.push(violation);
    }

    return new ValidationReport(violations);
  }

  protected override onResetFormData(entity: IPayment | undefined, currentFormData: PaymentFormData): Observable<PaymentFormData> {
    const formData = this.onCreateFormData(entity);

    if (entity) {
      formData.paymentType = entity.paymentType;
      formData.paymentMethod = entity.paymentMethod;
      formData.contact = entity.contact;
      formData.order = entity.order;
      formData.occurredAt = new Date(entity.occurredAt);
      formData.amount = entity.amount;
      formData.description = entity.description;
    } else {
      formData.paymentType = this.paymentType;
      formData.paymentMethod = currentFormData.paymentMethod;
      formData.occurredAt = new Date();
    }

    return new Observable<PaymentFormData>(subscriber => subscriber.next(formData));
  }
}
