import {Component} from '@angular/core';
import {PaymentsDashboardComponent} from "../shared/payments-dashboard/payments-dashboard.component";
import {PaymentType} from "../../../core/models/payment/payment-type";


@Component({
  selector: 'app-providers-payments-dashboard',
  templateUrl: './provider-payments-dashboard.component.html',
  styleUrls: ['./provider-payments-dashboard.component.scss']
})
export class ProviderPaymentsDashboardComponent extends PaymentsDashboardComponent{
  protected readonly PaymentType = PaymentType;
}
