import {Component, OnInit} from '@angular/core';
import {OrdersDashboardComponent} from "../../../shared/orders-dashboard/orders-dashboard.component";
import {ReportingOrderType} from "../../../../../core/models/transaction/reporting-order/reporting-order-type";


@Component({
  template: ''
})
export abstract class ReportingOrdersDashboardComponent extends OrdersDashboardComponent implements OnInit {

  addLabel!: string;
  editLabel!: string;
  reportingOrderType!: ReportingOrderType;

  ngOnInit(): void {
    this.addLabel = this.getAddLabel();
    this.editLabel = this.getEditLabel();
    this.reportingOrderType = this.getReportingOrderType();
  }

  protected abstract getAddLabel(): string;

  protected abstract getEditLabel(): string

  protected abstract getReportingOrderType(): ReportingOrderType;
}
