<p-dialog header="Header" [(visible)]="this.visible" [modal]="true">
  <p-toast [key]="this.toastId"></p-toast>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-people-arrows"></i>
      <span class="font-bold white-space-nowrap" [textContent]="headerLabel"></span>
    </div>
  </ng-template>

  <div class="flex">
    <form>
      <div class="flex flex-column ">
        <div class="flex flex-row flex-wrap gap-5">
          <div class="w-full md:w-fit">
            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-sitemap"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="nature" name="nature" [(ngModel)]="formData.nature" required="true"/>
                <label for="nature">Nature (الطبيعة)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-sitemap"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="type" name="type" [(ngModel)]="formData.type"/>
                <label for="type">Type (نمط)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-copyright"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="note" name="note" [(ngModel)]="formData.note"/>
                <label for="note">Notation (العلامة)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-hashtag"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="sequence" name="sequence" [(ngModel)]="formData.sequence"/>
                <label for="sequence">N° de séquence (رقم التسلسل او الحصة)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-money-bill"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="amount" name="amount" [(ngModel)]="formData.amount"/>
                <label for="amount">Montant facturé (السعر المفوتر)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-hashtag"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="orderNumber" name="orderNumber" [(ngModel)]="formData.orderNumber"/>
                <label for="orderNumber">N° de facture (رقم الفاتورة)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa-solid fa-calendar-check"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <p-calendar id="date"
                            dateFormat="dd/mm/yy"
                            inputId="date"
                            name="date"
                            placeholder="Date de la vente"
                            appendTo="body"
                            [showIcon]="true"
                            [showButtonBar]="true"
                            [(ngModel)]="this.formData.date">
                </p-calendar>
                <label for="date">Date de la vente (تاريخ البيع)</label>
              </p-floatLabel>
            </p-inputGroup>

            <p-inputGroup class="mt-5">
              <p-inputGroupAddon>
                <i class="fa fa-envelope"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="period" name="period" [(ngModel)]="formData.period"/>
                <label for="period">Période de garantie (مدة الضمان)</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
          <div class="w-full md:w-fit">
            <div class="flex flex-column">
              <h2>Magasin</h2>
              <p-inputGroup class="mt-2">
                <p-inputGroupAddon>
                  <i class="fa fa-user"></i>
                </p-inputGroupAddon>
                <p-floatLabel class="w-full">
                  <input pInputText id="sourceContactName" name="sourceContactName"
                         [(ngModel)]="this.formData.sourceContactName"/>
                  <label for="sourceContactName">Nom du vendeur</label>
                </p-floatLabel>
              </p-inputGroup>

              <p-inputGroup class="mt-5">
                <p-inputGroupAddon>
                  <i class="fa fa-map"></i>
                </p-inputGroupAddon>
                <p-floatLabel class="w-full">
                  <input pInputText id="sourceContactAddress" name="sourceContactAddress"
                         [(ngModel)]="this.formData.sourceContactAddress"/>
                  <label for="sourceContactAddress">Adresse du vendeur</label>
                </p-floatLabel>
              </p-inputGroup>
            </div>

            <div class="flex flex-column">
              <h2>Client</h2>
              <p-inputGroup class="mt-2">
                <p-inputGroupAddon>
                  <i class="fa fa-user"></i>
                </p-inputGroupAddon>
                <p-floatLabel class="w-full">
                  <input pInputText id="targetContactName" name="targetContactName"
                         [(ngModel)]="this.formData.targetContactName"/>
                  <label for="sourceContactAddress">Nom du client</label>
                </p-floatLabel>
              </p-inputGroup>

              <p-inputGroup class="mt-5">
                <p-inputGroupAddon>
                  <i class="fa fa-map"></i>
                </p-inputGroupAddon>
                <p-floatLabel class="w-full">
                  <input pInputText id="targetContactAddress" name="targetContactAddress"
                         [(ngModel)]="this.formData.targetContactAddress"/>
                  <label for="sourceContactAddress">Adresse du client</label>
                </p-floatLabel>
              </p-inputGroup>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2">
      <div class="flex-grow-1">
        <p-button icon="pi pi-times" (click)="this.cancel()" label="Annuler" severity="danger"
                  styleClass="w-full"></p-button>
      </div>
      <div class="flex-grow-1">
        <p-button icon="pi pi-check" (click)="this.validate()" label="Confirmer" severity="success"
                  pAutoFocus [autofocus]="true" [loading]="this.confirming" iconPos="right"
                  styleClass="w-full"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
