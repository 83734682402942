export class GenericSearchRequest {
  pageIndex: number = 0;
  pageSize: number = 10;
  offsetIndicators: any[] | undefined;

  constructor(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined = undefined) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    this.offsetIndicators = offsetIndicators;
  }
}
