import { Component, inject } from '@angular/core';
import { ComponentsModule } from './shared/components/components.module';
import { AuthenticationService } from './core/services/authentication/authentication.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  public authService = inject(AuthenticationService);
}
