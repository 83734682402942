import {Component, inject} from "@angular/core";
import {OrderBuilderModalDefinition, OrdersTableComponent} from "../../shared/orders-table/orders-table.component";
import {IPurchaseOrder} from "../../../../core/models/transaction/purchase-order/purchase-order.interface";
import {
  PurchaseOrderSearchRequest
} from "../../../../core/models/transaction/purchase-order/purchase-order-search.request";
import {
  PurchaseOrderCreationRequest
} from "../../../../core/models/transaction/purchase-order/purchase-order-creation.request";
import {
  PurchaseOrderUpdateRequest
} from "../../../../core/models/transaction/purchase-order/purchase-order-update.request";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";
import {PurchaseOrderService} from "../../../../core/services/transaction/purchase-order.service";
import {
  TablePurchaseOrderBuilderComponent
} from "../../shared/order-builder/table-order-builder/table-purchase-order-builder/table-purchase-order-builder.component";
import {PaymentRequestStrategy} from "../../shared/order-builder/order-builder.component";
import {AllowedReportTypes} from "../../shared/order-builder/reporting/allowed-report.type";

@Component({
  selector: 'app-purchase-orders-crud-table',
  templateUrl: './../../shared/orders-table/orders-table.component.html',
  styleUrls: ['./../../shared/orders-table/orders-table.component.scss'],
})
export class PurchaseOrdersTableComponent extends OrdersTableComponent<IPurchaseOrder, PurchaseOrderSearchRequest, PurchaseOrderCreationRequest, PurchaseOrderUpdateRequest> {

  purchaseOrderService = inject(PurchaseOrderService);

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): PurchaseOrderSearchRequest {
    const occurredAfter = this.occurrenceRange?.at(0)?.toISOString();
    const occurredBefore = this.occurrenceRange?.at(1)?.toISOString();
    const contactId = this.contact?.id;

    return new PurchaseOrderSearchRequest(occurredAfter, occurredBefore, undefined, undefined, contactId, pageIndex, pageSize, offsetIndicators);
  }

  protected getService(): GenericServiceService<IPurchaseOrder, PurchaseOrderSearchRequest, PurchaseOrderCreationRequest, PurchaseOrderUpdateRequest> {
    return this.purchaseOrderService;
  }

  protected isCustomerTransactions(): boolean {
    return false;
  }

  protected isProviderTransactions(): boolean {
    return true;
  }

  getCreationOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TablePurchaseOrderBuilderComponent> {
    return {
      component: TablePurchaseOrderBuilderComponent,
      inputs: [
        {
          name: "allowedReportTypes",
          value: [AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_TRUE]
        }
      ]
    };
  }

  protected getEditionOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TablePurchaseOrderBuilderComponent> {
    return {
      component: TablePurchaseOrderBuilderComponent,
      inputs: [
        {
          name: "allowedReportTypes",
          value: [AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_TRUE]
        }
      ]
    };
  }


  protected getOrderPaymentStrategy(): PaymentRequestStrategy {
    return PaymentRequestStrategy.FORM_PAYMENT;
  }

  protected isPayableOrder(): boolean {
    return true;
  }
}
