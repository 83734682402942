import {Component, EventEmitter, inject, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {MessageService} from "primeng/api";
import {GenericServiceService} from "../../services/shared/generic-service.service";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";
import {BusinessErrorHandler} from "../../util/handlers/business-error.handler";
import {OrderType} from "../../models/payment/order-type";
import {PaymentType} from "../../models/payment/payment-type";
import {SequenceGenerator} from "../../util/generators/sequence.generator";
import {TableSearchManager} from "../../util/managers/table-search.manager";
import {GenericSearchRequest} from "../../models/shared/generic-search.request";
import {NotificationHandler} from "../../util/handlers/notification.handler";

@Component({template: ''})
export abstract class CrudTableComponent<Entity extends IdentifiableEntity, SearchRequest extends GenericSearchRequest, CreateRequest, UpdateRequest> implements OnInit {

  protected readonly OrderType = OrderType;
  protected readonly PaymentType = PaymentType;
  protected tableSearchManager!: TableSearchManager<Entity, SearchRequest>;
  private notificationHandler = inject(NotificationHandler);

  @Input()
  reloadEventEmitter!: EventEmitter<any>;

  createFormVisibilityEventEmitter = new EventEmitter<Entity | undefined>;
  editFormVisibilityEventEmitter = new EventEmitter<Entity | undefined>;

  @Input()
  pageSize!: number;

  @Input()
  addButtonLabel!: string;

  @Input()
  addHeaderPrefix!: string;

  @Input()
  editHeaderPrefix!: string;

  protected toastId = SequenceGenerator.generate();
  protected searchRequest!: SearchRequest;
  protected processing = false;

  ngOnInit(): void {
    this.searchRequest = this.buildSearchRequest(0, this.pageSize, undefined);
    this.tableSearchManager = new TableSearchManager<Entity, SearchRequest>(this.pageSize, this.searchRequest, this.getService());
  }

  public create() {
    this.createFormVisibilityEventEmitter.emit(undefined);
  }

  public edit(entity: Entity) {
    this.editFormVisibilityEventEmitter.emit(entity);
  }

  public delete(entity: Entity) {
    this.performDeletion(entity).subscribe({
      next: () => {
        this.notificationHandler.notifyOperationSuccess(this.toastId);
        this.tableSearchManager.lockLongDelayAndRefreshItems();
      },

      error: (error) => {
        console.error(error);

        this.notificationHandler.notifyErrorOperationFailure(this.toastId, error);
      }
    })
  }

  protected abstract buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): SearchRequest;

  protected abstract getService(): GenericServiceService<Entity, SearchRequest, CreateRequest, UpdateRequest>;

  protected onCreateSuccessful(response: Entity) {
    this.notificationHandler.notifyOperationSuccess(this.toastId);
    this.tableSearchManager.lockLongDelayAndRefreshItems();
  }

  protected onEditSuccessful(response: Entity) {
    this.notificationHandler.notifyOperationSuccess(this.toastId);
    this.tableSearchManager.lockLongDelayAndRefreshItems();
  }

  protected performDeletion(entity: Entity): Observable<Entity> {
    return this.getService().delete(entity.id);
  }
}
