<p-toast [key]="this.toastId"></p-toast>
<p-confirmDialog></p-confirmDialog>

<app-product-creation-form [headerPrefix]="this.addHeaderPrefix"

                           [visibilityEventEmitter]="this.createFormVisibilityEventEmitter"

                           (onPersistSuccessHandler)="this.onCreateSuccessful($event)"/>

<app-product-edition-form [headerPrefix]="this.editHeaderPrefix"

                          [visibilityEventEmitter]="this.editFormVisibilityEventEmitter"

                          (onPersistSuccessHandler)="this.onEditSuccessful($event)"/>

<p-table
  dataKey="id"
  [value]="this.tableSearchManager.items"

  [paginator]="true"
  [rows]="this.pageSize"
  [totalRecords]="this.tableSearchManager.totalItemsCount"

  [lazy]="true"
  [loading]="this.tableSearchManager.searching || this.processing"
  (onLazyLoad)="tableSearchManager.search($event)"
  [(first)]="this.tableSearchManager.first"

  [rowHover]="true"
  [resizableColumns]="true"
  responsiveLayout="stack"
  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

  [scrollable]="true"

  [showFirstLastIcon]="false"
  [showPageLinks]="false"

  currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
      <button pButton pRipple [label]="this.addButtonLabel" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()"></button>
      <span class="flex-grow-1 p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class=" w-full" pInputText type="text" (input)="tableSearchManager.lockDelayAndRefreshItems()" [(ngModel)]="this.searchRequest.name"
               placeholder="Rechercher ..."/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn><i class="fa-solid fa-box mr-2"></i>Nom</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-barcode mr-2"></i>Code barre</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-hashtag mr-2"></i>Référence</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-sitemap mr-2"></i>Catégorie</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-coins mr-2"></i>Prix d'achat</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-money-bill mr-2"></i>Prix de vente</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-percent mr-2"></i>Profit</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-money-bill-transfer mr-2"></i>Prix de revendeur
      </th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-dolly mr-2"></i>Quantité disponible</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-people-carry-box mr-2"></i>Quantité de revendeur
      </th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-triangle-exclamation mr-2"></i>Quantité minimale
        autorisée
      </th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-barcode mr-2"></i>Autre code barres</th>
      <th style="width:30px" pFrozenColumn alignFrozen="right"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
    <tr>
      <td><span class="p-column-title">Nom:</span>{{ product.name }}</td>
      <td class="text-center"><span class="p-column-title">Code barre:</span>{{ product.barcode }}</td>
      <td class="text-center"><span class="p-column-title">Référence:</span>{{ product.reference }}</td>
      <td class="text-center"><span class="p-column-title">Catégorie:</span>
        <p-tag *ngIf="product.productCategory?.name">{{ product.productCategory?.name }}</p-tag>
      </td>
      <td class="text-center"><span class="p-column-title">Prix d'achat:</span>
        {{ product.purchasePrice | number:'1.2-2' }} DZD
      </td>
      <td class="text-center"><span class="p-column-title">Prix de vente:</span>
        {{ product.sellingPrice | number:'1.2-2' }} DZD
      </td>
      <td class="text-center font-semibold"><span class="p-column-title">Profit:</span>
        {{ product.profitMargin * 100 || 0 | number:'1.2-2' }} %
      </td>
      <td class="text-center"><span class="p-column-title">Prix de revendeur:</span>
        <span *ngIf="product.resellerPrice">{{ product.resellerPrice | number:'1.2-2' }} DZD</span>
      </td>
      <td class="text-center"><span class="p-column-title">Quantité disponible:</span>
        <p-tag [severity]="product.availableQuantity <= product.minimalAllowedQuantity ? 'danger': 'success'">
          {{ product.availableQuantity || 0 | number:'1.3-3' }}
        </p-tag>
      </td>
      <td class="text-center"><span class="p-column-title">Quantité de revendeur:</span>
        {{ product.resellerPriceSwitchingQuantity | number:'1.3-3' }}
      </td>
      <td class="text-center"><span class="p-column-title">Quantité minimal autorisée:</span>
        {{ product.minimalAllowedQuantity | number:'1.3-3' }}
      </td>
      <td class="text-center"><span class="p-column-title">Autres code barres:</span>
        <div class="flex justify-content-center align-items-center gap-2">
          <p-tag *ngFor="let barcode of product.secondaryBarcodes" severity="info" [value]="barcode"></p-tag>
        </div>
      </td>

      <td pFrozenColumn alignFrozen="right">
        <span class="p-column-title">Actions:</span>
        <app-product-split-button appendTo="body" label="Actions"  styleClass="p-button-sm w-full p-mb-2"
        itemsStyleClass="table-right-button" [item]="product"
                               [parentToastId]="this.toastId"
                               [editable]="true" (editEventEmitter)="edit($event)"
                               [deletable]="true" (deleteEventEmitter)="delete($event)"
        />
      </td>
    </tr>
  </ng-template>
</p-table>
