<div class="flex flex flex-row">
  <p-inputGroup class="w-fit">
    <p-inputGroupAddon>
      <i [class]="this.icon"></i>
    </p-inputGroupAddon>
  </p-inputGroup>

  <span [class]="'w-full ' + (this.floatLabel ? 'p-float-label' : '')">
    <p-dropdown appendTo="body"
                [attr.id]="this.id"
                [inputId]="this.id"
                [options]="this.items"
                [appendTo]="this.appendTo ? this.appendTo : null"
                [placeholder]="this.floatLabel ? '' : this.placeholder"
                (onChange)="this.onChange($event)"
    ></p-dropdown>
    <label *ngIf="this.floatLabel" [for]="this.id" [textContent]="this.placeholder"></label>
  </span>
</div>
