<p-dialog header="Header" [(visible)]="this.visible" [modal]="true">
  <p-toast [key]="this.toastId"></p-toast>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <i class="fa-solid fa-sitemap mr-2"></i>
      <span class="font-bold white-space-nowrap" [textContent]="headerLabel"></span>
    </div>
  </ng-template>

  <div class="flex">
    <form>
      <div class="flex flex-column ">
        <div class="flex flex-row flex-wrap gap-2">
          <div class="w-full md:w-fit">
            <p-inputGroup class="mt-4">
              <p-inputGroupAddon>
                <i class="fa-solid fa-sitemap"></i>
              </p-inputGroupAddon>
              <p-floatLabel class="w-full">
                <input pInputText id="name" name="name" [(ngModel)]="formData.name" required="true"/>
                <label for="name">Nom</label>
              </p-floatLabel>
            </p-inputGroup>
          </div>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-row gap-2">
      <div class="flex-grow-1">
        <p-button icon="pi pi-times" (click)="this.cancel()" label="Annuler" severity="danger"
                  styleClass="w-full"></p-button>
      </div>
      <div class="flex-grow-1">
        <p-button icon="pi pi-check" (click)="this.validate()" label="Confirmer" severity="success"
                  pAutoFocus [autofocus]="true" [loading]="this.confirming" iconPos="right"
                  styleClass="w-full"></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
