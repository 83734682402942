import {CrudTableComponent} from "./crud-table.component";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";
import {GenericSearchRequest} from "../../models/shared/generic-search.request";
import {RangeSearchRequestInterface} from "../../models/shared/range-search-request.interface";
import {Component} from "@angular/core";
import {IContact} from "../../models/contact/contact.interface";
import {ContactSearchRequestInterface} from "../../models/shared/contact-search-request.interface";

@Component({template: ''})
export abstract class ContactRangeCrudTableComponent<Entity extends IdentifiableEntity, SearchRequest extends GenericSearchRequest & RangeSearchRequestInterface & ContactSearchRequestInterface, CreateRequest, UpdateRequest> extends CrudTableComponent<Entity, SearchRequest, CreateRequest, UpdateRequest> {

  occurrenceRange: Date[] | undefined;
  contact: IContact | undefined;

  protected onContactChanged() {
    this.searchRequest.contactId = this.contact?.id;

    this.tableSearchManager.lockLongDelayAndRefreshItems();
  }

  protected onOccurrenceRangeChanged() {
    const occurredAfter = this.occurrenceRange?.at(0)?.toISOString()?.split('T')[0];
    const occurredBefore = this.occurrenceRange?.at(1)?.toISOString()?.split('T')[0];

    this.searchRequest.occurredAfter = occurredAfter;
    this.searchRequest.occurredBefore = occurredBefore;

    this.tableSearchManager.lockDelayAndRefreshItems();
  }
}
