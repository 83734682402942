import {
  Component,
  EnvironmentInjector,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  Type,
  ViewContainerRef
} from "@angular/core";
import {NameableEntity} from "../../models/shared/nameable.entity";
import {AutoCompleteCompleteEvent} from "primeng/autocomplete";
import {Observable} from "rxjs";
import {GenericSearchResponse} from "../../models/shared/generic-search.response";
import {MessageService} from "primeng/api";
import {EntityFormComponent} from "../crud-form/entity-form.component";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";

@Component({
  template: ''
})
export abstract class ItemAutoCompleteComponent<Entity extends IdentifiableEntity & NameableEntity> implements OnInit {

  private messageService = inject(MessageService);
  private viewContainerRef = inject(ViewContainerRef);
  private environmentInjector = inject(EnvironmentInjector);

  @Input()
  id!: string;

  @Input()
  parentToastId!: string;

  @Input()
  entity!: Entity | undefined;

  @Output()
  entityChange: EventEmitter<Entity | undefined> = new EventEmitter<Entity | undefined>();

  @Input()
  floatLabel = true;

  @Input()
  appendTo: string | undefined;

  private createFormVisibilityEventEmitter = new EventEmitter<Entity | undefined>;
  private editFormVisibilityEventEmitter = new EventEmitter<Entity | undefined>;

  protected entities: Entity[] = [];
  protected placeholder!: string;
  protected icon!: string;

  ngOnInit(): void {
    this.icon = this.getIcon();
    this.placeholder = this.getPlaceholder();

    this.initForm(this.getCreationFormType(), this.createFormVisibilityEventEmitter, this.getCreationHeaderPrefix());
    this.initForm(this.getEditionFormType(), this.editFormVisibilityEventEmitter, this.getEditionHeaderPrefix());
  }

  search(event: AutoCompleteCompleteEvent) {
    this.performSearch(event.query).subscribe(response => {
      this.entities = response.items;
    });
  };

  select(entity: Entity | undefined) {
    this.entityChange.emit(entity);
  }

  onCreate() {
    this.createFormVisibilityEventEmitter.emit(this.entity);
  }

  onEdit() {
    if (this.entity) {
      this.editFormVisibilityEventEmitter.emit(this.entity);
    } else {
      this.messageService.add({
        key: this.parentToastId,
        severity: 'error',
        summary: 'Aucune séléction',
        detail: "Veuillez d'abords séléctionner un élement"
      });
    }
  }

  onSelect(event: any) {
    this.select(event.value);
  }

  onClear() {
    this.select(undefined);
  }

  initForm<T extends EntityFormComponent<Entity, any>>(entityFormType: EntityFormComponentEntry<T>, visibilityEventEmitter: EventEmitter<Entity | undefined>, headerPredix: string) {
    const component = this.viewContainerRef.createComponent(entityFormType.type, {environmentInjector: this.environmentInjector});
    component.setInput("visibilityEventEmitter", visibilityEventEmitter);
    component.setInput("headerPrefix", headerPredix);
    component.setInput("entity", this.entity);

    entityFormType.extraInputs.forEach((input: {
      name: string;
      value: unknown;
    }) => component.setInput(input.name, input.value));

    component.instance.onFailureHandler.subscribe(() => {
    });
    component.instance.onPersistSuccessHandler.subscribe(entity => {
      this.select(entity);
    });
  }

  abstract performSearch(query: string): Observable<GenericSearchResponse<Entity>>;

  abstract getCreationFormType<T extends EntityFormComponent<Entity, any>>(): EntityFormComponentEntry<T>;

  abstract getEditionFormType<T extends EntityFormComponent<Entity, any>>(): EntityFormComponentEntry<T>;

  abstract getIcon(): string;

  abstract getPlaceholder(): string;

  abstract getCreationHeaderPrefix(): string;

  abstract getEditionHeaderPrefix(): string;
}

export interface EntityFormComponentEntry<T extends EntityFormComponent<any, any>> {
  type: Type<T>,
  extraInputs: {
    name: string,
    value: any
  }[]
}

