import {OrderItemRequest} from "../order-item/order-item.request";

export class OrderRequest {

  contactId!: string;
  occurredAt!: Date;
  description: string | undefined;

  orderItems!: OrderItemRequest[];
}
