<div *ngFor="let route of routes" class="flex flex-column">
    <div class="flex m-3">
      <h3>{{route.root}}</h3>
    </div>
    <div class="flex gap-3 m-5">
      <app-quick-access-button 
        *ngFor="let child of route.childs" 
        [label]="child.label" 
        [icon]="child.icon" 
        [link]="child.link">
      </app-quick-access-button>
    </div>
</div>
  