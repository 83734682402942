import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ItemSplitButtonComponent} from "./item-split-button/item-split-button.component";
import {SplitButtonModule} from "primeng/splitbutton";
import {AutoCompleteModule} from "primeng/autocomplete";
import {FormsModule} from "@angular/forms";
import {FloatLabelModule} from "primeng/floatlabel";
import {ValueChipsComponent} from "./value-chips/value-chips.component";
import {ChipsModule} from "primeng/chips";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {InputGroupModule} from "primeng/inputgroup";
import {ContactSplitButtonComponent} from "./item-split-button/contact-split-button/contact-split-button.component";
import {PaymentSplitButtonComponent} from "./item-split-button/payment-split-button/payment-split-button.component";
import {OrderSplitButtonComponent} from "./item-split-button/order-split-button/order-split-button.component";
import { ProductSplitButtonComponent } from './item-split-button/product-split-button/product-split-button.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { ProductsModule } from '../../features/inventory/product/products.module';
import {ColorizedProgressbarComponent} from "./colorized-progressbar/colorized-progressbar.component";
import {ProgressBarModule} from "primeng/progressbar";

@NgModule({
  declarations: [
    ValueChipsComponent,
    ItemSplitButtonComponent,
    ContactSplitButtonComponent,
    PaymentSplitButtonComponent,
    OrderSplitButtonComponent,
    ProductSplitButtonComponent,
    BarcodeComponent,
    ColorizedProgressbarComponent,
  ],
  providers: [
    BarcodeComponent
  ],
  imports: [
    CommonModule,
    SplitButtonModule,
    AutoCompleteModule,
    FormsModule,
    FloatLabelModule,
    ChipsModule,
    InputGroupAddonModule,
    InputGroupModule,
    NgxBarcode6Module,
    ProgressBarModule,
  ],
  exports: [
    ValueChipsComponent,
    ItemSplitButtonComponent,
    ContactSplitButtonComponent,
    PaymentSplitButtonComponent,
    OrderSplitButtonComponent,
    ProductSplitButtonComponent,
    BarcodeComponent,
    ColorizedProgressbarComponent,
  ]
})
export class UiModule {
}
