import {Component, inject, Input} from "@angular/core";
import {OrderBuilderModalDefinition, OrdersTableComponent} from "../../../shared/orders-table/orders-table.component";
import {GenericServiceService} from "../../../../../core/services/shared/generic-service.service";
import {IReportingOrder} from "../../../../../core/models/transaction/reporting-order/reporting-order.interface";
import {
  ReportingOrderSearchRequest
} from "../../../../../core/models/transaction/reporting-order/reporting-order-search.request";
import {
  ReportingOrderCreationRequest
} from "../../../../../core/models/transaction/reporting-order/reporting-order-creation.request";
import {
  ReportingOrderUpdateRequest
} from "../../../../../core/models/transaction/reporting-order/reporting-order-update.request";
import {ReportingOrderType} from "../../../../../core/models/transaction/reporting-order/reporting-order-type";
import {ReportingOrderService} from "../../../../../core/services/transaction/reporting-order.service";
import {
  PrintableReport
} from "../../../../../core/ui/item-split-button/order-split-button/order-split-button.component";
import {SourceType} from "../../../../../core/models/reporting/source-type";
import {ReportType} from "../../../../../core/models/reporting/report-type";
import {
  TableReportingOrderBuilderComponent
} from "../../../shared/order-builder/table-order-builder/table-reporting-order-builder/table-reporting-order-builder.component";
import {PaymentRequestStrategy} from "../../../shared/order-builder/order-builder.component";
import {AllowedReportType, AllowedReportTypes} from "../../../shared/order-builder/reporting/allowed-report.type";

@Component({
  selector: 'app-reporting-orders-crud-table',
  templateUrl: '../../../shared/orders-table/orders-table.component.html',
  styleUrls: ['../../../shared/orders-table/orders-table.component.scss'],
})
export class ReportingOrdersTableComponent extends OrdersTableComponent<IReportingOrder, ReportingOrderSearchRequest, ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {

  reportingOrderService = inject(ReportingOrderService);

  @Input()
  reportingOrderType!: ReportingOrderType;

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): ReportingOrderSearchRequest {
    const occurredAfter = this.occurrenceRange?.at(0)?.toISOString();
    const occurredBefore = this.occurrenceRange?.at(1)?.toISOString();
    const contactId = this.contact?.id;

    return new ReportingOrderSearchRequest(this.reportingOrderType, occurredAfter, occurredBefore, undefined, undefined, contactId, pageIndex, pageSize, offsetIndicators);
  }

  protected getService(): GenericServiceService<IReportingOrder, ReportingOrderSearchRequest, ReportingOrderCreationRequest, ReportingOrderUpdateRequest> {
    return this.reportingOrderService;
  }

  protected isCustomerTransactions(): boolean {
    return true;
  }

  protected isProviderTransactions(): boolean {
    return false;
  }

  protected override getPrintableReports(): PrintableReport[] {
    const printableReports = super.getPrintableReports();

    let label = "Imprimer";
    let header = "Impression d'un document";
    let message = "Voulez vous vraiment imprimer le document ?";

    switch (this.reportingOrderType) {
      case ReportingOrderType.PROFORMA_INVOICE:
        header = "Impression d'une facture proforma";
        message = "Voulez vous vraiment imprimer la facture proforma ?";
        break;
      case ReportingOrderType.ESTIMATE:
        header = "Impression d'un devis";
        message = "Voulez vous vraiment imprimer le devis ?";
        break;
      case ReportingOrderType.INVOICE:
        header = "Impression d'une facture";
        message = "Voulez vous vraiment imprimer la facture ?";
        break;
    }

    printableReports.push({
      label: label,
      header: header,
      message: message,
      reportType: ReportType.REPORTING_ORDER,
      sourceType: SourceType.REPORTING_ORDER,
    })

    return printableReports;
  }

  getCreationOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TableReportingOrderBuilderComponent> {
    return {
      component: TableReportingOrderBuilderComponent,
      inputs: [
        {
          name: 'reportingOrderType',
          value: this.reportingOrderType
        },

        {
          name: 'allowedReportTypes',
          value: this.getAllowedReportTypes()
        }
      ]
    };
  }

  protected getEditionOrderBuilderModalDefinition(): OrderBuilderModalDefinition<TableReportingOrderBuilderComponent> {
    return {
      component: TableReportingOrderBuilderComponent,
      inputs: [
        {
          name: 'reportingOrderType',
          value: this.reportingOrderType
        },

        {
          name: 'allowedReportTypes',
          value: this.getAllowedReportTypes()
        }
      ]
    };
  }

  protected getOrderPaymentStrategy(): PaymentRequestStrategy {
    return PaymentRequestStrategy.NO_PAYMENT;
  }

  private getAllowedReportTypes(): AllowedReportType[] {
    switch (this.reportingOrderType) {
      case ReportingOrderType.INVOICE:
        return [
          AllowedReportTypes.REPORTING_INVOICE_NO_ENFORCEMENT_DEFAULT_TRUE,
          AllowedReportTypes.NO_REPORT_NO_ENFORCEMENT_DEFAULT_TRUE
        ];
      case ReportingOrderType.PROFORMA_INVOICE:
        return [
          AllowedReportTypes.REPORTING_PROFORMA_INVOICE_ENFORCE_VAT,
          AllowedReportTypes.NO_REPORT_ENFORCE_VAT
        ];
      case ReportingOrderType.ESTIMATE:
        return [
          AllowedReportTypes.REPORTING_ESTIMATE_ENFORCE_NO_VAT,
          AllowedReportTypes.NO_REPORT_ENFORCE_NO_VAT
        ];
    }
  }

  protected isPayableOrder(): boolean {
    return false;
  }
}
