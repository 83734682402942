export enum PaymentMethod {
  CASH = "CASH",
  CHECK = "CHECK",
  BANK_TRANSFER = "BANK_TRANSFER",
  CREDIT_CARD = "CREDIT_CARD"
}

export class PaymentMethodUtils {
  public static asString(paymentMethod: PaymentMethod): string {
    switch (paymentMethod) {
      case PaymentMethod.CASH:
        return "Cash";
      case PaymentMethod.CHECK:
        return "Chèque";
      case PaymentMethod.BANK_TRANSFER:
        return "Transfer bancaire";
      case PaymentMethod.CREDIT_CARD:
        return "Carte de crédit";
    }
  }

  public static fromString(value: string): PaymentMethod | undefined {
    switch (value) {
      case 'Cash':
        return PaymentMethod.CASH;
      case 'Chèque':
        return PaymentMethod.CHECK;
      case "Transfer bancaire":
        return PaymentMethod.BANK_TRANSFER;
      case 'Carte de crédit':
        return PaymentMethod.CREDIT_CARD;
    }

    return undefined;
  }
}
