import {map, Observable} from "rxjs";
import {GenericSearchResponse} from "../../models/shared/generic-search.response";
import {HttpService} from "../../services/http/http.service";
import {HttpResponse} from "@angular/common/http";

export class GenericRepository<T, SearchRequest, CreateRequest, UpdateRequest> {

  protected apiPath: string;
  protected httpService: HttpService;

  constructor(httpService: HttpService, apiPath: string) {
    this.apiPath = apiPath;
    this.httpService = httpService;
  }

  public search(request: SearchRequest): Observable<GenericSearchResponse<T>> {

    // @ts-ignore
    const requestParams = Object.entries(request)
      .filter(([key, value]) => value !== null)
      .filter(([key, value]) => value !== undefined)
      .reduce((obj, [key, value]) => ({...obj, [key]: value}), {});

    return this.httpService.search<T>(this.apiPath, requestParams)
      .pipe(map<GenericSearchResponse<T>, GenericSearchResponse<T>>(response => {
        response.items.forEach(item => this.onEdit(item));

        return response;
      }));
  }

  public findById(id: string): Observable<T> {
    return this.httpService.get(this.apiPath + '/' + id, false)
      .pipe(map<T, T>(entity => {
        this.onEdit(entity);

        return entity;
      }));
  }

  public findByIds(ids: string[]): Observable<T[]> {
    return this.httpService.get(this.apiPath + '/ids/' + ids.join(","), false)
      .pipe(map<T[], T[]>(entities => {
        entities.forEach(entity => this.onEdit(entity));

        return entities;
      }));
  }

  public create(data: CreateRequest): Observable<HttpResponse<T>> {
    return this.httpService.post(this.apiPath, data)
      .pipe(map<HttpResponse<T>, HttpResponse<T>>(response => {
        this.onEdit(response.body!);

        return response;
      }));
  }

  update(id: string, data: UpdateRequest): Observable<HttpResponse<T>> {
    return this.httpService.put(this.apiPath + '/' + id, data)
      .pipe(map<HttpResponse<T>, HttpResponse<T>>(response => {
        this.onEdit(response.body!);

        return response;
      }));
  }

  delete(id: string): Observable<HttpResponse<T>> {
    return this.httpService.delete(this.apiPath + '/' + id)
      .pipe(map<HttpResponse<T>, HttpResponse<T>>(response => {
        this.onEdit(response.body!);

        return response;
      }));
  }

  onEdit(entity: T | undefined): T | undefined {
    return entity;
  }
}
