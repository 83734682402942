import {NgModule} from "@angular/core";
import {
  ProductCategoryCreationFormComponent
} from "./product-category-creation-form/product-category-creation-form.component";
import {
  ProductCategoryEditionFormComponent
} from "./product-category-edition-form/product-category-edition-form.component";
import {CommonModule} from "@angular/common";
import {ComponentsModule} from "../../../../shared/components/components.module";
import {ToolbarModule} from "primeng/toolbar";
import {FileUploadModule} from "primeng/fileupload";
import {FormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {ChipModule} from "primeng/chip";
import {UiModule} from "../../../../core/ui/ui.module";
import {StyleClassModule} from "primeng/styleclass";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {PasswordModule} from "primeng/password";
import {DividerModule} from "primeng/divider";
import {FloatLabelModule} from "primeng/floatlabel";
import {ConfirmationService, MessageService} from "primeng/api";

@NgModule({
  declarations: [
    ProductCategoryCreationFormComponent,
    ProductCategoryEditionFormComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    ToolbarModule,
    FileUploadModule,
    FormsModule,
    RippleModule,
    ChipModule,
    UiModule,
    StyleClassModule,
    InputGroupModule,
    InputGroupAddonModule,
    PasswordModule,
    DividerModule,
    FloatLabelModule
  ],
  exports: [
    ProductCategoryCreationFormComponent,
    ProductCategoryEditionFormComponent
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class ProductCategoryFormModule{

}
