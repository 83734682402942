import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {PaymentFormComponent} from "../shared/payment-form/payment-form.component";
import {IPayment} from "../../../core/models/payment/payment.interface";
import {PaymentFormData} from "../shared/payment-form/payment-form-data.";

@Component({
  selector: 'app-payment-creation-form',
  templateUrl: '../shared/payment-form/payment-form.component.html',
  styleUrl: '../shared/payment-form/payment-form.component.scss',
})
export class PaymentCreationFormComponent extends PaymentFormComponent {

  protected onConfirm(entity: IPayment | undefined, formData: PaymentFormData): Observable<IPayment> {
    return this.paymentService.create(formData.toPaymentCreationRequest());
  }
}
