import {Component, inject} from "@angular/core";
import {EntityFormComponent} from "../../../../../core/ui/crud-form/entity-form.component";
import {ValidationReport, ValidationViolation} from "../../../../../core/ui/validation/validation-report";
import {ProductFormData} from "./product-form-data.";
import {ProductCategoryService} from "../../../../../core/services/inventory/product-category/product-category.service";
import {IProduct} from "../../../../../core/models/inventory/product/product.interface";
import {ProductService} from "../../../../../core/services/inventory/product/product.service";

@Component({
  template: ''
})
export abstract class ProductFormComponent extends EntityFormComponent<IProduct, ProductFormData> {

  protected disableAvailableQuantity!: boolean;

  public productService = inject(ProductService);
  public productCategoryService = inject(ProductCategoryService);

  protected override onCreateFormData(entity: IProduct | undefined): ProductFormData {
    return new ProductFormData();
  }

  protected onValidate(formData: ProductFormData): ValidationReport {
    const violations: ValidationViolation[] = [];

    // Mandatory information
    if (formData.name == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "name";
      violation.errorDetails = "Le nom est obligatoire";

      violations.push(violation);
    }

    if (formData.productCategory == undefined) {
      const violation = new ValidationViolation();
      violation.fieldName = "productCategory";
      violation.errorDetails = "La catégorie est obligatoire";

      violations.push(violation);
    }

    if (formData.purchasePrice == undefined || formData.purchasePrice < 0) {
      const violation = new ValidationViolation();
      violation.fieldName = "purchasePrice";
      violation.errorDetails = "Le prix d'achat doit être un nombre positive";

      violations.push(violation);
    }

    if (formData.sellingPrice == undefined || formData.sellingPrice < 0) {
      const violation = new ValidationViolation();
      violation.fieldName = "sellingPrice";
      violation.errorDetails = "Le prix de vente doit être un nombre positive";

      violations.push(violation);
    }

    if (formData.resellerPrice && formData.resellerPrice < 0) {
      const violation = new ValidationViolation();
      violation.fieldName = "sellingPrice";
      violation.errorDetails = "Le prix de revente doit être un nombre positive";

      violations.push(violation);
    }

    // Relations between the data
    if (formData.purchasePrice && formData.sellingPrice && formData.purchasePrice > formData.sellingPrice) {
      const violation = new ValidationViolation();
      violation.fieldName = "sellingPrice";
      violation.errorDetails = "Le prix de vente doit être supérieur au prix d'achat";

      violations.push(violation);
    }

    if (formData.purchasePrice && formData.resellerPrice && formData.purchasePrice > formData.resellerPrice) {
      const violation = new ValidationViolation();
      violation.fieldName = "resellerPrice";
      violation.errorDetails = "Le prix de revente doit être supérieur au prix d'achat";

      violations.push(violation);
    }

    if (formData.sellingPrice && formData.resellerPrice && formData.resellerPrice > formData.sellingPrice) {
      const violation = new ValidationViolation();
      violation.fieldName = "resellerPrice";
      violation.errorDetails = "Le prix de revente doit être inférieur au prix de vente";

      violations.push(violation);
    }

    return new ValidationReport(violations);
  }
}
