export class ProductRequest {

  name!: string;
  barcode: string | undefined;
  reference: string | undefined;
  productCategoryId: string | undefined;
  purchasePrice: number | undefined;
  sellingPrice: number | undefined;
  resellerPrice: number | undefined;
  resellerPriceSwitchingQuantity: number | undefined;
  minimalAllowedQuantity: number | undefined;
  secondaryBarcodes: string[] | undefined;
}
