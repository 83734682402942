import {Component} from "@angular/core";
import {ProductCategoryFormComponent} from "../product-category-form/product-category-form.component";
import {IProductCategory} from "../../../../../core/models/inventory/product-category/product-category.interface";
import {ProductCategoryFormData} from "../product-category-form/product-category-form-data.";
import {Observable} from "rxjs";

@Component({
  selector: 'app-product-category-edition-form',
  templateUrl: '../product-category-form/product-category-form.component.html',
  styleUrl: '../product-category-form/product-category-form.component.scss',
})
export class ProductCategoryEditionFormComponent extends ProductCategoryFormComponent {

  protected override onResetFormData(entity: IProductCategory | undefined, previousFormData: ProductCategoryFormData): Observable<ProductCategoryFormData> {
    return new Observable<ProductCategoryFormData>(subscriber => subscriber.next(ProductCategoryFormData.fromProductCategory(entity)));
  }

  protected onConfirm(entity: IProductCategory, formData: ProductCategoryFormData): Observable<IProductCategory> {
    return this.productCategoryService.update(entity.id, formData.toProductCategoryUpdateRequest());
  }
}
