import {EntityFormComponentEntry, ItemAutoCompleteComponent} from "../item-auto-complete.component";
import {IProductCategory} from "../../../models/inventory/product-category/product-category.interface";
import {Observable} from "rxjs";
import {Component, inject, Input} from "@angular/core";
import {GenericSearchResponse} from "../../../models/shared/generic-search.response";
import {IContact} from "../../../models/contact/contact.interface";
import {ContactService} from "../../../services/contact/contact.service";
import {ContactSearchRequest} from "../../../models/contact/contact-search.request";
import {
  ContactCreationFormComponent
} from "../../../../features/contact/contact-creation-form/contact-creation-form.component";
import {
  ContactEditionFormComponent
} from "../../../../features/contact/contact-edition-form/contact-edition-form.component";

@Component({
  selector: 'app-contact-auto-complete',
  templateUrl: '../item-auto-complete.component.html',
  styleUrl: '../item-auto-complete.component.scss'
})
export class ContactAutoCompleteComponent extends ItemAutoCompleteComponent<IContact> {

  contactService = inject(ContactService);

  @Input()
  draggable: boolean = true;

  @Input()
  includeCustomers!: boolean;

  @Input()
  includeProviders!: boolean;

  performSearch(query: string): Observable<GenericSearchResponse<IProductCategory>> {
    const request = new ContactSearchRequest(query, this.includeCustomers, this.includeProviders, 0, 15);

    return this.contactService.search(request);
  }

  getIcon(): string {
    return "fa fa-user";
  }

  getPlaceholder(): string {
    if (this.includeCustomers && this.includeProviders) {
      return "Contact";
    } else if (this.includeCustomers) {
      return "Client";
    } else if (this.includeProviders) {
      return "Fournisseur";
    }

    throw new Error("Unknown contact type");
  }

  getCreationHeaderPrefix(): string {
    if (this.includeCustomers && this.includeProviders) {
      return "Nouveau contact";
    } else if (this.includeCustomers) {
      return "Nouveau client";
    } else if (this.includeProviders) {
      return "Nouveau fournisseur";
    }

    throw new Error("Unknown contact type");
  }

  getEditionHeaderPrefix(): string {
    if (this.includeCustomers && this.includeProviders) {
      return "Contact";
    } else if (this.includeCustomers) {
      return "Client";
    } else if (this.includeProviders) {
      return "Fournisseur";
    }

    throw new Error("Unknown contact type");
  }

  // @ts-ignore
  getCreationFormType(): EntityFormComponentEntry<ContactCreationFormComponent> {
    return {
      type: ContactCreationFormComponent,
      extraInputs: [
        {
          name: 'forceCustomer',
          value: this.includeCustomers && !this.includeProviders
        },

        {
          name: 'forceProvider',
          value: !this.includeCustomers && this.includeProviders
        },
        {
          name: "enableInitialBalance",
          value: true
        },
        {
          name: "draggable",
          value: this.draggable
        }
      ]
    };
  }

  // @ts-ignore
  getEditionFormType(): EntityFormComponentEntry<ContactEditionFormComponent> {
    return {
      type: ContactEditionFormComponent,
      extraInputs: [
        {
          name: 'forceCustomer',
          value: this.includeCustomers && !this.includeProviders
        },

        {
          name: 'forceProvider',
          value: !this.includeCustomers && this.includeProviders
        },
        {
          name: "enableInitialBalance",
          value: false
        },
        {
          name: "draggable",
          value: this.draggable
        }
      ]
    };
  }
}
