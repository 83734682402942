import {WarrantyPrintingRequest} from "../../../core/models/reporting/warranty-printing.request";

export class WarrantyFormData extends WarrantyPrintingRequest {

  date: Date | undefined;

  public toWarrantyPrintingRequest(): WarrantyPrintingRequest {
    const request = new WarrantyPrintingRequest();
    request.reportStyle = this.reportStyle;
    request.nature = this.nature;
    request.type = this.type;
    request.note = this.note;
    request.sequence = this.sequence;
    request.amount = this.amount;
    request.orderNumber = this.orderNumber;
    request.orderDate = this.date!.toLocaleDateString();
    request.period = this.period;
    request.sourceContactName = this.sourceContactName;
    request.targetContactAddress = this.targetContactAddress;

    request.sourceContactAddress = this.sourceContactAddress;
    request.targetContactName = this.targetContactName;

    return request;
  }
}
