export class ValidationReport {

  violations: ValidationViolation[] = [];

  constructor(violations: ValidationViolation[]) {
    this.violations = violations;
  }
}

export class ValidationViolation {

  fieldName: string | undefined;
  errorSummary: string | undefined;
  errorDetails: string | undefined;
}
