export class ColorsHelper {

  public static toRgb(alpha: number): string {
      let position = Math.floor(alpha * 1023);

      let red;
      let green;
      let blue;

      if (position <= 255) {
        red = 255;
        green = position % 256;
        blue = 0;
      } else if (position <= 511) {
        red = 255 - (position % 256);
        green = 255;
        blue = 0;
      } else if (position <= 767) {
        red = 0;
        green = 255;
        blue = position % 256;
      } else if (position <= 1023) {
        red = 0;
        green = 255 - position % 256;
        blue = 255;
      } else {
        red = 0;
        green = 0;
        blue = 0;
      }

      red = Math.max(0, red);
      green = Math.max(0, green);
      blue = Math.max(0, blue);

      return "rgb(" + red + "," + green + "," + blue + ")";
  }
}
