<div class="flex flex-column">
  <p-toast [key]="this.toastId"></p-toast>
  <p-confirmDialog></p-confirmDialog>

  <app-product-creation-form headerPrefix="Nouveau produit"
                           [visibilityEventEmitter]="this.createFormVisibilityEventEmitter"
                           (onPersistSuccessHandler)="this.onCreateSuccessful($event)"
  />
  <p-table
    dataKey="id"
    selectionMode="single"
    [(selection)]="this.tableSelectedItem"

    [value]="this.tableSearchManager.items"

    [paginator]="true"
    [rows]="this.pageSize"
    [totalRecords]="this.tableSearchManager.totalItemsCount"

    [lazy]="true"
    [loading]="this.tableSearchManager.searching || this.processing"
    (onLazyLoad)="tableSearchManager.search($event)"
    [(first)]="this.tableSearchManager.first"

    [rowHover]="true"
    [resizableColumns]="true"
    responsiveLayout="stack"
    styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

    [scrollable]="true"

    [showFirstLastIcon]="false"
    [showPageLinks]="false"
  >
    <ng-template pTemplate="caption">
      <p-inputGroup class="mb-2">
        <p-inputGroupAddon>
          <i class="fa fa-barcode"></i>
        </p-inputGroupAddon>
        <input pInputText id="barcode" name="barcode" required="true" placeholder="Code à barre"
               [(ngModel)]="this.barcode"
               (keydown)="this.onBarcodeSearch($event)"/>
      </p-inputGroup>

      <div class="mb-2">
        <app-product-category-auto-complete id="productCategory"
                                            class="flex-grow-1"
                                            appendTo="body"
                                            [floatLabel]="false"
                                            [parentToastId]="this.toastId"
                                            [(entity)]="this.productCategory"
                                            (entityChange)="this.onProductCategoryChanged()"/>
      </div>
      <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
          <span class="flex-grow-1 p-input-icon-left">
            <i class="pi pi-search"></i>
            <input class=" w-full" pInputText type="text" (input)="tableSearchManager.lockDelayAndRefreshItems()"
                  [(ngModel)]="this.searchRequest.name"
                  placeholder="Rechercher ..."/>
          </span>
          <button pButton pRipple label="Nouveau produit" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pResizableColumn><i class="fa-solid fa-box mr-2"></i>Nom</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-hashtag mr-2"></i>Référence</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-sitemap mr-2"></i>Catégorie</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-money-bill mr-2"></i>Prix de vente</th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-money-bill-transfer mr-2"></i>Prix de revendeur
        </th>
        <th pResizableColumn class="text-center"><i class="fa-solid fa-dolly mr-2"></i>Quantité disponible</th>
        <th style="width: 5rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr [pSelectableRow]="product" (dblclick)="this.onSelectItem(product)">
        <td><span class="p-column-title">Nom:</span>{{ product.name }}</td>
        <td class="text-center"><span class="p-column-title">Référence:</span>{{ product.reference }}</td>
        <td class="text-center"><span class="p-column-title">Catégorie:</span>
          <p-tag *ngIf="product.productCategory?.name">{{ product.productCategory?.name }}</p-tag>
        </td>
        <td class="text-center"><span class="p-column-title">Prix de vente:</span>
          {{ product.sellingPrice | number:'1.2-2' }} DZD
        </td>
        <td class="text-center"><span class="p-column-title">Prix de revendeur:</span>
          <span *ngIf="product.resellerPrice">{{ product.resellerPrice | number:'1.2-2' }} DZD</span>
        </td>
        <td class="text-center"><span class="p-column-title">Quantité disponible:</span>
          <p-tag [severity]="product.availableQuantity <= product.minimalAllowedQuantity ? 'danger': 'success'">
            {{ product.availableQuantity || 0 | number:'1.3-3' }}
          </p-tag>
        </td>
        <td pFrozenColumn alignFrozen="right">
          <p-button icon="pi pi-plus" [style]="{width: '2rem', height: '2rem'}"  severity="success" (click)="this.onSelectItem(product)"></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
