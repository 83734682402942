import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {DropdownChangeEvent} from "primeng/dropdown";

@Component({
  template: ''
})
export abstract class EnumDropdownComponent<E> implements OnInit {

  @Input()
  id!: string;

  @Input()
  entity!: E | undefined;

  @Output()
  entityChange: EventEmitter<E | undefined> = new EventEmitter<E | undefined>();

  @Input()
  floatLabel = true;

  @Input()
  appendTo: string | undefined;

  protected placeholder!: string;
  protected icon!: string;

  protected items: string[] = [];

  ngOnInit(): void {
    const entities = this.findAll();
    const entity = entities.at(0);

    this.icon = this.getIcon();
    this.placeholder = this.getPlaceholder();
    this.items = entities.map(e => this.asString(e));

    this.entityChange.emit(entity);
  }

  onChange(event: DropdownChangeEvent) {
    this.entityChange.emit(this.asEntity(event.value));
  }

  abstract getIcon(): string;

  abstract getPlaceholder(): string;

  abstract findAll(): E[];

  abstract asString(entity: E | undefined): string;

  abstract asEntity(item: string): E | undefined;
}
