import {NgModule} from "@angular/core";
import {ProductCategoryAutoCompleteComponent} from "./product-category-auto-complete.component";
import {
  ProductCategoryFormModule
} from "../../../../features/inventory/product-category/product-category-form/product-category-form.module";
import {InputGroupModule} from "primeng/inputgroup";
import {InputGroupAddonModule} from "primeng/inputgroupaddon";
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {FormsModule} from "@angular/forms";
import {NgComponentOutlet, NgIf, NgTemplateOutlet} from "@angular/common";

@NgModule({
  declarations: [
    ProductCategoryAutoCompleteComponent
  ],
  providers: [],
    imports: [
        ProductCategoryFormModule,
        InputGroupModule,
        InputGroupAddonModule,
        AutoCompleteModule,
        ButtonModule,
        FormsModule,
        NgTemplateOutlet,
        NgComponentOutlet,
        NgIf
    ],
  exports: [
    ProductCategoryAutoCompleteComponent
  ]
})
export class ProductCategoryAutoCompleteModule {

}
