import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {ConfirmationService, MenuItem, MessageService} from "primeng/api";
import {NameableEntity} from "../../models/shared/nameable.entity";
import {SplitButton} from "primeng/splitbutton";
import {Observable} from "rxjs";
import {ReportService} from "../../services/report/report.service";
import {PrintingHandler} from "../../util/handlers/printing.handler";
import {IdentifiableEntity} from "../../models/shared/identifiable-entity";
import {BusinessErrorHandler} from "../../util/handlers/business-error.handler";
import { BarcodeHandler } from '../../util/handlers/barcode.handler';


@Component({
  selector: 'app-item-split-button',
  templateUrl: './item-split-button.component.html',
  styleUrl: './item-split-button.component.scss'
})
export class ItemSplitButtonComponent<T extends IdentifiableEntity & NameableEntity> extends SplitButton {

  protected confirmationService = inject(ConfirmationService);
  protected messageService = inject(MessageService);
  protected businessErrorHandler = inject(BusinessErrorHandler);

  @Input()
  parentToastId!: string;

  @Input()
  itemsStyleClass: string | undefined;

  @Input()
  item!: T;

  @Input()
  editable: boolean = false;

  @Output()
  editEventEmitter: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  deletable: boolean = false;

  @Output()
  deleteEventEmitter: EventEmitter<T> = new EventEmitter<T>();

  @Input()
  processing!: boolean;

  @Output()
  processingChange = new EventEmitter<boolean>;

  reportService = inject(ReportService);
  printingHandler = inject(PrintingHandler);
  barcodeHandler = inject(BarcodeHandler);

  items: MenuItem[] = [];

  override ngOnInit() {
    super.ngOnInit();

    if (this.editable) {
      this.items.push({
        icon: 'pi pi-pencil',
        label: 'Modifier',
        styleClass: 'text-warning',
        command: () => {
          this.editEventEmitter.emit(this.item);
        }
      });
    }

    if (this.deletable) {
      this.items.push({
        icon: 'pi pi-trash',
        label: 'Supprimer',
        styleClass: 'text-danger',
        command: () => {
          // @ts-ignore
          this.confirmationService.confirm({
            //target: event?.target as EventTarget,
            message: 'Voulez vous vraiment supprimer: ' + this.item.name + " ?",
            header: 'Confirmation de suppression',
            icon: 'pi pi-info-circle',
            acceptButtonStyleClass: "p-button-danger p-button-text",
            rejectButtonStyleClass: "p-button-text p-button-text",
            acceptIcon: "none",
            acceptLabel: "Oui",
            rejectIcon: "none",
            rejectLabel: "Non",

            accept: () => {
              this.deleteEventEmitter.emit(this.item);
            },

            reject: () => {
            }
          });
        }
      });
    }
  }


  protected printWithCustomDialog<I>(visibilityEventEmitter: EventEmitter<I>, input: I) {
    visibilityEventEmitter.emit(input);
  }

  protected printWithDefaultDialog<I>(header: string, message: string, input: I, printingCallback: (id: string, input: I) => Observable<Blob>) {
    this.print("printingConfirmDialog", header, message, input, printingCallback);
  }

  protected print<I>(confirmDialogKey: string, header: string, message: string, input: I, printingCallback: (id: string, input: I) => Observable<Blob>): void {
    this.confirmationService.confirm({
      key: confirmDialogKey,
      header: header,
      message: message,
      acceptButtonStyleClass: "p-button-success",
      rejectButtonStyleClass: "p-button-danger",
      acceptIcon: "none",
      acceptLabel: "Oui",
      rejectIcon: "none",
      rejectLabel: "Non",

      accept: () => {
        this.processingChange.emit(true);

        printingCallback(this.item.id, input).subscribe({
          next: (data) => {
            this.printingHandler.open(data);
            this.processingChange.emit(false);
          },

          error: (error) => {
            console.error(error);
            this.processingChange.emit(false);
            this.messageService.add({
              key: this.parentToastId,
              severity: 'error',
              summary: 'Opération échouée',
              detail: this.businessErrorHandler.convert(error)
            });
          }
        });
      },

      reject: () => {
      }
    });
  }
}
