import {AfterViewInit, Component, EventEmitter} from "@angular/core";

@Component({
  template: ''
})
export abstract class CrudDashboardComponent implements AfterViewInit {

  reloadEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.reloadEventEmitter.emit();
  }
}
