import {Injectable} from '@angular/core';
import {ContactSearchRequest} from "../../models/contact/contact-search.request";
import {ContactCreationRequest} from "../../models/contact/contact-creation.request";
import {IContact} from "../../models/contact/contact.interface";
import {ContactUpdateRequest} from "../../models/contact/contact-update.request";
import {GenericServiceService} from "../shared/generic-service.service";
import {ContactRepository} from "../../repositories/contact/contact.repository";

@Injectable()
export class ContactService extends GenericServiceService<IContact, ContactSearchRequest, ContactCreationRequest, ContactUpdateRequest> {

  constructor(contactRepository: ContactRepository) {
    super(contactRepository);
  }
}
