import { ItemSplitButtonComponent } from "../item-split-button.component";
import { Component } from "@angular/core";
import { IProduct } from "../../../models/inventory/product/product.interface";

@Component({
  selector: 'app-product-split-button',
  templateUrl: '../item-split-button.component.html',
  styleUrl: '../item-split-button.component.scss'
})
export class ProductSplitButtonComponent extends ItemSplitButtonComponent<IProduct> {
  override ngOnInit() {
    this.items.push(
      {
        icon: 'pi pi-file',
        label: 'Imprimer',
        styleClass: this.itemsStyleClass ? this.itemsStyleClass : '',
        items: [
          {
            icon: 'pi pi-arrow-right',
            label: 'Code Barre',
            target: 'body',
            command: () => {
              this.barcodeHandler.printBarcode(
                {
                  name: this.item.name,
                  barcode: this.item.barcode,
                  sellingPrice: this.item.sellingPrice,
                },
                {
                  height: 35,
                  width: 45
                }
              );
            }
          },
        ]
      }
    );

    super.ngOnInit();
  }
}
