import {IdentifiableEntity} from "../shared/identifiable-entity";
import {NameableEntity} from "../shared/nameable.entity";
import {SequenceGenerator} from "../../util/generators/sequence.generator";
import {IContact} from "../contact/contact.interface";

export interface IWarranty extends IdentifiableEntity, NameableEntity {

  contactId: string;
  contact: {
    name: string;
    address: string;
  }
}

export class IWarrantyUtils {
  public static fromContact(contact: IContact): IWarranty {
    return {
      contact: {
        name: contact.name,
        address: contact.address || ''
      },

      contactId: contact.id,

      id: SequenceGenerator.generate(),
      name: '',
    }
  }
}


