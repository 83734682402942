import {Component, inject} from "@angular/core";
import {IProductCategory} from "../../../../core/models/inventory/product-category/product-category.interface";
import {
  ProductCategorySearchRequest
} from "../../../../core/models/inventory/product-category/product-category-search.request";
import {ProductCategoryService} from "../../../../core/services/inventory/product-category/product-category.service";
import {CrudTableComponent} from "../../../../core/ui/crud-table/crud-table.component";
import {
  ProductCategoryCreateRequest
} from "../../../../core/models/inventory/product-category/product-category-create.request";
import {
  ProductCategoryUpdateRequest
} from "../../../../core/models/inventory/product-category/product-category-update.request";
import {GenericServiceService} from "../../../../core/services/shared/generic-service.service";

@Component({
  selector: 'app-product-categories-crud-table',
  templateUrl: './product-categories-table.component.html',
  styleUrls: ['./product-categories-table.component.scss'],
})
export class ProductCategoriesTableComponent extends CrudTableComponent<IProductCategory, ProductCategorySearchRequest, ProductCategoryCreateRequest, ProductCategoryUpdateRequest> {

  productCategoryService = inject(ProductCategoryService);

  protected getService(): GenericServiceService<IProductCategory, ProductCategorySearchRequest, ProductCategoryCreateRequest, ProductCategoryUpdateRequest>{
    return this.productCategoryService;
  }

  protected buildSearchRequest(pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined): ProductCategorySearchRequest {
    return new ProductCategorySearchRequest(undefined, pageIndex, pageSize, offsetIndicators);
  }
}
