import {PaymentRequest} from "../../../../core/models/payment/payment.request";
import {IContact} from "../../../../core/models/contact/contact.interface";
import {PaymentCreationRequest, TargetOrderRequest} from "../../../../core/models/payment/payment-creation.request";

export class PaymentFormData extends PaymentRequest {

  contact!: IContact;
  order: TargetOrderRequest | undefined;

  public toPaymentCreationRequest(): PaymentCreationRequest {
    const request = new PaymentCreationRequest();
    request.occurredAt = this.occurredAt;
    request.paymentMethod = this.paymentMethod;
    request.paymentType = this.paymentType;
    request.amount = this.amount;
    request.contactId = this.contact.id;
    request.order = this.order;
    request.description = this.description;

    return request;
  }
}
