import {Component, OnInit, inject} from '@angular/core';
import {ContactsDashboardComponent} from "../shared/contacts-dashboard/contacts-dashboard.component";


@Component({
  selector: 'app-customers-dashboard',
  templateUrl: './customers-dashboard.component.html',
  styleUrls: ['./customers-dashboard.component.scss']
})
export class CustomersDashboardComponent extends ContactsDashboardComponent {}
