<p-toast [key]="this.toastId"></p-toast>
<p-confirmDialog></p-confirmDialog>

<app-product-category-creation-form [headerPrefix]="this.addHeaderPrefix"

                                    [visibilityEventEmitter]="this.createFormVisibilityEventEmitter"

                                    (onPersistSuccessHandler)="this.onCreateSuccessful($event)"/>

<app-product-category-edition-form [headerPrefix]="this.editHeaderPrefix"

                                   [visibilityEventEmitter]="this.editFormVisibilityEventEmitter"

                                   (onPersistSuccessHandler)="this.onEditSuccessful($event)"/>

<p-table
  dataKey="id"
  [value]="this.tableSearchManager.items"

  [paginator]="true"
  [rows]="this.pageSize"
  [totalRecords]="this.tableSearchManager.totalItemsCount"

  [lazy]="true"
  [loading]="this.tableSearchManager.searching || this.processing"
  [(first)]="this.tableSearchManager.first"
  (onLazyLoad)="tableSearchManager.search($event)"

  [rowHover]="true"
  [resizableColumns]="true"
  responsiveLayout="stack"
  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

  [scrollable]="true"

  [showFirstLastIcon]="false"
  [showPageLinks]="false"

  currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
      <button pButton pRipple [label]="this.addButtonLabel" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()"></button>
      <span class="flex-grow-1 p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class=" w-full" pInputText type="text" (input)="tableSearchManager.lockDelayAndRefreshItems()" [(ngModel)]="this.searchRequest.name"
               placeholder="Rechercher ..."/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn><i class="fa-solid fa-sitemap mr-2"></i>Nom</th>
      <th style="width: 30px"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-productCategory>
    <tr>
      <td><span class="p-column-title">Nom:</span>{{ productCategory.name }}</td>
      <td>
        <span class="p-column-title">Actions:</span>
        <app-item-split-button appendTo="body" label="Actions" styleClass="p-button-sm w-full" [item]="productCategory"
                               [parentToastId]="this.toastId"
                               [editable]="true" (editEventEmitter)="edit($event)"
                               [deletable]="true" (deleteEventEmitter)="delete($event)"
        />
      </td>
    </tr>
  </ng-template>
</p-table>
