import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-navigation-breadcrumb',
  templateUrl: './navigation-breadcrumb.component.html',
  styleUrls: ['./navigation-breadcrumb.component.scss']
})
export class NavigationBreadcrumbComponent implements OnInit {
  breadcrumbItems: MenuItem[] = [];
  home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary')
    ).subscribe(route => {
      const breadcrumb: MenuItem[] = [{ label: 'Accès rapide', routerLink: '/navigateTo' }];

      let currentRoute = route;
      const breadcrumbItems: MenuItem[] = [];

      while (currentRoute) {
        const routeData = currentRoute.snapshot.data;
        if (routeData && routeData['breadcrumb'] && routeData['breadcrumb'] !== 'Accès rapide') {
          let url = this.getParentRoute(currentRoute);
          if (route.children) {
            url = currentRoute.snapshot.url.map(segment => segment.path).join('/');
          }
          const label = routeData['breadcrumb'];
          const isDisabled = this.isDisabledRoute(url);
          const breadcrumbItem: MenuItem = {
            label: label,
            routerLink: isDisabled ? null : '/navigateTo',
            queryParams: { parentRoute: url },
            disabled: isDisabled
          };
          breadcrumbItems.push(breadcrumbItem);
        } else if (routeData && routeData['breadcrumb'] === 'Accès rapide') {
          let parentRouteLabel = '';

          const queryParams = route.snapshot.queryParams;
          if (queryParams && queryParams['parentRoute']) {
            const parentRoutePath = queryParams['parentRoute'];
            const foundRoute = this.router.config.find(route => route.path === parentRoutePath);
          
            if (foundRoute && foundRoute.data && foundRoute.data['breadcrumb']) {
              parentRouteLabel = foundRoute.data['breadcrumb'];
            } else {
              parentRouteLabel = '';
            }
          }
          
          if(parentRouteLabel != '') {
            const breadcrumbItem: MenuItem = {
              label: parentRouteLabel,
              routerLink: null,
              queryParams: { parentRoute: parentRouteLabel },
              disabled: true
            };
            breadcrumbItems.push(breadcrumbItem);
          }
          
        }
        currentRoute = currentRoute.parent!;
      }

      const reversedItems = breadcrumbItems.reverse();

      if (reversedItems.length >= 2) {
        const parentRouteBreadcrumb = reversedItems.splice(1, 1)[0];
        reversedItems.splice(2, 0, parentRouteBreadcrumb);
      }

      this.breadcrumbItems = [breadcrumb[0], ...reversedItems];
      this.breadcrumbItems[2].disabled = true;
    });
  }

  private getParentRoute(route: ActivatedRoute): string {
    let parentRoute = '';
    let parent = route.parent;
    while (parent) {
      if (parent.snapshot && parent.snapshot.routeConfig && parent.snapshot.routeConfig.path) {
        parentRoute = parent.snapshot.routeConfig.path;
      }
      parent = parent.parent;
    }
    return parentRoute;
  }

  private isDisabledRoute(url: string): boolean {
    return url === 'navigateTo' || url.toLowerCase() === 'home';
  }
}
