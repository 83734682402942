<p-toast [key]="this.toastId"></p-toast>
<p-confirmDialog></p-confirmDialog>

<p-confirmDialog key="printingConfirmDialog">
  <ng-template pTemplate="message" let-message>
    <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
      <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
      <p class="ml-2 mr-2">{{ message.message }}</p>
      <p-inputGroup class="mt-3 mr-1 w-full">
        <p-inputGroupAddon>
          <i class="fa-solid fa-calendar"></i>
        </p-inputGroupAddon>
        <p-floatLabel class="w-full">
          <p-calendar id="historyReportYear"
                      dateFormat="yy"
                      view="year"
                      inputId="historyReportYear"
                      name="historyReportYear"
                      appendTo="body"
                      [showIcon]="true"
                      [showButtonBar]="true"
                      [(ngModel)]="this.historyReportDate">
          </p-calendar>
          <label for="historyReportYear">Transactions de l'année</label>
        </p-floatLabel>
      </p-inputGroup>
    </div>
  </ng-template>
</p-confirmDialog>

<app-warranty-form headerPrefix="Garantie"

                   [visibilityEventEmitter]="this.warrantyCreateFormVisibilityEventEmitter"/>

<app-contact-creation-form [headerPrefix]="this.addHeaderPrefix"

                           [visibilityEventEmitter]="this.createFormVisibilityEventEmitter"

                           [forceCustomer]="this.forceCustomerCreation"
                           [forceProvider]="this.forceProviderCreation"

                           [enableInitialBalance]="true"

                           (onPersistSuccessHandler)="this.onCreateSuccessful($event)"/>

<app-contact-edition-form [headerPrefix]="this.editHeaderPrefix"

                          [visibilityEventEmitter]="this.editFormVisibilityEventEmitter"

                          [forceCustomer]="this.forceCustomerCreation"
                          [forceProvider]="this.forceProviderCreation"

                          [enableInitialBalance]="false"

                          (onPersistSuccessHandler)="this.onEditSuccessful($event)"/>

<p-table
  dataKey="id"
  [value]="this.tableSearchManager.items"

  [paginator]="true"
  [rows]="this.pageSize"
  [totalRecords]="this.tableSearchManager.totalItemsCount"

  [lazy]="true"
  [loading]="this.tableSearchManager.searching || this.processing"
  [(first)]="this.tableSearchManager.first"
  (onLazyLoad)="tableSearchManager.search($event)"

  [rowHover]="true"
  [resizableColumns]="true"
  responsiveLayout="stack"
  styleClass="p-datatable-sm p-datatable-striped p-datatable-gridlines"

  [scrollable]="true"

  [showFirstLastIcon]="false"
  [showPageLinks]="false"

  currentPageReportTemplate="Affichage du {first} au {last} d'un total de {totalRecords} élements"
  [showCurrentPageReport]="true"
>
  <ng-template pTemplate="caption">
    <div class="flex flex-row flex-wrap gap-2 align-items-center justify-content-between">
      <button pButton pRipple [label]="this.addButtonLabel" icon="pi pi-plus" class="p-button-success w-full md:w-fit"
              (click)="this.create()"></button>
      <span class="flex-grow-1 p-input-icon-left">
        <i class="pi pi-search"></i>
        <input class=" w-full" pInputText type="text" (input)="tableSearchManager.lockDelayAndRefreshItems()"
               [(ngModel)]="this.searchRequest.name"
               placeholder="Rechercher ..."/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pResizableColumn><i class="fa-solid fa-user mr-2"></i>Nom</th>
      <th pResizableColumn><i class="fa-solid fa-map mr-2"></i>Adresse</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-phone mr-2"></i>N° Téléphone</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-envelope mr-2"></i>E-mail</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-coins mr-2"></i>Solde initial</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-scale-balanced mr-2"></i>Solde courant</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-people-arrows mr-2"></i>C / F</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-tablet mr-2"></i>N° RC</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-file-invoice mr-2"></i>NIF</th>
      <th pResizableColumn class="text-center"><i class="fa-solid fa-square-poll-vertical mr-2"></i>NIS</th>
      <th style="width:30px" pFrozenColumn alignFrozen="right"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-contact>
    <tr>
      <td><span class="p-column-title">Nom:</span>{{ contact.name }}</td>
      <td><span class="p-column-title">Adresse:</span>{{ contact.address }}</td>
      <td>
        <span class="p-column-title">N° Téléphone:</span>
        <div class="flex flex-wrap justify-content-center">
          <p-chip [label]="contact.phoneNumber"></p-chip>
        </div>
      </td>
      <td>
        <span class="p-column-title">E-mail:</span>
        <div *ngIf="contact.email" class="flex flex-row justify-content-center">
          <a [href]="'mailto:' + contact.email"
             class="no-underline p-1 p-button p-button-help p-button-outlined p-button-raised">{{ contact.email }}</a>
        </div>
      </td>
      <td class="text-center">
        <span class="p-column-title">Solde initial:</span>
        {{ contact.transactionalDetails?.initialBalance | number:'1.2-2' }} DZD
      </td>
      <td class="text-center">
        <span class="p-column-title">Solde courant:</span>
        {{ contact.transactionalDetails?.balance | number:'1.2-2' }} DZD
      </td>
      <td>
        <span class="p-column-title">C / F:</span>
        <div class="flex justify-content-center align-items-center">
          <p-tag *ngIf="contact.transactionalDetails?.isCustomer || false" severity="success" value="Client"
                 styleClass="m-1"></p-tag>
          <p-tag *ngIf="contact.transactionalDetails?.isProvider || false" severity="danger"
                 value="Fournisseur"></p-tag>
        </div>
      </td>
      <td class="text-center"><span
        class="p-column-title">N° RC:</span>{{ contact.transactionalDetails?.companyRegistrationNumber }}
      </td>
      <td class="text-center"><span
        class="p-column-title">NIF:</span>{{ contact.transactionalDetails?.fiscalRegistrationNumber }}
      </td>
      <td class="text-center"><span
        class="p-column-title">NIS:</span>{{ contact.transactionalDetails?.statisticsNumber }}
      </td>

      <td pFrozenColumn alignFrozen="right">
        <span class="p-column-title">Actions:</span>

        <app-contact-split-button appendTo="body" label="Actions" styleClass="p-button-sm w-full p-mb-2"
                                  itemsStyleClass="table-right-button"
                                  [item]="contact"
                                  [parentToastId]="this.toastId"
                                  [historyReportDate]="this.historyReportDate"
                                  [warrantyCreateFormVisibilityEventEmitter]="this.warrantyCreateFormVisibilityEventEmitter"

                                  [(processing)]="this.processing"

                                  [editable]="true" (editEventEmitter)="edit($event)"
                                  [deletable]="true" (deleteEventEmitter)="delete($event)"

        />
      </td>
    </tr>
  </ng-template>
</p-table>
