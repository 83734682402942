import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-value-chips',
  templateUrl: './value-chips.component.html',
  styleUrl: './value-chips.component.scss'
})
export class ValueChipsComponent<T> {

  @Input()
  id!: string;

  @Input()
  values!: T[] | undefined;

  @Input()
  placeholder!: string;
}
