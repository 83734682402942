import {ContactRequest} from "./contact.request";
import {ContactTransactionDetailsRequest} from "./contact-transaction-details.request";

export class ContactCreationRequest extends ContactRequest {
  transactionalDetails = new ContactCreationTransactionDetailsRequest();
}

export class ContactCreationTransactionDetailsRequest extends ContactTransactionDetailsRequest {
  initialBalance: number | undefined = 0;
}
