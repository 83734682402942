import {OrderSearchRequest} from "../shared/order/order-search.request";
import {ReportingOrderType} from "./reporting-order-type";

export class ReportingOrderSearchRequest extends OrderSearchRequest {

  reportingOrderType!: ReportingOrderType;

  constructor(reportingOrderType: ReportingOrderType,
              occurredAfter: string | undefined, occurredBefore: string | undefined,
              referenceNumber: string | undefined, serialNumber: string | undefined,
              contactId: string | undefined,
              pageIndex: number, pageSize: number, offsetIndicators: any[] | undefined
  ) {
    super(occurredAfter, occurredBefore, referenceNumber, serialNumber, contactId, pageIndex, pageSize, offsetIndicators);

    this.reportingOrderType = reportingOrderType;
  }
}
