import {Injectable} from '@angular/core';
import {HttpService} from '../../services/http/http.service';
import {GenericRepository} from "../shared/generic.repository";
import {ProductCategoryCreateRequest} from "../../models/inventory/product-category/product-category-create.request";
import {ProductCategoryUpdateRequest} from "../../models/inventory/product-category/product-category-update.request";
import {ProductCategorySearchRequest} from "../../models/inventory/product-category/product-category-search.request";
import {IProductCategory} from "../../models/inventory/product-category/product-category.interface";

@Injectable()
export class ProductCategoryRepository extends GenericRepository<IProductCategory, ProductCategorySearchRequest, ProductCategoryCreateRequest, ProductCategoryUpdateRequest> {
  constructor(httpService: HttpService) {
    super(httpService, "/productCategories");
  }
}
