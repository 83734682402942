<div class="flex flex flex-row">
  <p-inputGroup class="w-fit">
    <p-inputGroupAddon>
      <i [class]="this.icon"></i>
    </p-inputGroupAddon>
  </p-inputGroup>

  <span [class]="'w-full ' + (this.floatLabel ? 'p-float-label' : '')">
    <p-autoComplete
      field="name"

      [inputId]="this.id"
      [suggestions]="this.entities"
      (completeMethod)="this.search($event)"

      [(ngModel)]="this.entity"

      (onClear)="this.onClear()"
      (onSelect)="this.onSelect($event)"

      [dropdown]="true"
      [virtualScroll]="true"
      [virtualScrollItemSize]="45"
      [appendTo]="this.appendTo ? this.appendTo : null"
      [placeholder]="this.floatLabel ? '' : this.placeholder"
    >
    </p-autoComplete>
    <label *ngIf="this.floatLabel" [for]="this.id" [textContent]="this.placeholder"></label>
  </span>

  <p-button icon="pi pi-plus" severity="success" class="ml-1" (onClick)="this.onCreate()"></p-button>
  <p-button icon="pi pi-pencil" severity="warning" class="ml-1" (onClick)="this.onEdit()"></p-button>
</div>
